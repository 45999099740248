/**
 * Esta función permite convertir el texto de un archivo de tipo Blob en datos en formato JSON.
 * @param {Blob} blob 
 * @returns Retorna los datos del archivo Blob en formato JSON.
 */
export const blobToJson = (blob) => {

    return new Promise((resolve, reject) => {
        // Validar que el parámetro blob sea una instanci de Blob
        if (!(blob instanceof Blob)) reject(`El parámetro debe de ser de tipo 'Blob'`);

        const reader = new FileReader();
        let jsonResult = null;


        try {
            reader.addEventListener("loadend", (e) => {
                const text = e.target.result;
                jsonResult = JSON.parse(text);
                resolve(jsonResult);
            });

            reader.readAsText(blob);
        } catch (error) {
            reject(error)
        }
    });
}
