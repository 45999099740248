import React, { useEffect, useState } from "react";
import { Box, IconButton, Popover, Tooltip, Typography, Divider } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

import ManualList from "./ManualList";
import { loadFullData } from "../../apiCore/entity";
import { hideLoader, showLoader } from "../Controls/Loader";

export default function ManualIcon() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [videos, setVideos] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        if (open) {
            (async () => {
                try {
                    setIsLoading(true)
                    showLoader();
                    const response = await loadFullData({ model: "videos" });
                    const { error, data } = response?.data || {};

                    if (error) {
                        setIsError(true);
                    };

                    if (data) setVideos(data);

                } catch (error) {
                    setIsError(true);
                } finally {
                    setIsLoading(false);
                    hideLoader();
                }
            })();
        }
    }, [open]);

    return (
        <React.Fragment>
            <Tooltip title="Ayuda">
                <IconButton
                    onClick={handleClick}
                    aria-describedby={id}
                    className="whiteLetter"
                >
                    <HelpOutline
                        fontSize="large"
                        className="helpIcon"
                    />
                </IconButton>
            </Tooltip>


            {!isLoading && (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {isError && !isLoading && (<Box padding={2} className="error">Error al cargar la lista de manuales</Box>)}
                    {!isError && videos.length === 0 && !isLoading && (<Box padding={2}>No hay información disponible</Box>)}
                    {!isError && videos.length > 0 && !isLoading && (
                        <Box padding={1}>
                            <Typography paddingLeft={1} variant="h6" fontWeight="600">Tutoriales</Typography>
                            <Divider />
                            <ManualList videos={videos} />
                        </Box>)}
                </Popover>
            )}
        </React.Fragment>
    )
}
