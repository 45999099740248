import React from "react";
import ReactPlayer from "react-player/youtube";

export default function YouTubeVideoPlayer({ url }) {
    return (
        <ReactPlayer
            url={url}
            controls={true}
            width="auto"
            height="400px"
        />
    )
}
