import React, { useState, useEffect, useCallback, useRef } from "react";

import {
    Box,
    Grid,
    Button,
    Autocomplete,
} from "@mui/material";
import fileDownload from "js-file-download";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import DownloadIcon from "@mui/icons-material/Download";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";

import ConfirmModal from "./ConfirmModal";
import ExportConfirmModal from "./ExportConfirmModal";
import { CssTextField } from "../Controls/CustomTextField";
import { showLoader, hideLoader } from "../Controls/Loader";
import { deleteRegister } from "../../apiCore/dataTable";
import Table from "./DataOnePage/Table/Table";
import api from "../../axios/axios";
import "../../custom.css";
import Pagination from "./DataOnePage/Pagination/Pagination";
import { useLocation } from "react-router-dom";
import { useMainContext } from "../../hooks/useMainContext";
import { roles } from "../../utils/constants";
import DownloadModal from "./DownloadData/DownloadModal";

const arrayFilterExportColumns = [];
const NAME_COLUMN_CREATED = "created";
const MAC_LENGTH_FOR_DOWNLOAD = 100;

const getFilterColumns = (filterColums) => {
    return filterColums.map(column => ({ ...column, isEmpty: false }));
}

export default function DataOnePage(props) {
    const {
        model,
        rows,
        columns,
        page,
        perPage,
        length,
        addItem,
        loadData,
        filtersValues,
        setFiltersValues,
        typeDatatable = null,
        handleOrderValueChange,
        onFilterValuesChange,
        onNumberOfRecordsPerPageChange,
        onPageNumberChange,
        NewRegisterButton,
        ActionsButtons,
        modelIsView,
    } = props;

    const { availableMenuPaths = [], user } = useMainContext();
    const location = useLocation();

    const getPermissions = useCallback(() => {
        const findPath = availableMenuPaths?.find(item => item?.menuPath === location?.pathname);
        const { editPermission, viewPermission } = findPath || {};

        if (user?.roleId === roles.administrador) {
            return { editPermission: true, viewPermission: true }
        } else {
            return { editPermission: Boolean(editPermission), viewPermission: Boolean(viewPermission) }
        }

    }, [location]);

    // Contiene los permisos de edición y visualización del usuario.
    const permissions = useRef(getPermissions());

    const { enqueueSnackbar } = useSnackbar();
    const [idRegistro, setIdRegistro] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openRangeDownload, setOpenRangeDownload] = useState(false);
    const [filterExportColumns, setFilterExportColumns] = useState(null);
    const [filterExportParams, setFilterExportParams] = useState(null);
    const [openDownloadForm, setOpenDownloadForm] = useState(false);

    const onOpenCloseDownloadForm = () => setOpenDownloadForm(prevState => !prevState);

    useEffect(() => {
        // Filtra las columnas de acuerdo con el array de columnas con el filtro a exportar.
        // El map y el filter devuelven un array de array cn los objetos filtrados, [[{}],...,[{}]]
        // La función flat genera un array s
        const filterColumns = arrayFilterExportColumns.map(item => {
            return columns.filter(column => column.field === item);
        }).flat();

        if (filterColumns.length > 0) {
            setFilterExportColumns(getFilterColumns(filterColumns));
        }
    }, []);

    const exportAllData = () => {
        //Consulta de reporte del periodo indicado por el usuario
        /*
        var startDate = moment(rangeDate.startDate).startOf('day').utcOffset(0, true).format();
        console.log(startDate);

        var endDate = moment(rangeDate.endDate).endOf('day').utcOffset(0, true).format();
        console.log(endDate);
        */

        showLoader();
        api.post('api/entity/exportDataFull', { model }, {
            responseType: 'blob'
        }).then((response) => {
            // Validar si la consulta este vacía.
            if (response.status === 204) {
                enqueueSnackbar("No hay información disponible para los filtros seleccionados.", { variant: "error" });
                hideLoader();
                return;
            }

            fileDownload(response.data, model + ".xlsx");
            setOpenRangeDownload(!openRangeDownload);
            hideLoader();
        }).catch(error => {
            if (error?.response?.data?.error) {
                enqueueSnackbar(error?.response?.data?.error, { variant: "error" });
            } else {
                enqueueSnackbar("No se logró descargar la información solicitada.", { variant: "error" });
            }
            hideLoader();
        });
    }

    const exportFilterData = () => {
        let hasEmptyInputs = false;

        // Validar que todos los campos del formulario no estén vacíos.
        if (!filterExportParams) {
            setFilterExportColumns(prevState => {
                return prevState.map(item => ({ ...item, isEmpty: true }));
            });
            return;
        }

        // Validar que la el objecto filterExportParmas contenga todo los elementos.
        filterExportColumns.forEach(item => {
            // Validar si el objeto no contiene  la key del item.field.
            if (!(item.field in filterExportParams)) {
                hasEmptyInputs = true;
                setFilterExportColumns(prevState => {
                    return prevState.map(column => {
                        if (column.field === item.field) return ({ ...column, isEmpty: true });
                        return { ...column }
                    })
                });
            } else {
                // En caso de que si tenga la key del item.field, validamos que esta tenga un valor.
                if (!filterExportParams[item.field]) {
                    hasEmptyInputs = true;
                    setFilterExportColumns(prevState => {
                        return prevState.map(column => {
                            if (column.field === item.field) return ({ ...column, isEmpty: true });
                            return { ...column }
                        })
                    });
                }
            }
        });

        // Validar que no hayan inputs vaciós antes de hacer la solicitud al servidor.
        if (hasEmptyInputs) return;

        // Realizar la consulta al servidor.
        showLoader();
        api.post('api/entity/exportFilterData', { model, filterParams: filterExportParams }, {
            responseType: 'blob'
        }).then((response) => {
            // Validar si la consulta este vacía.
            if (response.status === 204) {
                enqueueSnackbar("No hay información disponible para los filtros seleccionados.", { variant: "error" });
                hideLoader();
                return;
            }
            fileDownload(response.data, model + ".xlsx")
            setOpenRangeDownload(!openRangeDownload);
            setFilterExportParams(null);
            hideLoader();
        }).catch(error => {
            if (error?.response?.data?.error) {
                enqueueSnackbar(error?.response?.data?.error, { variant: "error" });
            } else {
                enqueueSnackbar("No se logró descargar la información solicitada.", { variant: "error" });
            }
            hideLoader();
        });
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={1}>

                        <Grid item xs={6} sm={3} md={2} className="toRight" >
                            <Box p={1}>
                                <Autocomplete
                                    options={[25, 50, 75, 100]}
                                    fullWidth
                                    size="small"
                                    value={perPage}
                                    disableClearable
                                    getOptionLabel={(option) => option?.toString()}
                                    renderInput={(params) => (
                                        <CssTextField
                                            {...params}
                                            label="Número de registros"
                                            className="widthSelect"
                                        />
                                    )}
                                    onChange={(_, value) => {
                                        onNumberOfRecordsPerPageChange(value);
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={3} md={2} className="toRight">
                            <Box p={1}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    className="heightButton"
                                    endIcon={<FilterAltOffIcon />}
                                    onClick={() => {
                                        //Limpiar filtros
                                        //setFiltersValues({});
                                        var formTemporal = { ...filtersValues };

                                        var keys = Object.keys(formTemporal);
                                        for (var i = 0; i < keys.length; i++) {
                                            var field = keys[i];

                                            var tempObj = formTemporal[field];
                                            if (tempObj.type === "select") {
                                                tempObj.value = [];
                                            }
                                            else if (tempObj.type === "bool") {
                                                tempObj.value = false;
                                                tempObj.filterReset = true;
                                            }
                                            else {
                                                tempObj.value = "";
                                            }

                                            formTemporal[field] = tempObj;
                                        }
                                        setFiltersValues(formTemporal);
                                    }}>
                                    Limpiar filtros
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={3} md={2}>
                            <Box p={1}>
                                <Button variant="contained"
                                    fullWidth
                                    className="heightButton"
                                    endIcon={<DownloadIcon />}
                                    onClick={() => {
                                        const findCreatedField = columns?.find(column => column?.field === NAME_COLUMN_CREATED);

                                        if (findCreatedField && length > MAC_LENGTH_FOR_DOWNLOAD && (!filterExportColumns || filterExportColumns?.length === 0)) {
                                            onOpenCloseDownloadForm();
                                        } else {
                                            setOpenRangeDownload(true);
                                        }

                                    }}>
                                    Descargar datos
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={3} md={2}>
                            <Box p={1}>
                                <Button variant="contained"
                                    fullWidth
                                    className="heightButton"
                                    endIcon={<AutorenewIcon />}
                                    onClick={() => {
                                        loadData();
                                    }}
                                >
                                    Refrescar datos
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} className="toRight">
                            <Pagination
                                page={page}
                                perPage={perPage}
                                onPageNumberChange={onPageNumberChange}
                                length={length}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Box p={1}>
                                {/* Agregar botón de nuevo registro personalizado */}
                                {
                                    NewRegisterButton
                                        ? (<NewRegisterButton onReload={loadData} columns={columns} />)
                                        : (
                                            <Button
                                                disabled={!permissions.current.editPermission}
                                                variant="contained"
                                                fullWidth
                                                className="heightButton"
                                                endIcon={<AddIcon />}
                                                onClick={() => {
                                                    addItem()
                                                }}>
                                                Nuevo registro
                                            </Button>
                                        )
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >

            {/* Renderiza la tabla con los filtros, las columnas y los datos del componente DataOnePage */}
            < Table
                columns={columns}
                model={model}
                filtersValues={filtersValues}
                setFiltersValues={setFiltersValues}
                rows={rows}
                typeDatatable={typeDatatable}
                setIdRegistro={setIdRegistro}
                setOpenDelete={setOpenDelete}
                handleOrderValueChange={handleOrderValueChange}
                onFilterValuesChange={onFilterValuesChange}
                ActionsButtons={ActionsButtons}
                loadData={loadData}
                modelIsView={modelIsView}
                permissions={permissions.current}
            />

            <ConfirmModal
                open={openDelete}
                setOpen={setOpenDelete}
                confirmButtonText="Confirmar Eliminación"
                titleMessage="Borrar Registro"
                confirmMessage={"¿Confirma que desea eliminar el registro con ID: " + idRegistro + "?"}
                confirmHandler={async () => {
                    var res = await deleteRegister(model, idRegistro);
                    if (res.data.error) {
                        enqueueSnackbar(res.data.error, { variant: "error" });
                    } else {
                        loadData();
                        setOpenDelete(false);
                        enqueueSnackbar(res.data.message, { variant: "success" });
                    }
                }}
            />

            <ExportConfirmModal
                open={openRangeDownload}
                setOpen={setOpenRangeDownload}
                titleMessage="Confirmación de descarga"
                filterExportColumns={filterExportColumns}
                setFilterExportColumns={setFilterExportColumns}
                setFilterExportParams={setFilterExportParams}
                confirmHandler={
                    filterExportColumns ? exportFilterData : exportAllData
                }
            />

            <DownloadModal
                open={openDownloadForm}
                onClose={onOpenCloseDownloadForm}
                model={model}
            />
        </>
    )
}