import * as Yup from "yup";
import { getCurrentDateInFormatISO } from "../../../utils/datetime";
import { quoteStatus } from "../../../utils/constants";

export function initialValues({ quote, userId }) {
    let date = null;

    if (quote?.date) {
        date = new Date(quote.date).toISOString().slice(0, 10);
    }

    return {
        deliveryTime: quote?.deliveryTime || "", // Tiempo de entrega
        deliveryLocation: quote?.deliveryLocation || "", // Lugar de entrega
        quantityInTons: quote?.quantityInTons || "", // Cantidad en toneladas
        date: date || getCurrentDateInFormatISO(), // Fecha
        deadlineId: quote?.deadlineId || "", // Plazo de entrega
        validityId: quote?.validityId || "", // Vigencia
        approvalRequired: quote?.approvalRequired || true, // Requiere aprobación
        sendToCustomer: quote?.sendToCustomer || false, // Enviada al cliente
        userId: quote?.userId || userId, // Id usuario
        quoteStatusId: quote?.quoteStatusId || quoteStatus.enElaboracion, // Id status
        customerId: quote?.customerId || "", // Id cliente
        consecutive: quote?.consecutive || "", // Consecutivo
        includeFletePDF: quote?.includeFletePDF ?? true, // Incluir en el pdf de la cotización del valor del flete
        includeSourcesPDF: quote?.includeSourcesPDF ?? true, // Incluir en el pdf las fuentes de los fertilizantes
    }
}

export function validationSchema() {
    return Yup.object({
        validityId: Yup.number().required("Por favor seleccione un valor de vigencia"),
        deadlineId: Yup.number().required("Por favor seleccione un plazo"),
        deliveryTime: Yup.number().typeError("Ingrese un valor númerico en días"),
        deliveryLocation: Yup.string(),
        date: Yup.date().required("Ingrese un fecha valida"),
        customerId: Yup.number().required("Por favor seleccione un cliente"),
    });
}