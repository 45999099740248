/**
 * Permite agrupar los datos de un array de acuerdo con una llave específicada.
 * 
 * @param {Array<any>} array - Array de objetos para agrupar. 
 * @param {string} key - Llave con la que se van a agrupar los datos
 * @returns {Array} - Array con los elementos agrupados por la key asignada.
 */

export function groupBy(array = [], key) {
    return array.reduce((acc, currentValue) => {
        let groupKey = currentValue[key]; // Valor de la llave por la cual se van a agrupar los datos.

        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }

        acc[groupKey]?.push(currentValue);

        return acc;
    }, {});
}