import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';
import { approvalPublicQuote } from "../../../apiCore/quotes";
import { useSnackbar } from "notistack";

export default function ApprovedQuote() {
    const { enqueueSnackbar } = useSnackbar();
    const { idQuote, mailUser, requestId } = useParams();

    useEffect(()=>{
        async function callFunction(){
            let idQuoteString = atob(idQuote);
            let mailUserString  = atob(mailUser);
            let idRequestString = atob(requestId);

            try{
                const response = await approvalPublicQuote({ idQuoteString, mailUserString, idRequestString });
                const { error, message } = response?.data || {};
                if (error) throw { response };
                if (message) {
                    enqueueSnackbar(message, { variant: "success" });
                }                
            }catch (err) {
                enqueueSnackbar("Error de conexión, inténtelo mas tarde.", { variant: "error" });
            }
        }
        
        callFunction();
        
    },[]);

    return (
        <Box className="displayFlex flexJustifyContentCenter flexDirectionColumn flexAlignItemsCenter height100 fullWidth whiteLetter">
            <Typography align="center" variant="h2" className="titleExternalControl">
                COTIZACIÓN APROBADA
            </Typography>
            <br/>
            <Typography align="center" className="titleExternalControl">
                Proceso finalizado, esta ventana puede cerrarse, el suario que solicito la aprobación fue notificado y el cliente recibió la cotización aprobada.
            </Typography>
        </Box>
    )
}