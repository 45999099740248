import DatatableGroups from "../components/Admin/Groups/DatatableGroups";
import DatatableUser from "../components/Admin/User/DatatableUser";
import Datatable from "../components/Controls/Datatable";
import Dashboard from "../components/Dashboard/Dashboard";
import DatatableQuote from "../components/Quotes/DatatableQuote/DatatableQuote";
import Reports from "../components/Reports/Reports";

export const roles = {
    administrador: 1,
    aprobador: 2,
    RTCJunior: 3,
    RTCSenior: 4,
}

export const typeOfMixes = {
    byGrade: 1,
    byFertilizer: 2,
};

export const quoteStatus = {
    pendiente: 1,
    aprobada: 2,
    rechazada: 3,
    cerrada: 4,
    enElaboracion: 5,
    lograda: 6,
};

export const dataTypes = {
    text: "text",
    select: "select",
    float: "float",
    int: "int",
    date: "date",
    boolean: "bool",
    array: "array",
    bit: "bit",
    number: "number",
}

export const loginConfig = {
    microsoft: {
        /*Si se coloca en true la opción enableMicrosoftLogin, también se debe de configurar los datos del clientId y tendntId de Azure AD */
        enableMicrosoftLogin: true, // Habilitar login con Microsoft
        clientId: "8eea56ba-ac33-492f-bace-0261705cbf12", // Id del cliente
        tenantId: "16825ec7-9fde-45ce-951f-d759042da93e", // Id del inquilino
        getUserData: true,
    }

};

export const ENV = {
    API_ROUTES: {
        ENTITY: {
            CREATE: "api/entity/create",
            UPDATE: "api/entity/update",
            GET: "api/entity/get",
            GET_LAST_REGISTER: "api/entity/getLastRegister",
            DOWNLOAD_BY_CREATED_FIELD: "api/entity/downloadByCreatedField",
        },
        MAQUILA: {
            GET: "api/maquila/get",
        },
        AUTOCOMPLETE: {
            LOAD_FULL_DATA: "api/autocomplete/loadFullData",
        },
        GROUPS: {
            GET_GROUPS_BY_ROLE: "api/groups/groupsByRole",
        }
    },
    CLIENT_ROUTES: {
        LOGIN: "/",
        DASHBOARD: {
            PATH: "/dashboard",
            COMPONENT: Dashboard,
            TITLE_PAGE: "CIAMSA - COTIZADOR AGRICOLA",
            MODEL: null
        },
        USERS: {
            PATH: "/admin/users",
            COMPONENT: DatatableUser,
            TITLE_PAGE: "Administrador / Usuarios",
            MODEL: "users",
        },
        GROUPS: {
            PATH: "/admin/groups",
            COMPONENT: DatatableGroups,
            TITLE_PAGE: "Administrador / Grupos",
            MODEL: "groups"
        },
        ELEMENTS: {
            PATH: "/elementos",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Elementos",
            MODEL: "elements"
        },
        FERTILIZERS: {
            PATH: "/fertilizantes",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Fertilizantes",
            MODEL: "fertilizers"
        },
        CUSTOMERS: {
            PATH: "/clientes",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Clientes",
            MODEL: "customers"
        },
        FERTILIZERSBYELEMENTS: {
            PATH: "/fertilizante_por_elemento",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Fertilizante por elemento",
            MODEL: "fertilizersByElements"
        },
        QUOTES: {
            PATH: "/cotizaciones",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Cotizaciones",
            MODEL: "quotes"
        },
        MIXES: {
            PATH: "/mezclas",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Mezclas",
            MODEL: "mixes",
        },
        FERTLIZERBYMIX: {
            MODEL: "fertlizerByMix",
        },
        ELEMENTBYMIX: {
            MODEL: "elementByMix",
        },
        TYPEOFMIX: {
            MODEL: "typeOfMix",
        },
        PACKAGINGTYPE: {
            PATH: "/empaques",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Tipo de empaque",
            MODEL: "packagingType"
        },
        FERTILIZERDEFAULT: {
            PATH: "/fertilizantes_por_defecto",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Fertilizantes por defecto",
            MODEL: "fertilizerDefault",
        },
        VIEW_QUOTES: {
            PATH: "/cotizaciones",
            COMPONENT: DatatableQuote,
            TITLE_PAGE: "Ciamsa / Cotizaciones",
            MODEL: "datatableQuotes",
        },
        DEADLINE: {
            PATH: "/plazo",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Plazo",
            MODEL: "deadline",
        },
        ADDITIONAL_INFO:{
            PATH: "/informacion",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Información adicional cotizaciones",
            MODEL: "quotePrintInfo",
        },
        USERS_INVENTORY:{
            PATH: "/usuariosInventario",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Usuarios para la gestión de inventario",
            MODEL: "usersInventory",
        },     
        ADDITIONAL_PAGES:{
            PATH: "/paginasAdicionales",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Páginas adicionales cotización",
            MODEL: "pdfAdditionalPages",
        },                
        VALIDITY: {
            PATH: "/vigencia",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Vigencia",
            MODEL: "validity",
        },
        PROFITABILITY: {
            PATH: "/rentabilidad",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Configuración Rentabilidad",
            MODEL: "profitability",
            ORDER_ID: "desc"
        },  
        DISCOUNT_RANGES_BY_ROLS: {
            PATH: "/descuentosPorRoles",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Configuración de aprobación de descuento por roles",
            MODEL: "discountRangesByRols",
            ORDER_ID: "desc"
        },                
        REPORTS: {
            PATH: "/reportes",
            COMPONENT: Reports,
            TITLE_PAGE: "Ciamsa / Reportes",
            MODEL: "reports",
        },
        ZONES: {
            PATH: "/zonas",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Zonas",
            MODEL: "zone",
        },
        ACCESSCONTROL: {
            PATH: "/admin/accesos",
            COMPONENT: Datatable,
            TITLE_PAGE: "Administrador / Accesos",
            MODEL: "accessControl",
        },
        MAQUILA_COST: {
            PATH: "/maquila",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Historial de precios maquila",
            MODEL: "maquilaCost",
        },
        AUTOMATIC_CALCULATION_BY_GRADE: {
            PATH: "/calculo_grado",
            COMPONENT: Datatable,
            TITLE_PAGE: "Ciamsa / Configuración cálculo automático de mezclas por grado",
            MODEL: "automaticCalculationByGradeConfig",
        },
    },
};