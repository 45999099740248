import { TextField, styled } from "@mui/material";

// Input sin label y responsivo
export const UnlabeledTextField = styled(TextField)(({ theme }) => ({
    "& legend": {
        display: "none",
    },
    "& fieldset": {
        top: 0,
    },
    "& .MuiInputBase-root": {
        fontSize: "0.75rem !important",
    },
}));