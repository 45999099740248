import React from "react";
import { Outlet } from "react-router-dom";
import MenuNavegacion from "../../components/MenuNavegacion/MenuNavegacion";
import NotAuthorized401 from "../../components/HttpState/NotAutorized401/NotAuthorized401";
import { useMainContext } from "../../hooks/useMainContext";

export default function ProtectedRoutes({ allowedRoles = [] }) {
    const { user } = useMainContext();

    return (
        allowedRoles?.includes(user?.roleId)
            ? <Outlet />
            : (
                <MenuNavegacion
                    ContentComponent={NotAuthorized401}
                />
            )

    );
}
