import React, { useEffect, useState } from 'react';
import { getUserInfo } from "../../apiCore/user";
import { useSnackbar } from "notistack";
import api from "../../axios/axios";
import { roles } from "../../utils/constants";

export const MainContext = React.createContext();

export function MainContextProvider(props) {
    const { children } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [user, setUser] = useState(null);
    const [availableMenuPaths, setAvailableMenuPaths] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataMainContext, setDataMainContext] = useState({ entity: "", createEntity: false, newEntities: [] });

    useEffect(() => {
        (async () => {
            try {
                // Consultar si el usuario tiene una sessión activa.
                const session = await api.post("api/session/validateSession", {});

                if (!session?.data?.haveSession) {
                    await logout();
                    return;
                };

                // Consulta email del usuario.
                const email = await api.post("api/session/getEmailSession", {});

                if (!email?.data?.email) {
                    await logout();
                    return;
                }

                // Consultar datos del usuario.
                const user = await getUserInfo(email?.data?.email);

                if (!user?.[0]) {
                    await logout();
                    return;
                }

                setUser(user[0]);

                const { roleId } = user[0] || {};

                // Solo se consultan accesos para roles diferentes a los del administrador.
                if (roleId !== roles.administrador) { await getAvailableMenuPaths(); }

                setLoading(false);
            } catch (error) {
                enqueueSnackbar("Error al intentar iniciar sesión", { variant: "error" });
                await logout();
            }
        })();
    }, []);

    const logout = async () => {
        try {
            await api.post("api/session/closeSession", {});
        } catch (error) {
            enqueueSnackbar("Error al intentar cerrar la sesión", { variant: "error" });
        } finally {
            setUser(null);
            setLoading(false);
        }
    }

    // Consultar las rutas del menú de navegación disponibles.
    const getAvailableMenuPaths = async () => {
        try {
            const response = await api.get("api/accessControl/menuPaths");
            if (response?.data?.error) {
                enqueueSnackbar(response.data.error, { variant: "error" });
                return;
            }

            if (response?.data?.data) {
                setAvailableMenuPaths(response?.data?.data);
            }

        } catch (error) {
            if (error?.response?.data?.error) {
                enqueueSnackbar(error.response.data.error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al consultar los accesos disponibles", { variant: "error" });
            }
        }
    }

    const data = {
        user,
        logout,
        setUser,
        dataMainContext,
        setDataMainContext,
        availableMenuPaths,
    };

    if (loading) return null;

    return <MainContext.Provider value={data}>{children}</MainContext.Provider>
}