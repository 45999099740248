import React from "react";
import { Link } from "react-router-dom";

export default function CustomLink({ children, ...restParams }) {
    return (
        <Link
            {...restParams}
            className="link-remove-default-styles"
        >
            {children}
        </Link>
    )
}
