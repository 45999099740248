import React, { useState } from 'react';
import MicrososoftLogin from "react-microsoft-login";
import { loginConfig } from "../../utils/constants";
import { validateLoginWithMicrosoft } from "../../apiCore/session";
import ConfirmModal from "../Controls/ConfirmModal";
import { requestAccess } from "../../apiCore/session";
import { useSnackbar } from "notistack";
import { showLoader, hideLoader } from "../Controls/Loader";
import { Button } from "@mui/material";

export default function LoginWithMicrosoft() {

    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState(null);
    const [showRequestModal, setShowRequestModal] = useState(false);

    const authHandler = async (err, data) => {
        if (err) {
            console.error(err);
            return;
        }
        if (data && !err) {
            setData(data);
            try {
                showLoader();
                const response = await validateLoginWithMicrosoft(data?.mail);

                if (response?.status === 200) {
                    hideLoader();
                    window.location.href = "/dashboard";
                    return;
                }

            } catch (error) {
                hideLoader();
                if (error?.response?.status === 401) {
                    setShowRequestModal(true);
                    return;
                }

                if (error?.response?.data?.error) {
                    enqueueSnackbar(error.response.data.error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al intentar iniciar sesión", { variant: "error" });
                }
            }
        }
    }

    const requesAccessConfirm = async () => {
        try {
            showLoader();
            let user = {};

            // Validar email
            if (data?.userPrincipalName) {
                user = { ...user, email: data?.userPrincipalName, }
            } else if (data?.mail) {
                user = { ...user, email: data.mail };
            } else if (data?.account?.username) {
                user = { ...user, email: data.mail };
            } else {
                enqueueSnackbar("No fue posible encontrar un email para realizar la solicitud de acceso", { variant: "error" });
                return;
            }

            // Asignar name y lastname
            user = {
                ...user,
                name: data?.givenName,
                lastName: data?.surname,
                fullName: data?.account?.name,
            };

            const response = await requestAccess(user);

            if (response?.data?.error) {
                enqueueSnackbar(response.data.error, { variant: "error" });
                hideLoader();
                setShowRequestModal(false);
                return;
            }
            if (response.status === 200) {
                enqueueSnackbar(response.data.message, { variant: "success" });
                hideLoader();
                setShowRequestModal(false);
                return;
            }

        } catch (error) {
            hideLoader();
            setShowRequestModal(false);
            if (error?.response?.data?.error) {
                enqueueSnackbar(error.response.data.error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al realizar la solicitud", { variant: "error" });
            }
        }
    }

    const MicrosoftIcon = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="20">
                <path fill="#f25022" d="M13 0h9v9h-9z" />
                <path fill="#00a4ef" d="M13 11h9v9h-9z" />
                <path fill="#7fba00" d="M23 0h9v9h-9z" />
                <path fill="#ffb900" d="M23 11h9v9h-9z" />
            </svg >
        );
    }

    const LoginMicrosoftButton = () => {
        return <Button
            fullWidth
            color='secondary'
            variant="outlined"
            startIcon={<MicrosoftIcon />}
        >
            Ingresar con Microsoft
        </Button>
    }

    return (
        <>
            <MicrososoftLogin
                clientId={loginConfig.microsoft.clientId}
                prompt="select_account"
                authCallback={authHandler}
                withUserData={loginConfig.microsoft.getUserData}
                tenantUrl={loginConfig.microsoft.tenantId ? `https://login.microsoftonline.com/${loginConfig.microsoft.tenantId}` : undefined}
                children={<LoginMicrosoftButton />}
            />

            <ConfirmModal
                open={showRequestModal}
                setOpen={setShowRequestModal}
                titleMessage="Solicitar acceso a la plataforma"
                confirmMessage="¿Desea realizar una solicitud de acceso a los administradores para poder ingresar a la plataforma?"
                confirmHandler={requesAccessConfirm}
                confirmButtonText="Solicitar acceso"
            />
        </>
    )
}

