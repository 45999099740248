import React, { useState } from "react";
import { CssTextField } from "./CustomTextField";
import moment from "moment";

const yyyymmddhhmmFormatDate = "YYYY-MM-DD HH:mm";

export default function DateTimePicker({ onFilterChange, column, isFilter, formik }) {
    const [dateValue, setDateValue] = useState("");

    return (
        <React.Fragment>
            <CssTextField
                fullWidth
                className="forceHeightInput marginBottom"
                type="datetime-local"
                size="small"
                name={column?.field}
                label={isFilter ? "Filtro " + column?.headerName : column?.headerName}
                value={dateValue}
                onChange={(event) => {
                    if (formik?.handleChange instanceof Function) formik.handleChange(event)
                    if (onFilterChange instanceof Function) {
                        onFilterChange(moment(event.target.value).format(yyyymmddhhmmFormatDate));
                    }
                    setDateValue(event.target.value)
                }}
                InputLabelProps={{
                    shrink: true,
                }}

                error={formik?.touched?.[column?.field] && Boolean(formik?.errors?.[column?.field])}
                helperText={formik?.touched?.[column?.field] && formik?.errors?.[column?.field]}
            />
        </React.Fragment>
    );
}
