import React, { useEffect, useState } from "react";
import {
    Box,
    Paper,
    TableCell,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableBody,
    Badge,
    styled,
    Typography
} from "@mui/material";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

import { getAllFertilizerWithPriceUpdate } from "../../../apiCore/ferilizers";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    ":hover": {
        backgroundColor: theme.palette.action.hover,
    },
    minHeight: "40px",
    height: "40px",
}));

export default function FertilizersPriceUpdate({ hasData }) {
    const [fertilizers, setfertilizers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const response = await getAllFertilizerWithPriceUpdate();

                const { data, error } = response?.data || {};

                if (error) throw { response };
                if (data) {
                    setfertilizers(data);
                    if (hasData instanceof Function) hasData(data?.length > 0);
                } else {
                    if (hasData instanceof Function) hasData(false);
                }
            } catch (error) {
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    if (isLoading) return <Box>Cargando...</Box>

    if (isError) return <div className="error">Error al cargar la lista de fertilizantes</div>

    return (
        <TableContainer
            className="tableDashboard"
            component={Paper}
            elevation={0}>
            <Typography variant="h6" textAlign="center">
                Lista de fertilizantes actualizados última semana
            </Typography>
            <Table
                stickyHeader>
                <TableHead
                    className="tableDashboardHead">
                    <TableRow>
                        <TableCell
                            className="tableDashboardCellWidth45">
                            NOMBRE FERTILIZANTE
                        </TableCell>
                        <TableCell
                            className="tableDashboardCellWidth25 fullWidthRight">
                            PRECIO ACTUAL
                        </TableCell>
                        <TableCell
                            className="tableDashboardCellWidth30 fullWidthRight">
                            FECHA ACTUALIZACIÓN
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fertilizers.map(fertilizer => {
                        const formatDate = fertilizer?.lastedUpdate ? moment(fertilizer?.lastedUpdate).format("DD/MM/YYYY, h:mm a") : "--/--/----";
                        return (
                            <StyledTableRow key={fertilizer?.id}>
                                <TableCell>
                                    <Box className="paddingForce">
                                        {fertilizer.name}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box className="paddingForce fullWidthRight">
                                        <CurrencyFormat
                                            displayType="text"
                                            value={fertilizer?.pricePerKg ?? 0}
                                            thousandSeparator
                                            prefix="$ "
                                        />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box className="fullWidthRight paddingForce">
                                        {fertilizer?.lastedUpdate ?
                                            <Badge variant="dot" color="error" invisible={false}>
                                                {formatDate}
                                            </Badge>
                                            : formatDate
                                        }
                                    </Box>
                                </TableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
