import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CustomLink from "../../common/CustomLink/CustomLink";
import { ENV } from "../../../utils/constants";

export default function NotFound404() {
    return (
        <Box className="displayFlex flexJustifyContentCenter flexDirectionColumn flexAlignItemsCenter height100 fullWidth whiteLetter">
            <Typography align="center" variant="h1">
                404
            </Typography>
            <Typography align="center" variant="h2">
                Not Found
            </Typography>
            <Button
                variant="contained">
                <CustomLink to={ENV.CLIENT_ROUTES.DASHBOARD.PATH}>
                    Ir al dashboard
                </CustomLink>
            </Button>
        </Box>
    )
}