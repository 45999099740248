import React, { useState, useRef } from "react";
import "../../custom.css";

import {
    TextField,
    Grid,
    Box,
    Paper,
    Button,
    Collapse,
    styled,
    Link
} from "@mui/material";
import { useSnackbar } from "notistack";
import { sendCode, validateCode } from "../../apiCore/session";
import { showLoader, hideLoader } from "../Controls/Loader";
import { useMainContext } from "../../hooks/useMainContext";
import { Navigate, useLocation } from "react-router-dom";
import LoginWithMicrosoft from "./LoginWithMicrosoft";
import { ENV, loginConfig } from "../../utils/constants";
import { Icon } from "../../assets";

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "1px solid rgba(255, 255, 255, 0.23)"
        },
        "&:hover fieldset": {
            borderColor: "#fff",
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.primary.main,
        },
    },
    "& .MuiFormLabel-root": {
        color: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiInputBase-input": {
        color: "#fff",
    },
}));

export default function Login() {
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useMainContext();

    const location = useLocation();

    const [showFormSendCode, setShowFormSendCode] = useState(0);

    var mailRef = useRef(null);
    var oneRef = useRef(null);
    var twoRef = useRef(null);
    var threeRef = useRef(null);
    var fourRef = useRef(null);
    var validateRef = useRef(null);

    const [code, setCode] = useState({
        one: "",
        two: "",
        three: "",
        four: ""
    });

    const [loginMail, setLoginMail] = useState("");

    const validarCodigoAcceso = async function () {

        const res = await validateCode(loginMail, (code.one + "" + code.two + "" + code.three + "" + code.four));
        setCode({ one: "", two: "", three: "", four: "" })

        if (res.data.error) {
            enqueueSnackbar(res.data.error, { variant: "error" });
            oneRef.current.focus()
        } else {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setLoginMail("");
            window.location.href = "/dashboard";
        }
    }

    const generarCodigoAcceso = async function () {
        showLoader();
        const res = await sendCode(loginMail);

        if (res.data.error) {
            enqueueSnackbar(res.data.error, { variant: "error" });
        } else {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setShowFormSendCode(2);
            setTimeout(function () { oneRef.current.focus() }, 500);
        }
        hideLoader();
    }

    if (user) return <Navigate to={ENV.CLIENT_ROUTES.DASHBOARD.PATH} state={{ from: location }} replace />

    return (
        <>
            <Grid container className="backgroundPage">
                <Grid item xs={12} sm={2} md={3} lg={4}>
                </Grid>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Box className="bloqueLogin">
                        <Paper elevation={5} className="bakgroundBloqueBlanco">
                            <Box p={1}
                                className="displayFlex flexJustifyContentCenter"
                            >
                                <Icon.LogoWhiteCiamsa />
                            </Box>
                            <Collapse in={(showFormSendCode === 0)}>
                                <Box p={1}>
                                    <Box p={1}>
                                        <Button
                                            fullWidth
                                            color="secondary"
                                            variant="outlined"
                                            onClick={() => {
                                                setTimeout(function () { mailRef.current.focus() }, 20);
                                                setShowFormSendCode(1)
                                            }}>
                                            Ingreso con código de verificación
                                        </Button>
                                    </Box>
                                    {loginConfig.microsoft.enableMicrosoftLogin && (
                                        <Box p={1}>
                                            <LoginWithMicrosoft />
                                        </Box>
                                    )}
                                </Box>
                            </Collapse>
                            <Collapse in={(showFormSendCode === 1)}>
                                <Box p={1}>
                                    <Box p={1}>
                                        <CustomTextField
                                            color="secondary"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    if (loginMail !== "" && loginMail.indexOf("@") !== -1 && loginMail.indexOf(".") !== -1) {
                                                        generarCodigoAcceso();
                                                    } else {
                                                        enqueueSnackbar("Correo no valido.", { variant: "error" });
                                                    }
                                                }
                                            }}
                                            inputRef={mailRef}
                                            value={loginMail}
                                            fullWidth
                                            label="Correo electrónico"
                                            variant="outlined"
                                            onChange={(e) => setLoginMail(e.target.value)}
                                        />
                                    </Box>
                                    <Box p={1}>
                                        <Button
                                            fullWidth
                                            color="secondary"
                                            variant="outlined"
                                            onClick={() => {
                                                if (loginMail !== "" && loginMail.indexOf("@") !== -1 && loginMail.indexOf(".") !== -1) {
                                                    generarCodigoAcceso();
                                                } else {
                                                    enqueueSnackbar("Correo no valido.", { variant: "error" });
                                                }
                                            }}>
                                            Enviar código de verificación
                                        </Button>
                                    </Box>
                                    <Box p={1}>
                                        <Button
                                            fullWidth
                                            color="secondary"
                                            variant="outlined"
                                            onClick={() => {
                                                setShowFormSendCode(0)
                                            }}>
                                            Cancelar
                                        </Button>
                                    </Box>
                                </Box>
                            </Collapse>
                            <Collapse in={(showFormSendCode === 2)}>
                                <Box p={1}>
                                    <Box p={1}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={3}>
                                                <CustomTextField
                                                    inputRef={oneRef}
                                                    value={code.one}
                                                    inputProps={{ maxLength: 1, style: { fontSize: 38, textAlign: "center", padding: 5 } }}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="secondary"
                                                    onFocusCapture={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onClick={() => {
                                                        setCode({ ...code, one: "" });
                                                    }}
                                                    onChange={(e) => {
                                                        twoRef.current.focus();
                                                        setCode({ ...code, one: e.target.value });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CustomTextField
                                                    inputRef={twoRef}
                                                    value={code.two}
                                                    inputProps={{ maxLength: 1, style: { fontSize: 38, textAlign: "center", padding: 5 } }}
                                                    fullWidth
                                                    className="bigTextCode"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onFocusCapture={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onClick={() => {
                                                        setCode({ ...code, two: "" });
                                                    }}
                                                    onChange={(e) => {
                                                        threeRef.current.focus();
                                                        setCode({ ...code, two: e.target.value });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CustomTextField
                                                    inputRef={threeRef}
                                                    value={code.three}
                                                    inputProps={{ maxLength: 1, style: { fontSize: 38, textAlign: "center", padding: 5 } }}
                                                    fullWidth
                                                    className="bigTextCode"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onFocusCapture={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onClick={() => {
                                                        setCode({ ...code, three: "" });
                                                    }}
                                                    onChange={(e) => {
                                                        fourRef.current.focus();
                                                        setCode({ ...code, three: e.target.value });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CustomTextField
                                                    inputRef={fourRef}
                                                    value={code.four}
                                                    inputProps={{ maxLength: 1, style: { fontSize: 38, textAlign: "center", padding: 5 } }}
                                                    fullWidth
                                                    className="bigTextCode"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onFocusCapture={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onClick={() => {
                                                        setCode({ ...code, four: "" });
                                                    }}
                                                    onChange={(e) => {
                                                        validateRef.current.focus();
                                                        setCode({ ...code, four: e.target.value });
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box p={1}>
                                        <Button
                                            ref={validateRef}
                                            fullWidth
                                            color="secondary"
                                            variant="outlined"
                                            onClick={() => {
                                                if (code.one !== "" && code.two !== "" && code.three !== "" && code.four !== "" && (code.one + "" + code.two + "" + code.three + "" + code.four) !== "9999") {
                                                    validarCodigoAcceso();
                                                } else {
                                                    enqueueSnackbar("Código no valido", { variant: "error" });
                                                }
                                            }}>
                                            Validar código de acceso
                                        </Button>
                                    </Box>
                                    <Box p={1}>
                                        <Button
                                            fullWidth
                                            color="secondary"
                                            variant="outlined"
                                            onClick={() => {
                                                setShowFormSendCode(0)
                                            }}>
                                            Cancelar
                                        </Button>
                                    </Box>
                                </Box>
                            </Collapse>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2} md={3} lg={4}>
                </Grid>
            </Grid>
            <div className="developedLink">
                <Link href="https://indesap.com" color="inherit" target="_blank">
                    Developed by Indesap 2023
                </Link>
            </div>
        </>
    )
}
