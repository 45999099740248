import React, { useState } from "react";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";

import BasicModal from "../../common/BasicModal/BasicModal";
import UserForm from "./UserForm";

export default function NewUserButton({ onReload, columns }) {
    const [open, setOpen] = useState(false);

    const onOpenCloseModal = () => setOpen(prevState => !prevState);

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    return (
        <React.Fragment>
            <Button
                fullWidth
                variant="contained"
                className="heightButton"
                endIcon={<Add />}
                onClick={onOpenCloseModal}
            >
                Nuevo registro
            </Button>

            <BasicModal
                open={open}
                onClose={onOpenCloseModal}
                title="Registro de datos"
                fullScreen={fullScreen}
                sxDialogContent={{ padding: 0 }}
            >
                <UserForm columns={columns} onReload={onReload} onClose={onOpenCloseModal} />
            </BasicModal>

        </React.Fragment>
    );
}
