import { handleResponse } from "./handleResponse";
import fileDownload from 'js-file-download';
import api from "../axios/axios";
import jsonTraslate from "../translate.json";
import { dataTypes } from "../utils/constants";
import md5 from "md5";


export const structure = async (model) => {
    const hash = md5("structure"+model);

    try {
        const res = await api.post(`api/entity/structure`, { model });
                
        localStorage.setItem(hash, JSON.stringify(res));
        return handleResponse(res);
    } catch (error) {

        //En caso de una respuesta erronea en la consulta de la estructura, se consulta el localstorage
        let res = localStorage.getItem(hash);
        if(res){
            return handleResponse(JSON.parse(res));
        }else{
            return handleResponse(error.response);
        }
    }
}

export const loadModelData = async (model, page, perPage, filtersValues, typeDatatable, orderValue = null) => {
    const hash = md5("modelData" + model + page + perPage + JSON.stringify(filtersValues) + JSON.stringify(typeDatatable) + JSON.stringify(orderValue));

    try {
        const res = await api.post(`api/entity/loadModelData`, { model, page, perPage, filtersValues, typeDatatable, orderBy: orderValue });        

        localStorage.setItem(hash, JSON.stringify(res));
        return handleResponse(res);
    } catch (error) {
        let res = localStorage.getItem(hash);
        if(res){
            return handleResponse(JSON.parse(res));
        }else{
            return handleResponse(error.response);
        }
    }

}

export const deleteRegister = async (model, id) => {
    try {
        const res = await api.post(`api/entity/deleteRegister`, { model, id });
        return handleResponse(res);
    } catch (error) {
        return handleResponse(error.response);
    }
}

export const updateRegister = async (model, column, id, value) => {
    try {
        const res = await api.post(`api/entity/updateRegister`, { model, column, id, value });
        return handleResponse(res);
    } catch (error) {
        return handleResponse(error.response);
    }

}

export const getDataChainDependency = async (model, column) => {
    try {
        const res = await api.post(`api/entity/getDataChainDependency`, { model, column });
        return handleResponse(res);
    } catch (error) {
        return handleResponse(error.response);
    }
}

export const storeForm = async (model, arrayValues) => {
    try {
        const res = await api.post(`api/entity/storeForm`, { model, arrayValues });
        return handleResponse(res);
    } catch (error) {
        return handleResponse(error.response);
    }
}

export const downloadFile = async (nameFile, callback) => {
    api.post('api/entity/downloadFile', { nameFile }, {
        responseType: 'blob'
    }).then((response) => {
        console.log(response);
        fileDownload(response.data, nameFile)
        callback()
    });
}

export async function getColumnsAndFilterValues({ model }) {
    try {
        const res = await structure(model);
        // throw Error("error")

        if (res?.data?.error) {
            throw res;
        };

        var columnsTemporales = new Array();
        var tempValues = {};
        //Se le resta 100, del id, y 20 de los border de las tablas
        var widthAllElement = window.innerWidth - 116;
        var width = 0;
        var type = dataTypes.text;
        var initValue = "";

        for (var i = 0; i < res.data.structure.length; i++) {
            //Longitud por defecto de las columnas 300
            //Columnas que deben rellenar el espacio, son todas menos 3, id, internal consecutive y enable (columnas por default en todas las tablas)
            //QUITAMOS LAS COLUMNAS DE 100 QUE SON 3
            width = widthAllElement / (res.data.structure.length - 1);

            if (width < 150) {
                width = 150;
            }

            var filter = {};
            if (
                res.data.structure[i].COLUMN_NAME == "id" ||
                res.data.structure[i].COLUMN_NAME == "internalConsecutive"
            ) {
                initValue = "";
                width = 100;
                filter = {
                    available: false,
                };
            } else if (res.data.structure[i].OPTIONS) {
                initValue = [];
                type = dataTypes.select;
                filter = {
                    available: true,
                    type: dataTypes.select,
                    options: res.data.structure[i].OPTIONS,
                };
            } else if (res.data.structure[i].DATA_TYPE === dataTypes.float) {
                initValue = "";
                type = dataTypes.float;
                filter = {
                    available: true,
                    type: dataTypes.float,
                };
            } else if (
                res.data.structure[i].DATA_TYPE === dataTypes.int &&
                res.data.structure[i].COLUMN_NAME !== "id"
            ) {
                initValue = "";
                type = dataTypes.int;
                filter = {
                    available: true,
                    type: dataTypes.int,
                };
            } else if (
                res.data.structure[i].DATA_TYPE === "varchar" ||
                res.data.structure[i].DATA_TYPE === "String"
            ) {
                initValue = "";
                type = "text";
                filter = {
                    available: true,
                    type: "text",
                };
            } else if (
                res.data.structure[i].DATA_TYPE === "datetime2" ||
                res.data.structure[i].DATA_TYPE === "Date"
            ) {
                type = dataTypes.date;
                initValue = "";
                filter = {
                    available: true,
                    type: dataTypes.date,
                };
            } else if (res.data.structure[i].DATA_TYPE === "Boolean" || res.data.structure[i].DATA_TYPE === dataTypes.bit) {
                type = dataTypes.boolean;
                initValue = false;
                width = 150;
                filter = {
                    available: true,
                    type: dataTypes.boolean,
                };
            }
            else if (res.data.structure[i].DATA_TYPE == "Array") {
                type = dataTypes.array;
                initValue = [];
                width = 200;
                filter = {
                    available: false,
                    type: dataTypes.text,
                };
            }

            var headerName = "";

            const { validationType, validations } = res?.data?.structure[i] || {};

            if (
                !jsonTraslate[model] ||
                !jsonTraslate[model][res.data.structure[i].COLUMN_NAME]
            ) {
                headerName = model + "." + res.data.structure[i].COLUMN_NAME;
            } else {
                headerName = jsonTraslate[model][res.data.structure[i].COLUMN_NAME];
            }

            var jsonConfig = null;
            if (res.data.structure[i].CONFIG) {
                jsonConfig = JSON.parse(res.data.structure[i].CONFIG);
            }

            var objPush = {
                required: res.data.structure[i].REQUIRED,
                config: jsonConfig,
                component: res.data.structure[i].COMPONENT,
                object: res.data.structure[i],
                field: res.data.structure[i].COLUMN_NAME,
                headerName: headerName,
                width: width,
                type: type,
                filter: filter,
                options: res.data.structure[i].OPTIONS
                    ? res.data.structure[i].OPTIONS
                    : null,
                value: initValue,
                validationType,
                validations,
            };

            columnsTemporales.push(objPush);
            tempValues[res.data.structure[i].COLUMN_NAME] = objPush;
        };

        return { columns: columnsTemporales, filtesValues: tempValues }
    } catch (error) {
        throw error;
    }
}