import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import CustomLink from "../../common/CustomLink/CustomLink";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

export default function ItemSection({ option, handleClose, classes, level, updateState }) {

    const location = useLocation();

    return (
        <CustomLink
            to={option?.url ? option.url : null}
            onClick={option?.url ? handleClose : null}
        >
            <ListItem
                ref={element => element && element.style.setProperty("padding-left", `${30 * (level === 0 ? 0.2 : level)}px`, "important")}
                button
                key={uuidv4()}
                className={clsx(classes)}
                onClick={() => updateState(option.id)}
                selected={location?.pathname === option.url}
            >
                {option.icon && (
                    <ListItemIcon>
                        {option.icon}
                    </ListItemIcon>
                )}
                <ListItemText primary={option.label} />
                {!option?.url ? (option?.open ? (<ExpandLess />) : (<ExpandMore />)) : null}
            </ListItem>
        </CustomLink >
    );

}
