import React from "react";
import { Box, Stack, Typography, Grid, Item } from "@mui/material";
import NewMixesButton from "../NewMixesButton/NewMixesButton";

export default function MixesHeader({ quote, onReload, availableEdit, showButon = true }) {
    return (
        <Box className="displayFlex flexJustifyContentSpaceBetween flexAlignItemsBaseline fullWidth">
            <Grid container spacing={1}>
                <Grid container item xs={12} sm={12} md={6} lg={3}>
                    <Box className="cardItem">
                        <b>Cliente:</b> 
                        {quote?.customer?.name}
                    </Box>
                </Grid>
                
                <Grid container item xs={12} sm={12} md={6} lg={3}>
                    <Box className="cardItem">
                        <b>lugar de entrega:</b> 
                        {quote?.deliveryLocation}
                    </Box>
                </Grid>

                <Grid container item xs={12} sm={12} md={showButon ? 3:6} lg={showButon ? 2:3}>
                    <Box className="cardItem">
                        <b>Plazo de pago:</b>                     
                        {quote?.deadline?.value}
                    </Box>
                </Grid>

                <Grid container item xs={12} sm={12} md={showButon ? 3:6} lg={showButon ? 2:3}>
                    <Box className="cardItem">
                        <b>Tiempo entrega:</b> 
                        {quote?.deliveryTime}
                    </Box>
                </Grid>
                {showButon && (
                    <Grid container item xs={12} sm={12} md={6} lg={2}>
                        <NewMixesButton
                            quote={quote}
                            onReload={onReload}
                            availableEdit={availableEdit}
                        />                    
                    </Grid>
                )}
            </Grid>
        </Box >
    )
}
