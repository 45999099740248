import React, { useEffect } from "react";

export const showLoader = () =>
{
    if(!document.body.classList.add("loader-on"))
        document.body.classList.add("loader-on")
    
    if(document.getElementById("loader-box"))
        document.getElementById("loader-box").classList.add("on")
}

export const hideLoader = () =>
{
    if(document.body.classList.remove("loader-on"))
        document.body.classList.remove("loader-on")
    
    if(document.getElementById("loader-box"))
        document.getElementById("loader-box").classList.remove("on")
}

export default function Loader ({show})
{
    useEffect(() =>
    {
        if (show)
        {
            document.body.classList.add("loader-on")
        }
        else
        {
            document.body.classList.remove("loader-on")
        }
    }, [show]);

    return (
        <div id="loader-box" className={show ? "on" : ""}>
            <div className="loader">                
            </div>            
            <div className="labelLoading">
                Procesando Solicitud...
            </div>
        </div>
    )
}