import { v4 as uuidv4 } from "uuid";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import PeopleIcon from "@mui/icons-material/People";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Analytics, Assessment, Factory, Public, Settings, ReceiptLong, NoteAddTwoTone } from "@mui/icons-material";

import { ENV } from "./constants";

export const navigationMenuOptions = [
    {
        id: uuidv4(),
        label: "Dashboard",
        icon: <Analytics />,
        classes: [],
        url: "/dashboard",
        open: false,
        order: 1,
    },
    {
        id: uuidv4(),
        label: "Clientes",
        icon: <PeopleIcon />,
        classes: [],
        url: ENV.CLIENT_ROUTES.CUSTOMERS.PATH,
        open: false,
        order: 2,
    },
    {
        id: uuidv4(),
        label: "Cotizaciones",
        icon: <RequestQuoteIcon />,
        classes: [],
        url: ENV.CLIENT_ROUTES.QUOTES.PATH,
        open: false,
        order: 3,
    },
    {
        id: uuidv4(),
        label: "Configuraciones",
        icon: <SettingsApplicationsIcon />,
        url: "",
        open: false,
        order: 4,
        childrens: [
            {
                id: uuidv4(),
                label: "Fertilizantes",
                icon: <Settings />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.FERTILIZERS.PATH,
                open: false,
                order: 1,
            },
            {
                id: uuidv4(),
                label: "Elementos",
                icon: <Settings />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.ELEMENTS.PATH,
                open: false,
                order: 2,
            },
            {
                id: uuidv4(),
                label: "Fertilizantes por elemento",
                icon: <Settings />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.FERTILIZERSBYELEMENTS.PATH,
                open: false,
                order: 3,
            },
            {
                id: uuidv4(),
                label: "Zonas",
                icon: <Public />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.ZONES.PATH,
                open: false,
                order: 4,
            },
            {
                id: uuidv4(),
                label: "Historial de precios maquila",
                icon: <Factory />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.MAQUILA_COST.PATH,
                open: false,
                order: 5,
            },
        ]
    },
    {
        id: uuidv4(),
        label: "Parametrización",
        icon: <EngineeringIcon />,
        url: "",
        open: false,
        order: 5,
        childrens: [
            {
                id: uuidv4(),
                label: "Configuración de rentabilidad",
                icon: <ReceiptLong />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.PROFITABILITY.PATH,
                open: false,
                order: 1,
            },
            {
                id: uuidv4(),
                label: "Vigencia",
                icon: <ReceiptLong />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.VALIDITY.PATH,
                open: false,
                order: 2,
            },
            {
                id: uuidv4(),
                label: "Plazo de pago",
                icon: <ReceiptLong />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.DEADLINE.PATH,
                open: false,
                order: 3,
            },
            {
                id: uuidv4(),
                label: "Rangos de descuento para aprobación por rol",
                icon: <ReceiptLong />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.DISCOUNT_RANGES_BY_ROLS.PATH,
                open: false,
                order: 3,
            },            
            {
                id: uuidv4(),
                label: "Tipo de empaque",
                icon: <SettingsSuggestIcon />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.PACKAGINGTYPE.PATH,
                open: false,
                order: 4,
            },
            {
                id: uuidv4(),
                label: "Fertilizantes por defecto",
                icon: <SettingsSuggestIcon />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.FERTILIZERDEFAULT.PATH,
                open: false,
                order: 5,
            },            
            {
                id: uuidv4(),
                label: "Usuarios control de inventario",
                icon: <SettingsSuggestIcon />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.USERS_INVENTORY.PATH,
                open: false,
                order: 6,
            },
            {
                id: uuidv4(),
                label: "Información adicional cotización",
                icon: <NoteAddTwoTone />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.ADDITIONAL_INFO.PATH,
                open: false,
                order: 7,
            },
            {
                id: uuidv4(),
                label: "Páginas adicionales cotizaciones",
                icon: <NoteAddTwoTone />,
                classes: ["paddingSubMenu"],
                url: ENV.CLIENT_ROUTES.ADDITIONAL_PAGES.PATH,
                open: false,
                order: 8,
            }
        ]
    },
    {
        id: uuidv4(),
        label: "Reportes",
        icon: <Assessment />,
        classes: [],
        url: ENV.CLIENT_ROUTES.REPORTS.PATH,
        open: false,
        order: 6,
    },
];