import React, { useState } from "react";
import { Box } from "@mui/material";
import MixesHeader from "../MixesHeader/MixesHeader";
import MixesTable from "../MixesTable/MixesTable";

export default function Mixes({ quote, setQuote, setReloading, availableEdit }) {

    const [reload, setReload] = useState(false);
    const [additionalMix, setAdditionalMix] = useState(null);

    const onReload = (newMix) => {
        if(newMix){
            //Si hay una definicion de la mezcla, se verifica que no exista previamenta            
            setAdditionalMix(newMix);
            if (setReloading instanceof Function) setReloading();
            setReload(prevState => !prevState);
        }else{
            if (setReloading instanceof Function) setReloading();
            setReload(prevState => !prevState);
        }
    };

    return (
        <Box
            className="displayFlex flexDirectionColumn height100 noMargin">
            <MixesHeader
                quote={quote}
                onReload={onReload}
                availableEdit={availableEdit}/>
            <MixesTable
                quote={quote}
                additionalMix={additionalMix}
                setQuote={setQuote}
                reload={reload}
                onReload={onReload}
                availableEdit={availableEdit}/>
        </Box>
    )
}
