import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import CellTable from "../../CellTable";
import { IconButton, Tooltip } from "@mui/material";

export default function TableContentRow(props) {
    const { row, typeDatatable, setIdRegistro, setOpenDelete, model, columns, ActionsButtons, loadData, modelIsView, permissions } = props;

    const [openModal, setOpenModal] = useState(false);

    const onOpenCloseModal = () => setOpenModal(!openModal);

    let actions = null;

    if (ActionsButtons) {
        actions = <ActionsButtons id={row?.id} row={row} onReload={loadData} columns={columns} />
    } else {
        actions = (
            <React.Fragment>
                <IconButton
                    aria-label="delete"
                    color='inherit'
                    size='small'
                    disabled={!permissions?.editPermission}
                    onClick={() => {
                        setIdRegistro(row.id ?? row._id);
                        setOpenDelete(true);
                    }}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>


                {typeDatatable &&
                    <Tooltip title="Detalle">
                        <IconButton
                            aria-label="Detalle"
                            color="inherit"
                            size="small"
                            onClick={onOpenCloseModal}>
                            <MenuOpenIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                }
            </React.Fragment>
        );
    }

    return (
        <>
            <div className="displayContents">
                <div className="cellTableFirstCell tableFirstColum">
                    {/* Renderizar botones de acción */}
                    {actions}

                </div>

                {columns.map((column, iindex) => {
                    if (column.field != "id" && column.field != "_id" && !column.config?.hiddenToSee) {
                        return (
                            <CellTable
                                key={iindex + 4000}
                                model={model}
                                row={row}
                                column={column}
                                modelIsView={modelIsView}
                                permissions={permissions}/>                            
                        )
                    }
                })}
            </div>
        </>
    )
}
