import React, { useEffect, useMemo } from "react";
import { Autocomplete, Box, Button } from "@mui/material";
import { Save } from "@mui/icons-material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import SelectAutocomplete from "../../Controls/SelectAutocomplete";
import { CssTextField } from "../../Controls/CustomTextField";
import { getYupSchemaFromMetaData } from "../../../utils/yupSchemaCreator";
import { ENV, roles } from "../../../utils/constants";
import { hideLoader, showLoader } from "../../Controls/Loader";
import { createNewRegister, updateRegister } from "../../../apiCore/entity";

// Roles habilitados asignar un grupo como propietarios.
const groupTypeOptions = ["Aprobador", "Jefe Regional"];

function initialValues(row) {
    return {
        name: row?.name ?? "",
        ownerId: row?.ownerId ?? "",
        groupType: row?.groupType ?? "",
    };
}

export default function GroupsForm({ columns, onReload, onClose, row }) {

    const { enqueueSnackbar } = useSnackbar();

    const dinamicSchema = getYupSchemaFromMetaData(columns, {}, []);

    const reloadAndClose = () => {
        // Recargar tabla de datos
        if (onReload instanceof Function) onReload();
        // Cerrar modal
        if (onClose instanceof Function) onClose();
    }

    const formik = useFormik({
        initialValues: initialValues(row),
        validationSchema: dinamicSchema,
        onSubmit: async (values) => {
            try {
                showLoader();
                if (row) {
                    // Actualizar datos
                    const { id } = row || {};
                    if (!id) {
                        enqueueSnackbar("Error, se requiere un ID valido para actualizar el registro", { variant: "error" });
                        return
                    }

                    const response = await updateRegister({
                        model: ENV.CLIENT_ROUTES.GROUPS.MODEL,
                        id,
                        data: values,
                    });

                    const { error, message } = response?.data || {};
                    if (error) {
                        enqueueSnackbar(error, { variant: "error" });
                        return;
                    }

                    if (message) enqueueSnackbar(message, { variant: "success" });

                } else {
                    // Crear nuevo registro
                    const response = await createNewRegister({
                        model: ENV.CLIENT_ROUTES.GROUPS.MODEL,
                        data: values
                    });
                    const { error, message } = response?.data || {};
                    if (error) {
                        enqueueSnackbar(error, { variant: "error" });
                        return;
                    }

                    if (message) {
                        enqueueSnackbar(message, { variant: "success" });
                    }
                }

                // Recargar datos de tabla y cerra modal.
                reloadAndClose();

            } catch (err) {
                const { error } = err?.response?.data;
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al registrar la información", { variant: "error" })
                }
            } finally {
                hideLoader();
            }
        }
    });

    // Determinar el role a consultar dependiendo del valor que se haya seleccionado
    // en el campo tipo de grupo (groupType).
    const roleToConsult = useMemo(() => {
        const { groupType } = formik.values;
        if (groupType === groupTypeOptions[0]) {
            return roles.aprobador;
        } else if (groupType === groupTypeOptions[1]) {
            return roles.RTCSenior;
        } else {
            return null;
        }
    }, [formik.values.groupType])

    useEffect(() => {
        if (formik.submitCount > 0) {
            columns.map(column => {
                formik.setFieldTouched(column?.field, true);
            });
        }
    }, [formik.submitCount]);

    return (
        <Box
            className="backgroundPlain">
            <Box
                onSubmit={formik.handleSubmit}
                component="form"
                className="panel">
                <Box
                    className="displayFlex flexDirectionColumn"
                    padding={1}
                    gap={1}>
                    <CssTextField
                        label="Nombre"
                        name="name"
                        fullWidth
                        size="small"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik?.touched?.["name"] && Boolean(formik?.errors?.["name"])}
                        helperText={formik?.touched?.["name"] && formik?.errors?.["name"]}/>

                    <Autocomplete
                        size="small"
                        value={formik.values.groupType}
                        disabled={Boolean(row)}
                        options={groupTypeOptions}
                        renderInput={(params) => (
                            <CssTextField
                                {...params}
                                name="groupType"
                                label="Tipo de grupo"
                                error={formik?.touched?.["groupType"] && Boolean(formik?.errors?.["groupType"])}
                                helperText={formik?.touched?.["groupType"] && formik?.errors?.["groupType"]}/>
                        )}
                        onChange={(_, newValue) => {
                            formik.setFieldValue("groupType", newValue ?? "");
                            formik.setFieldValue("ownerId", "");
                        }}
                        isOptionEqualToValue={(option, value) => option === value || value === ""}/>

                    <SelectAutocomplete
                        key={formik.values.groupType}
                        CustomTextField={CssTextField}
                        disabled={!Boolean(formik.values.groupType)}
                        reload={formik.values.groupType}
                        size="small"
                        model="users"
                        label="Propietario"
                        column="name"
                        name="owner"
                        conditions={
                            {
                                roleId: roleToConsult,
                            }
                        }
                        value={formik.values.ownerId}
                        textfieldError={formik?.touched?.ownerId && Boolean(formik?.errors?.ownerId)}
                        textfieldHelpertext={formik?.touched?.ownerId && formik?.errors?.ownerId}
                        onChange={async (id, _, __, value) => {
                            await formik.setFieldValue("ownerId", id ?? "");
                        }}
                        isMatch={(match) => {
                            if (!match && row) {
                                const message = "El campo propietario tiene asignado un valor que no corresponde a las opciones disponibles. Por favor actualice el campo con una opción valida";
                                enqueueSnackbar(message, { variant: "error" });
                                formik.setFieldValue("ownerId", "");
                            }
                        }}/>
                </Box>
                <Box
                    padding={1}>
                    <Button
                        variant="contained"
                        fullWidth
                        endIcon={<Save />}
                        type="submit"
                        disabled={formik.isSubmitting}>
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}