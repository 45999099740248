import React from "react";
import ManualItem from "./ManualItem";
import { Divider } from "@mui/material";

export default function ManualList({ videos }) {

    return videos.map(video => (
        <React.Fragment key={video?.id}>
            <ManualItem video={video} />
            <Divider />
        </React.Fragment>
    ))
}
