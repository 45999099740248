import React from "react";
import MenuNavegacion from "../../components/MenuNavegacion/MenuNavegacion";
import { Outlet, useLocation } from "react-router-dom";
import { useMainContext } from "../../hooks/useMainContext";
import NotAuthorized401 from "../../components/HttpState/NotAutorized401/NotAuthorized401";
import { ENV, roles } from "../../utils/constants";
import { Box } from "@mui/material";

export default function AcessControlRoute() {
    const { availableMenuPaths = [], user } = useMainContext();

    const location = useLocation();

    if (user?.roleId === roles.administrador) return <Outlet />;

    const findMenu = availableMenuPaths?.find(menu => menu?.menuPath === location?.pathname && (menu?.editPermission || menu?.viewPermission));

    // Si la ruta corresponde al Dashboard y no se tiene permiso para visualizar ni editar en esa sección,
    // se muestra al usuario una pantalla vacía.
    if (location?.pathname === ENV.CLIENT_ROUTES.DASHBOARD.PATH && !findMenu) {
        return (
            <MenuNavegacion
                ContentComponent={() => <Box></Box>}
                titlePage={ENV.CLIENT_ROUTES.DASHBOARD.TITLE_PAGE}
            />
        );
    }

    return (
        findMenu
            ? <Outlet />
            : (
                <MenuNavegacion
                    ContentComponent={NotAuthorized401}
                />
            )

    );
}