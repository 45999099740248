import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import FilterFieldGroup from "./FilterFieldGroup";
import { getDataForBasicColumnChart } from "../../apiCore/dashboard";
import { useSnackbar } from "notistack";

export default function BasicColumnChart({
    title,
    apiRoute = "api/dashboard/basicColumnChart",
    model,
    xAxisColumnNameForCategories,
    order = {},
    series = [],
    columnNameForValues,
    tooltip = {
        valueSuffix: "",
    },
    yAxisTitle,
    xAxisTitle = "Mes",
    filterFields = [],
    dataLabelFormat = null,
}) {

    const matchesMediaQuery = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const { enqueueSnackbar } = useSnackbar();

    const [filter, setFilter] = useState(() => {
        const fieldFound = filterFields.find(field => field?.columnName === "year");
        if (fieldFound) {
            return { year: new Date().getFullYear() }
        }
    });

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: "column",
            zoomType: "x",
        },
        title: {
            text: "",
        },
        xAxis: {
            title: {
                text: xAxisTitle,
            },
            categories: [],
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle,
            },
        },
        tooltip,
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    format: dataLabelFormat,
                },
            },
        },
        series: [],
        credits: {
            enabled: false,
        }
    });

    useEffect(() => {
        (async () => {
            try {
                chartRef.current?.chart?.showLoading()
                const response = await getDataForBasicColumnChart({
                    apiRoute,
                    viewName: model,
                    order,
                    xAxisColumnNameForCategories,
                    series,
                    columnNameForValues,
                    filter,
                });

                const { error, data } = response?.data || {};

                if (error) throw { error };

                if (data) {
                    // Validación cuando la consulta retorne un array vacío.
                    if (data?.length === 0) {
                        updateChartOptions([], []);
                        setChartOptions(prevState => ({ ...prevState, lang: { noData: "No hay información disponible" } }));
                        return;
                    }

                    // Categorias de los datos.
                    const categories = [...new Set(data.map(item => item?.[xAxisColumnNameForCategories]))];

                    // Series de datos.
                    let seriesArray = [];

                    // Validar si el parámetro series es un Array.
                    if (series instanceof Array) {
                        // Se recorre cada serie del array.
                        series.forEach(serie => {
                            const seriesName = [...new Set(data.map(item => item?.[serie]))];

                            seriesName.forEach(serieName => {
                                let serieObject = {
                                    name: series instanceof Array ? serieName : series,
                                    data: [],
                                };
                                categories.forEach((category, index) => {
                                    const filterData = data.filter(item => item?.[xAxisColumnNameForCategories] === category && item?.[series[0]] === serieName);
                                    const suma = filterData.reduce((accumalator, item) => accumalator + item?.[columnNameForValues], 0);
                                    serieObject.data.splice(index, 0, suma);
                                });
                                seriesArray.push(serieObject);
                            });
                        })

                    } else if (typeof series === "string") {
                        let serieObject = {
                            name: series,
                            data: [],
                        };
                        categories.forEach((category, index) => {
                            const filterData = data.filter(item => item?.[xAxisColumnNameForCategories] === category);
                            const suma = filterData.reduce((accumalator, item) => accumalator + item?.[columnNameForValues], 0);
                            serieObject.data.splice(index, 0, suma);
                        });
                        seriesArray.push(serieObject);
                    }

                    updateChartOptions(categories, seriesArray);
                }

            } catch (err) {
                const { error } = err?.data || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar(`Error al cargar los datos del gráfico ${title ? title : ""} `, { variant: "error" });
                }
            } finally {
                chartRef.current?.chart?.hideLoading()
            }
        })();
    }, [filter]);

    const updateChartOptions = (categories, series) => {
        setChartOptions({ ...chartOptions, xAxis: { ...chartOptions.xAxis, categories }, series })
    }

    const updateFilter = useCallback((columnName, newValue) => {
        setFilter(prevState => ({ ...prevState, [`${columnName}`]: newValue }));
    }, [filter]);

    const chartRef = useRef();

    return (
        <Box
            component={Paper}
            height="100%"
            elevation={0}
        >
            <Typography p={1} variant="h5" >{title}</Typography>
            {filterFields?.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexWrap: matchesMediaQuery ? "nowrap" : "wrap",
                        alignItems: "center",
                        gap: "0.3rem",
                        p: 1,
                    }}
                >
                    <FilterFieldGroup
                        updateFilter={updateFilter}
                        model={model}
                        filterFields={filterFields}
                    />
                </Box>
            )}
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
                containerProps={{ style: { height: "100%" } }}
            />
        </Box>
    )
}