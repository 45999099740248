import React from "react";
import * as Yup from "yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import { hideLoader, showLoader } from "../../Controls/Loader";
import { rejectQuote } from "../../../apiCore/approvalRequest";

export default function ApprovalRequestComments({ comment, approvalRequestId, onReload, quoteId, onClose, onReloadDatatable, onCloseApprovalListModal }) {

    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: { comment },
        validationSchema: Yup.object({
            comment: Yup.string()
                .required("Por favor ingrese un comentario de rechazo")
                .max(500, "El comentario debe de ser de máximo 500 caracteres"),
        }),
        onSubmit: async (values) => {
            try {
                showLoader();

                const response = await rejectQuote({
                    approvalRequestId,
                    quoteId,
                    comment: values.comment,
                });

                const { error, message } = response?.data || {};

                if (error) throw { error };

                if (message) enqueueSnackbar(message, { variant: "success" });

                if (onReload instanceof Function) onReload();

                if (onClose instanceof Function) onClose();

                if (onReloadDatatable instanceof Function) onReloadDatatable();

                if (onCloseApprovalListModal instanceof Function) onCloseApprovalListModal();

                hideLoader();
            } catch (err) {
                const { error } = err?.response?.data || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" })
                }
                else {
                    enqueueSnackbar("Error al rechazar la cotización", { variant: "error" });
                }
                hideLoader();
            }
        },
    });

    return (
        <Box
            className="commentsContainer"
            component="form"
            onSubmit={formik.handleSubmit}
        >
            <Typography
                variant="body2"
                sx={{
                    fontSize: {
                        sx: "0.8rem",
                        sm: "1.2rem",
                    }
                }}
            >
                Por favor ingrese el motivo de rechazo de la cotización
            </Typography>
            <TextField
                label="Comentario"
                name="comment"
                fullWidth
                multiline
                value={formik.values.comment}
                onChange={formik.handleChange}
                error={formik.touched.comment && Boolean(formik.errors.comment)}
                helperText={formik.touched.comment && formik.errors.comment}
            />
            <Button
                fullWidth
                variant="contained"
                size="large"
                type="submit"
            >
                Enviar
            </Button>
        </Box>
    )
}
