import React from "react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from "@mui/material";

export default function HaederIcon(props) {
    const { columnField, handleOrderValueChange, setHeaders, iconDown } = props;

    const getOrder = (state) => {
        return state ? "DESC" : "ASC";
    }

    const handleArrowDirectionChange = () => {
        setHeaders(prevState => {
            const newHeaders = prevState.map(item => {
                if (item.field === columnField) {
                    handleOrderValueChange({ field: columnField, order: getOrder(!iconDown) });
                    return ({ ...item, iconDown: !iconDown })
                }
                return ({ ...item, iconDown: true })
            })
            return newHeaders;
        });
    }

    return (
        <IconButton
            aria-label="Order"
            color="inherit"
            size="small"
            onClick={handleArrowDirectionChange}
        >
            {iconDown ? (
                <ArrowDownwardIcon />
            ) : (
                <ArrowUpwardIcon />
            )}
        </IconButton>
    )
}
