import React from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import Field from "../../Field";
import { dataTypes } from "../../../../utils/constants";

export default function TableFilter(props) {
    const { columns, model, filtersValues, setFiltersValues, onFilterValuesChange } = props;
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Grid item xs={12} className="headerTablePage tableFilter displayContents">
            <div className="displayContents">
                <div
                    key={999}
                    className="tableFirstColum"
                >
                </div>
                {columns.map((column, index) => {
                    if (column.field != "id" && column.field != "_id" && !column.config?.hiddenToSee) {
                        return (
                            <div
                                key={index + 1000}
                                className="tableFilterInputContainer"
                            >
                                <Field
                                    isFilter={true}
                                    enqueueSnackbar={enqueueSnackbar}
                                    valuesForm={filtersValues}
                                    model={model}
                                    key={index}
                                    column={column}
                                    onChange={(value) => {
                                        onFilterValuesChange(() => {
                                            // Actualizar el valor de los filtros.
                                            var formTemporal = { ...filtersValues };
                                            var tempObj = formTemporal[column.field];

                                            if (column.type === dataTypes.select) {
                                                tempObj.value = value;
                                            } else if (column.type === dataTypes.boolean) {
                                                tempObj.value = value;
                                                tempObj.filterReset = false;
                                            }
                                            else {
                                                tempObj.value = value;
                                            }

                                            tempObj.type = column.type;
                                            formTemporal[column.field] = tempObj;
                                            setFiltersValues(formTemporal);
                                        });
                                    }}
                                >
                                </Field>
                            </div>
                        )
                    }
                })}
            </div>
        </Grid>
    )
}
