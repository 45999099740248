import { CopyAll } from "@mui/icons-material";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import QuoteStepper from "../../QuoteStepper/QuoteStepper";
import { useSnackbar } from "notistack";
import { copyQuote } from "../../../../apiCore/quotes";
import { hideLoader, showLoader } from "../../../Controls/Loader";
import ConfirmModal from "../../../Controls/ConfirmModal";

export default function CopyButton({ quoteId, onReload }) {

    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const [newQuoteId, setNewQuoteId] = useState(null);

    const onOpenCloseModal = () => setOpen(prev => !prev);

    const handleCopyQuote = async () => {
        try {
            showLoader();
            const response = await copyQuote({ quoteId });
            const { error, newQuoteId: _newQuoteId, message } = response?.data || {};

            if (error) throw { response };

            if (_newQuoteId) {
                // Actualizar lista de cotizaciones.
                if (onReload instanceof Function) onReload();

                setNewQuoteId(_newQuoteId);

                if (message) {
                    enqueueSnackbar(message, { variant: "success" });
                }
                setOpenConfirmModal(false);
                onOpenCloseModal();
            } else {
                hideLoader();
            }
        } catch (err) {
            hideLoader();
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al copiar la cotización", { variant: "error" });
            }
        }
    }

    return (
        <React.Fragment>
            <Tooltip title="Copiar cotización">
                <IconButton
                    aria-label="copy quote"
                    color='inherit'
                    size='small'
                    onClick={() => setOpenConfirmModal(true)}
                >
                    <CopyAll />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={onOpenCloseModal}
                fullScreen
            >
                <QuoteStepper
                    key={quoteId}
                    quote={newQuoteId}
                    onClose={onOpenCloseModal}
                    onReload={onReload}
                />
            </Dialog>

            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                titleMessage="Copiar cotización"
                confirmMessage={`¿Desea copiar la información de la cotización ${quoteId}?`}
                confirmHandler={handleCopyQuote}
                confirmButtonText="Copiar"
            />
        </React.Fragment>
    );
}
