import React, { useState } from "react";
import { Edit, RemoveRedEye } from "@mui/icons-material";
import { Dialog, IconButton, Tooltip } from "@mui/material";

import QuoteStepper from "../../QuoteStepper/QuoteStepper";
import { quoteStatus } from "../../../../utils/constants";

export default function EditButton({ id, onReload, isOwner, row }) {
    const { quoteStatusId } = row || {};

    const [open, setOpen] = useState(false);

    const onOpenCloseModal = () => setOpen(prev => !prev);

    let content = null;

    if ((isOwner && (quoteStatusId === quoteStatus.enElaboracion || quoteStatusId === quoteStatus.rechazada))) {
        content = (
            <Tooltip title="Editar">
                <IconButton
                    aria-label="edit"
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    <Edit fontSize="inherit" />
                </IconButton>
            </Tooltip>
        );
    } else {
        content = (
            <Tooltip title="Ver cotización">
                <IconButton
                    aria-label="edit"
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    <RemoveRedEye fontSize="inherit" />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <React.Fragment>

            {content}

            <Dialog
                open={open}
                onClose={onOpenCloseModal}
                fullScreen
            >
                <QuoteStepper
                    key={id}
                    quote={id}
                    onClose={onOpenCloseModal}
                    onReload={onReload}
                />
            </Dialog>
        </React.Fragment>
    );
}
