import React, { useEffect, useMemo, useState } from "react";
import { Box, TextField, Typography, Grid } from "@mui/material";
import SelectAutocomplete from "../../Controls/SelectAutocomplete";
import { CustomStack } from "../QuoteForm/QuoterForm";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./QuoteFormCost.form";
import CurrencyFormat from "react-currency-format";
import api from "../../../axios/axios";
import { hideLoader, showLoader } from "../../Controls/Loader";
import { useSnackbar } from "notistack";
import { updateRegisterMix } from "../../../apiCore/entity";
import { ENV } from "../../../utils/constants";
import md5 from "md5";

// Componente para evitar advertencias de propTypes de CurrencyFormat cuando se usa la propiedad customInput
const CustomTextField = inputProps => <TextField {...inputProps} />

export default function QuoteFormCost({ mix, onReload, closeModal, availableEdit, profit }) {
    const { enqueueSnackbar } = useSnackbar();
    const [isError, setIsError] = useState(false);

    const [naJunior, setNaJunior] = useState(0);
    const [naSenior, setNaSenior] = useState(0);
    const [naAprobador, setNaAprobador] = useState(0);

    //La rentabilidad es definida a nivel general
    //Solo se asigna si la mezcla no tiene rentabilidad inicial, en los casos de edición, no se asigna una rentabilidad a partir de la general, para
    //Evitar modificar las condiciones del negocio.
    if(!mix.profitability){
        console.log("Mezcla con rentabilidad indefinida");
        mix.profitability = profit.value;
    }

    const formik = useFormik({
        initialValues: initialValues({ mix }),
        validationSchema: validationSchema(),
        validateOnChange: true,
        onSubmit: async (values) => {

            if (!availableEdit) {
                enqueueSnackbar("La cotización no se puede editar", { variant: "error" });
                return;
            }

            let dataMix = { ...values };
            let packaging = dataMix?.packaging;
            delete dataMix?.packaging;
            let idQuery = mix?.id ? mix?.id:mix?.mixId

            try {
                showLoader();
                // Registrar costos a la cotización
                const response = await updateRegisterMix({model: ENV.CLIENT_ROUTES.MIXES.MODEL, id: idQuery, data: dataMix})

                const { error, message } = response?.data;

                //Puede existir un error de comunicacion pero se controla debido a la operacion offline
                if (error) {
                    //enqueueSnackbar(error, { variant: "error" });
                    hideLoader();
                    return;
                }

                if (message) {
                    enqueueSnackbar(message, { variant: "success" });
                    if (closeModal instanceof Function) closeModal();
                }

                if (onReload instanceof Function) {
                    onReload({...response?.data?.data[0], quoteId: mix?.quoteId, mixId:mix?.mixId, packaging:packaging, });
                }

                hideLoader();

            } catch (err) {
                hideLoader();                
                const { error } = err?.response?.data || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al registrar los datos de la mezcla", { variant: "error" });
                }
            }

        }
    });

    // Calcular el costo de las materias primas
    // Formula: quantityTons * precio por tonelada.
    const rawMaterialCost = useMemo(() => {
        const quantityTons = Number(formik.values.quantityTons) || 0;
        const price = mix?.price || 0;
        const totalTons = mix?.totalTons || 0;
        const pricePerTon = (Number(price / totalTons) || 0) * 1000;

        // result = Number(pricePerTon * quantityTons);
        return Number(pricePerTon * quantityTons);

        // return parseFloat(result).toFixed(2);
    }, [mix, formik.values.quantityTons]);

    const maquilaCost = useMemo(() => {
        return (((Number(formik.values.maquilaCost) * Number(formik.values.quantityTons)) || 0) + Number(rawMaterialCost)) || 0;
    }, [formik.values]);


    // Calcular el número de empaques
    const calculateQuantityPackaging = useMemo(() => {
        let result = 0;
        const totalTons = Number(mix?.totalTons) || 0;
        const packagingCapacity = Number(formik.values?.packaging?.value) || 0;
        const quantityTons = Number(formik.values.quantityTons) || 0;

        // result = Number(totalTons / packagingCapacity) || 0;
        result = (Number(1000 / packagingCapacity) * quantityTons) || 0;
        if (!isFinite(result)) {
            result = 0;
        }
        return result;
    }, [mix, formik.values]);


    // Calcular el subtotal del costo de la cotización.
    // Precio + costo maquila + (número empaques * valor unitario)
    const subtotal = useMemo(() => {
        const quantityPackaging = Number(calculateQuantityPackaging) || 0;
        const packaginUnitPrice = Number(formik.values.packagingUnitPrice) || 0;
        return Number(Number(maquilaCost) + Number((quantityPackaging * packaginUnitPrice))) || 0;
    }, [formik.values, maquilaCost]);

    // Calcular el valor con la rentabilidad.
    const profitability = useMemo(() => {
        const profitabilityPercentage = Number(formik.values.profitability / 100) || 0;
        return Number(subtotal / (1 - profitabilityPercentage)) || 0;
    }, [subtotal, formik.values.profitability]);

    // Calcula el valor con el descuento.
    const discount = useMemo(() => {
        const discountPercentage = Number(formik.values.discount / 100) || 0;
        return Number(profitability * (1 - discountPercentage)) || 0;
    }, [profitability, formik.values.discount]);

    // Calcula el valor del flete por el número de toneladas.
    const totalFlete = useMemo(() => {
        return (Number(formik.values.flete) * Number(formik.values.quantityTons)) || 0
    }, [discount, formik.values.flete])

    // Calcula el total del costo de la cotización.
    const total = useMemo(() => {
        return (Number(discount) + Number(totalFlete)) || 0;
    }, [discount, formik.values]);

    useEffect(() => {
    }, []);

    useEffect(()=>{        
        setNaJunior(subtotal / 0.895);
        setNaSenior(subtotal / 0.91);
        setNaAprobador(subtotal / 0.92);

    },[subtotal])

    if (isError) return <Box>Error al cargar la información, por favor intente más tarde.</Box>

    return (
        <Box
            className="displayFlex flexDirectionColumn height100 noMargin">
            <Box>
                <Typography
                    variant="h6"
                    fontSize={{
                        xs: "1.1rem",
                        sm: "1.25rem",
                    }}>
                    {mix?.name}
                </Typography>
                <Typography variant="subtitle1">
                    {mix?.type?.name}
                </Typography>
            </Box>
            <Box
                component={"form"}
                onSubmit={formik.handleSubmit}
                id="quote-cost-form">
                <CustomStack>
                    <TextField
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        fullWidth
                        label="Cantidad de toneladas"
                        name="quantityTons"
                        disabled={!availableEdit}
                        value={formik.values.quantityTons}
                        onChange={formik.handleChange}
                        error={formik.touched.quantityTons && Boolean(formik.errors.quantityTons)}
                        helperText={formik.touched.quantityTons && formik.errors.quantityTons}/>

                    <CurrencyFormat
                        disabled
                        fullWidth
                        customInput={CustomTextField}
                        label="Costo materia prima"
                        value={parseFloat(rawMaterialCost).toFixed(2)}
                        thousandSeparator
                        prefix="$ "/>
                </CustomStack>
                <CustomStack>
                    <SelectAutocomplete
                        model="packagingType"
                        label="Tipo de empaque"
                        column="name"
                        disabled={!availableEdit}
                        value={mix?.packagingTypeId}
                        sx={{
                            width: "100%"
                        }}
                        onChange={async (id, _, __, value) => {
                            await formik.setFieldValue("packagingTypeId", id ?? "");
                            formik.setFieldValue("packagingUnitPrice", value?.unitPrice ?? "");
                            formik.setFieldValue("packaging", value);

                            console.log(value);

                            await formik.setFieldValue("maquilaCost", value.maquilaPrice);
                        }}
                        textfieldError={formik?.touched?.packagingTypeId && Boolean(formik?.errors?.packagingTypeId)}
                        textfieldHelpertext={formik?.touched?.packagingTypeId && formik?.errors?.packagingTypeId}
                        getDefaultValue={(Option) => {
                            if (Option) {
                                formik.setFieldValue("packagingTypeId", Option?.id);
                                formik.setFieldValue("packagingUnitPrice", Option?.unitPrice);
                                formik.setFieldValue("packaging", Option);
                            }
                        }}/>
                    <TextField
                        fullWidth
                        disabled
                        value={parseFloat(calculateQuantityPackaging).toFixed(1)}
                        label="Cantidad empaques"
                        name="quantityPackaging"/>
                </CustomStack>  
                <CustomStack>
                    <CurrencyFormat
                        fullWidth
                        customInput={CustomTextField}
                        disabled
                        label="Maquila"
                        name="maquilaCost"
                        thousandSeparator
                        prefix=" $"
                        value={formik.values.maquilaCost}
                        onChange={formik.handleChange}
                        error={formik.touched.maquilaCost && Boolean(formik.errors.maquilaCost)}
                        helperText={formik.touched.maquilaCost && formik.errors.maquilaCost}/>

                    <CurrencyFormat
                        customInput={CustomTextField}
                        disabled
                        fullWidth
                        label="Costo + (maquila x toneladas)"
                        value={parseFloat(maquilaCost).toFixed(2)}
                        thousandSeparator
                        prefix="$ "/>
                </CustomStack>
                <CustomStack>
                    <div style={{ width: "100%" }} />
                    <CurrencyFormat
                        customInput={CustomTextField}
                        thousandSeparator
                        fullWidth
                        prefix="$ "
                        disabled
                        value={parseFloat(subtotal).toFixed(2)}
                        label={"Costo + maquila + empaques (" + calculateQuantityPackaging + ")"}
                    />
                </CustomStack>
                <CustomStack>
                    <div style={{ width: "100%" }} />
                    <TextField
                        disabled
                        hidden
                        fullWidth
                        label="% Rentabilidad"
                        name="profitability"
                        value={formik.values.profitability}
                        onChange={formik.handleChange}
                        error={formik.touched.profitability && Boolean(formik.errors.profitability)}
                        helperText={formik.touched.profitability && formik.errors.profitability}/>

                    <CurrencyFormat
                        customInput={CustomTextField}
                        thousandSeparator
                        fullWidth
                        prefix="$ "
                        disabled
                        label="Precio"
                        name="rentabilidadPrice"
                        value={parseFloat(profitability).toFixed(2)}/>
                </CustomStack>
                <CustomStack>
                    <TextField
                        fullWidth
                        name="discount"
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                        label="% Descuento"
                        disabled={!availableEdit}
                        error={formik.touched.discount && Boolean(formik.errors.discount)}
                        helperText={formik.touched.discount && formik.errors.discount}/>
                    <CurrencyFormat
                        customInput={CustomTextField}
                        thousandSeparator
                        fullWidth
                        prefix="$ "
                        disabled
                        value={parseFloat(discount).toFixed(2)}
                        label="Precio + descuento"/>
                </CustomStack>
                <CustomStack>
                    <TextField
                        fullWidth
                        name="flete"
                        label="Flete por tn"
                        value={formik.values.flete}
                        onChange={formik.handleChange}
                        disabled={!availableEdit}
                        error={formik.touched.flete && Boolean(formik.errors.flete)}
                        helperText={formik.touched.flete && formik.errors.flete}/>
                    <CurrencyFormat
                        customInput={CustomTextField}
                        fullWidth
                        thousandSeparator
                        prefix="$ "
                        disabled
                        value={parseFloat(totalFlete).toFixed(2)}
                        name="fletePerTnPrice"
                        label="Flete por tn en pesos"/>
                </CustomStack>

                <CustomStack
                    spacing={{ xs: 0 }}>
                    <div style={{ width: "100%", display: "inline" }} />
                    <CurrencyFormat
                        customInput={CustomTextField}
                        disabled
                        fullWidth
                        label="Total"
                        name="total"
                        value={parseFloat(total).toFixed(2)}
                        thousandSeparator
                        prefix="$ "/>
                </CustomStack>
            </Box>
        </Box >
    )
}
