import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material"
import Card from "./Card";
import { hideLoader, showLoader } from "../Controls/Loader";
import { useSnackbar } from "notistack";
import { getAvailableReports } from "../../apiCore/reports";


export default function Reports() {
    const [reports, setReports] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setisError] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        (async () => {
            try {
                showLoader();
                const response = await getAvailableReports();
                const { error, data } = response?.data || {};
                if (error) throw { response };

                if (data) setReports(data);
            } catch (err) {
                const { error } = err?.response || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al obtener los reportes dispoonible", { variant: "error" });
                }
                setisError(true);
            } finally {
                hideLoader();
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <Box
            className="backgroundPlain">
            <Box
                padding={1}>
                <Typography variant="h5">
                    Descarga de reportes
                </Typography>
            </Box>
            <Box
                padding={1}
            >
                <Typography variant="body2">
                    En esta sección podras descargar los reportes disponibles.
                </Typography>
            </Box>

            {(!isLoading && isError) && (
                <Box padding={1} className="error">Error al cargar los reportes</Box>
            )}

            {(reports?.length === 0 && !isLoading && !isError) && (
                <Box padding={1}>No hay reportes disponibles</Box>
            )}

            {(reports?.length > 0 && !isLoading && !isError) && (
                <Box
                    className="reportsConatiner"
                    sx={{
                        gridTemplateColumns: `repeat(${reports.length < 3 ? "auto-fill" : "auto-fit"}, minmax(250px, 1fr))`,
                    }}
                >
                    {reports.map((report) => {
                        return (
                            <React.Fragment key={report?.id}>
                                <Card
                                    report={report}
                                />
                            </React.Fragment>
                        )
                    })}
                </Box>
            )}
        </Box >
    )
}
