import { PlayCircleFilledOutlined } from "@mui/icons-material"
import { Box, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material"
import React, { useState } from 'react'
import BasicModal from "../common/BasicModal/BasicModal"
import YouTubeVideoPlayer from "../VideoPlayer/YouTubeVideoPlayer"

export default function ManualItem({ video }) {
    const [open, setOpen] = useState(false);

    const onOpenClose = () => setOpen(prevState => !prevState);

    return (
        <React.Fragment>
            <Box
                className="displayFlex flexJustifyContentSpaceBetween flexAlignItemsCenter"
                padding={1}
            >
                <Box>
                    <Typography variant="body2" fontWeight="700">
                        {video?.name}
                    </Typography>
                    <Typography variant="body1">
                        {video?.description}
                    </Typography>
                </Box>
                <Box
                    className="displayFlex flexJustifyContentCenter flexAlignItemsCenter"
                >
                    <Tooltip title="Ver video">
                        <IconButton
                            onClick={onOpenClose}
                            size="large"
                        >
                            <PlayCircleFilledOutlined />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            <BasicModal
                open={open}
                title={video?.name}
                onClose={onOpenClose}
                fullScreen={useMediaQuery((theme) => theme.breakpoints.down('md'))}
                maxWidth="md"
            >
                <Box padding={1}>
                    <YouTubeVideoPlayer
                        url={video?.url}
                    />
                </Box>
            </BasicModal>
        </React.Fragment>
    )
}
