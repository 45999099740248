import React from "react";
import { Menu } from "@mui/material";

export default function BasicMenu(props) {
    const { children, open, anchorEl, onClose, ...restProps } = props;

    return (
        <Menu
            {...restProps}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            {children}
        </Menu>
    )
}
