import React, { useState, useEffect, useCallback } from 'react';
import { CssTextField } from "../Controls/CustomTextField";
import { Autocomplete } from "@mui/material";
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Box
} from '@mui/material';
import { getDataChainDependency } from '../../apiCore/dataTable';
import { debounce } from "../../utils/debouce";
import '../../custom.css';
import { dataTypes } from "../../utils/constants";
import DateTimePickerSelector from "./DateTimePickerSelector";
import DateTimePicker from "./DateTimePicker";

export default function Field({ isFilter = false, enqueueSnackbar, model, column, onChange, valuesForm, formik, useDebounce = true, isForm = false }) {
    //Variables
    const [value, setValue] = useState(isFilter && column.type !== dataTypes.boolean ? [] : column.value);

    //Apply only for 
    const [options, setOpctions] = useState([]);

    const loadData = async (valueFilter) => {

        var arrayValues = [];
        if (Array.isArray(valueFilter)) {
            for (var i = 0; i < valueFilter.length; i++) {
                arrayValues.push(valueFilter[i].id);
            }
        } else {
            arrayValues.push(valueFilter.id);
        }

        var res = await getDataChainDependency(model, column.field, arrayValues);
        if (res.data.error) {
            enqueueSnackbar(res.data.error, { variant: "error" });
            setOpctions([]);
        } else {
            setOpctions(res.data);
        }
    }

    useEffect(function () {
        const init = (valueFilter) => {
            loadData(valueFilter);
        }

        // Setear el valor del filtro cuando el valor venga vacío y el tipo sea texto.
        if (!column?.value && column.type === dataTypes.text) setValue(column.value);

        // Setear el valor del filtro cuando el valor venga vacío y el tipo sea select.
        if (column.value?.length === 0 && column.type === dataTypes.select) setValue([]);

        // Setear el valor del filtro cuando el valor venga vacío y el tipo sea boolean.
        if (column.type === dataTypes.boolean) {
            setValue(isFilter && column.type !== dataTypes.boolean ? [] : column.value);
        }

        // Vacía el valor del filtro cuando venga vacio y el tipo sea float
        if (!column?.value && column.type === dataTypes.float) setValue(column.value);

        // Vacía el valor del filtro cuando venga vacio y el tipo sea date
        if (!column?.value && column.type === dataTypes.date) setValue(column.value);

        // Vacía el valor del filtro cuando venga vacio y el tipo sea int
        if (!column?.value && column.type === dataTypes.int) setValue(column.value);

        //Si existe una definición de dependencia de un campo siguiente
        if (column.config && column.config.chainDependency) {
            if (valuesForm[column.config.chainDependency] && valuesForm[column.config.chainDependency].value) {
                init(valuesForm[column.config.chainDependency].value);
            } else {
                setValue(null);
                setOpctions([]);
            }
        }
    }, [valuesForm]);

    // Manejar el cambio del filtro para hacer la solicitud al servidor.
    const handleFilterChange = useCallback(debounce(_value => onChange(_value), 300), []);

    const handleChangeFilterNumber = (e, columnType) => {
        if (formik?.handleChange instanceof Function) formik.handleChange(e);

        let regex = new RegExp();

        // Validar el tipo de dato de la columna para asignar una expresión regular de validación.
        if (columnType === dataTypes.float || columnType === dataTypes.number) {
            // Expresión regular para validar que se ingresen datos del tipo ###.####, ejemplo 789.654654
            // usando el punto(.) como separador decimal.
            regex = /^-?[0-9]+([.])?([0-9]+)?$/;
        } else if (columnType === "int") {
            // Expresión regular para validar que solo se ingresan valores númericos sin decimales.
            // Acepta valores negativos.
            regex = /^-?\d+$/;
        }

        let inputValue = e.target.value
        const arrayInputValue = inputValue.split("");

        // Obtener el último elemento del array
        const lastElement = arrayInputValue.slice(-1)

        // Validar si el último elemento ungresado no sea una coma.
        if (lastElement?.[0] === ",") return;

        // Eliminar los espacios en blanco extra y
        // remover comas como separador de miles.
        inputValue = inputValue.trim().replaceAll(",", "");

        if (arrayInputValue?.[0] === "-" && arrayInputValue.length === 1) {
            return setValue(inputValue);
        }

        if (inputValue === "" || regex?.test(inputValue)) {
            setValue(inputValue);
            if (useDebounce) {
                handleFilterChange(inputValue);
            } else {
                onChange(inputValue);
            }
            return;
        }
    }

    const hanleOnKeyDown = (e) => {
        // Prevenir la actualización del input de los campos numéricos al presionar la tecls "Space".
        if (e.key === " ") e.preventDefault();
    }

    if (isFilter && !column.filter.available) {
        return (<></>);
    } else {
        if (column.field !== "_id" && column.field !== "id" && column.field !== "created" && column.field !== "updated" && (!isForm || column.field !== "lastedUpdate")) {
            if (column.type === dataTypes.text) {
                return (
                    <Box className="boxField">
                        <CssTextField
                            value={value}
                            name={column?.field}
                            className='marginBottom'
                            label={column.headerName}
                            fullWidth
                            size="small"
                            onChange={(e) => {
                                if (formik?.handleChange instanceof Function) formik.handleChange(e)
                                setValue(e.target.value);
                                if (useDebounce) {
                                    handleFilterChange(e.target.value);
                                } else {
                                    onChange(e.target.value);
                                }

                            }}
                            error={formik?.touched?.[column?.field] && Boolean(formik?.errors?.[column?.field])}
                            helperText={formik?.touched?.[column?.field] && formik?.errors?.[column?.field]}
                        />
                    </Box>
                )
            } else if (column.type === dataTypes.float) {
                return (
                    <Box className="boxField">
                        <CssTextField
                            value={value}
                            name={column?.field}
                            className='marginBottom'
                            label={column.headerName}
                            fullWidth
                            size="small"
                            onChange={(e) => handleChangeFilterNumber(e, column.type)}
                            onKeyDown={hanleOnKeyDown}
                            error={formik?.touched?.[column?.field] && Boolean(formik?.errors?.[column?.field])}
                            helperText={formik?.touched?.[column?.field] && formik?.errors?.[column?.field]}
                        />
                    </Box>
                )
            } else if (column.type === dataTypes.int) {
                return (
                    <Box className="boxField">
                        <CssTextField
                            value={value}
                            name={column?.field}
                            className='marginBottom'                            
                            label={column.headerName}
                            fullWidth
                            size="small"
                            onChange={(e) => handleChangeFilterNumber(e, column.type)}
                            onKeyDown={hanleOnKeyDown}
                            error={formik?.touched?.[column?.field] && Boolean(formik?.errors?.[column?.field])}
                            helperText={formik?.touched?.[column?.field] && formik?.errors?.[column?.field]}
                        />
                    </Box>
                )
            } else if (column.type === dataTypes.number) {
                return (
                    <Box className="boxField">
                        <CssTextField
                            value={value}
                            name={column?.field}
                            className='marginBottom'
                            label={column.headerName}
                            fullWidth
                            size="small"
                            onChange={(e) => handleChangeFilterNumber(e, column.type)}
                            onKeyDown={hanleOnKeyDown}
                            error={formik?.touched?.[column?.field] && Boolean(formik?.errors?.[column?.field])}
                            helperText={formik?.touched?.[column?.field] && formik?.errors?.[column?.field]}
                        />
                    </Box>
                )
            } else if (column.type === dataTypes.boolean && column.field !== "enable") {
                return (
                    <Box className="checkboxField">
                        <FormGroup>
                            <FormControlLabel style={{ paddingLeft: "2px" }}
                                label={column.headerName}
                                name={column?.field}
                                className='spanInsideChack'
                                control={
                                    <Switch
                                        checked={value}
                                        value={value}
                                        className={value ? "Mui-checked" : ""}
                                        onChange={(event) => {
                                            if (formik?.setFieldValue instanceof Function) {
                                                formik.setFieldValue(column?.field, event.target.checked);
                                            }
                                            onChange(event.target.checked);
                                            setValue(event.target.checked);
                                        }}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                }
                            />
                        </FormGroup>
                    </Box>
                )

            } else if (column.type === dataTypes.select && column.config) {
                return (
                    <Box className="boxField">
                        <Autocomplete
                            disableClearable={true}
                            value={value}
                            onChange={(_, val) => {
                                onChange(val);
                                setValue(val);
                                if (formik?.setFieldValue instanceof Function) {
                                    formik.setFieldValue(column?.field, val?.id ?? null);
                                }
                            }}
                            multiple={isFilter}
                            options={column.config.chainDependency ? options : (column.options ? column.options : [])}
                            getOptionLabel={(option) => option?.name ?? option?.value}
                            variant="outlined"
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                                <CssTextField
                                    {...params}
                                    className="forceHeightInput marginBottom"
                                    label={column.headerName}                                    
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    error={formik?.touched?.[column?.field] && Boolean(formik?.errors?.[column?.field])}
                                    helperText={formik?.touched?.[column?.field] && formik?.errors?.[column?.field]}
                                />
                            )
                            }
                            renderOption={(props, option) => {
                                return <li {...props}>{`${option?.name ?? option?.value}`}</li>;
                            }}
                        />
                    </Box>
                )
            } else if (column.type === dataTypes.select) {
                return (
                    <Box className="boxField">
                        <Autocomplete
                            disableClearable={true}
                            value={value}
                            onChange={async (_, val) => {
                                onChange(val);
                                setValue(val);
                                if (formik?.setFieldValue instanceof Function) {
                                    formik.setFieldValue(column?.field, val?.id ?? null);
                                }
                            }}
                            multiple={isFilter}
                            options={column.options ? column.options : []}
                            getOptionLabel={(option) => option?.name ?? option?.value?.toString()}
                            variant="outlined"
                            sx={{ width: 300 }}
                            renderInput={(params) => {
                                const { InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;
                                return (
                                    <CssTextField
                                        {...restParams}
                                        className="forceHeightInput marginBottom"
                                        label={column.headerName}
                                        placeholder={isFilter ? "Filtro " + column.headerName : column.headerName}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        InputProps={
                                            {
                                                ...restInputProps,
                                                startAdornment: (
                                                    <div
                                                        className="textfieldStartAdornmentContainer"
                                                    >
                                                        {startAdornment}
                                                    </div>)
                                            }
                                        }
                                        error={formik?.touched?.[column?.field] && Boolean(formik?.errors?.[column?.field])}
                                        helperText={formik?.touched?.[column?.field] && formik?.errors?.[column?.field]}
                                    />
                                )
                            }}
                            renderOption={(props, option) => {
                                return <li {...props}>{`${option?.name ?? option?.value}`}</li>;
                            }}

                        />
                    </Box>
                )
            }
            else if (column.type === dataTypes.date && !isForm) {
                // Para el selector de filto
                return (
                    <Box className="boxField">
                        <DateTimePickerSelector
                            onFilterChange={onChange}
                            value={column?.value}
                            column={column}
                            isFilter={isFilter}
                        />
                    </Box>
                );
            }
            else if (column.type === dataTypes.date && isForm) {
                // Para el formulario de registro
                return (
                    <Box className="boxField">
                        <DateTimePicker
                            onFilterChange={onChange}
                            column={column}
                            isFilter={isFilter}
                            formik={formik}
                        />
                    </Box>
                );
            }
            else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }
}