import React from "react";
import { SnackbarProvider } from "notistack";
import { Button } from '@mui/material';

export default function NotificationBar({ children }) {
    const notistackRef = React.createRef();

    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }

    return (
        <SnackbarProvider
            autoHideDuration={6000}
            ref={notistackRef}
            maxSnack={4}
            hideIconVariant={false}
            action={(key) => (
                <Button
                    className="whiteLetter"
                    onClick={onClickDismiss(key)}
                >
                    Cerrar
                </Button>
            )}>
            {children}
        </SnackbarProvider>
    )
}