import React from "react";
import Loader from './components/Controls/Loader';
import "./custom.css";
import "./css/mui.clases.css";
import "./assets/loader.css";
import AxiosInterceptor from "./components/AxiosInterceptor/AxiosInterceptor";
import AppRoutes from "./routes/AppRoutes/AppRoutes";
import ApiHealthCheck from "./components/HealthCheck/ApiHealthCheck";

export default function App() {
  return (
    <>
      <AxiosInterceptor />
      {
      //<ApiHealthCheck />
      }
      <AppRoutes />
      <Loader show={false} />
    </>
  );
}
