import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";

import TableFilter from "../TableFilter/TableFilter";
import TableContent from "../TableContent/TableContent";
import HaederIcon from "../HeaderIcon/HeaderIcon";
import "./Table.css";

const createHeaders = (columns = []) => {
    return columns.map((column) => ({
        ...column, ref: useRef(), iconDown: true,
    }))
}

// Se puede reducir el tamaño de la columna, hasta máximo 100 px.
const minWidthForResize = 100;

// export default function Table(props) {
export default function Table({
    columns,
    model,
    filtersValues,
    setFiltersValues,
    rows,
    typeDatatable,
    setIdRegistro,
    setOpenDelete,
    handleOrderValueChange,
    onFilterValuesChange,
    ActionsButtons,
    loadData,
    modelIsView,
    permissions,
}) {

    const [activeIndex, setActiveIndex] = useState(null);
    const [headers, setHeaders] = useState(createHeaders(columns))

    const refTableContainer = useRef(null);

    const gridTemplateColumns = useMemo(() => {
        return columns.map((column, index) => {
            if (index === 0) {
                return ("auto");
            } else {
                return (`${column.width}px`)
            }
        });
    }, []);

    useEffect(() => {
        refTableContainer.current.style.gridTemplateColumns = `${gridTemplateColumns.join(" ")}`;
    }, []);

    const onMouseDown = (index) => {
        setActiveIndex(index);
    }

    const mouseMove = useCallback((e) => {

        if (activeIndex === 0) return;
        const { left } = headers[activeIndex].ref.current.getBoundingClientRect()
        const width = e.clientX - left;

        if (width >= minWidthForResize) {
            gridTemplateColumns[activeIndex] = `${width}px`;
        }

        refTableContainer.current.style.gridTemplateColumns = `${gridTemplateColumns.join(" ")}`;

    }, [activeIndex, headers])

    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove);
        window.removeEventListener("mouseup", removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
        setActiveIndex(null);
        removeListeners();
    }, [setActiveIndex, removeListeners]);


    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener("mousemove", mouseMove);
            window.addEventListener("mouseup", mouseUp);
        }

        return () => {
            removeListeners();
        };
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);

    return (
        <Grid
            ref={refTableContainer}
            container
            className="tableContainer horizontalScroll"
        >
            <TableFilter
                columns={columns}
                model={model}
                filtersValues={filtersValues}
                setFiltersValues={setFiltersValues}
                onFilterValuesChange={onFilterValuesChange}
            />

            {/*Rendeziar las nombres de las columnas. */}
            <Grid item xs={12} className="headerTable displayContents" >
                <div className="displayContents" >
                    <div
                        key={1999}
                        className="cellTableFirstCell cellTableFirstTopCell cellTableFirstTopLeftCell tableFirstColum">
                        Opciones
                    </div>
                    {headers.map((column, index) => {
                        if (column.field != "id" && column.field != "_id" && !column.config?.hiddenToSee) {
                            return (
                                <div
                                    ref={column.ref}
                                    key={index + 2000}
                                    className="cellTable cellTableFirstTopCell resizable headerTableContent">
                                    <div className="headerText">
                                        {column.headerName}
                                    </div>
                                    <div className="headerIcon">
                                        <HaederIcon
                                            key={index + 5000}
                                            handleOrderValueChange={handleOrderValueChange}
                                            columnField={column.field}
                                            setHeaders={setHeaders}
                                            iconDown={column.iconDown}/>
                                    </div>

                                    <div
                                        onMouseDown={() => onMouseDown(index)}
                                        className="resize"/>
                                </div>
                            )
                        }
                    })}
                </div>
            </Grid>

            <TableContent
                columns={columns}
                rows={rows}
                typeDatatable={typeDatatable}
                setIdRegistro={setIdRegistro}
                setOpenDelete={setOpenDelete}
                model={model}
                ActionsButtons={ActionsButtons}
                loadData={loadData}
                modelIsView={modelIsView}
                permissions={permissions}
            />
        </Grid>
    )
}
