import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import DownloadButton from "./DownloadButton";

export default function Card({ report }) {
    return (
        <Box
            component={Paper}
            elevation={3}
            className="reportsCard"
        >
            <Box
                padding={1}
            >
                <Typography variant="h6">
                    {report?.name}
                </Typography>
                <Typography variant="body2">
                    {report?.description}
                </Typography>
            </Box>

            <Box
                className="reportsDownloadBtnContainer"
            >
                <DownloadButton
                    apiRoute={report?.apiRoute}
                    idReport={report?.id}
                    reportName={report?.name}
                />
            </Box>
        </Box>
    )
}
