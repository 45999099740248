import React, { useState, useEffect, useImperativeHandle } from "react";
import { Box, TextField, Autocomplete, Chip } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import api from "../../axios/axios";
import { handleResponse } from "../../apiCore/handleResponse";
import md5 from "md5";

const SelectAutocomplete = React.forwardRef(function SelectAutocomplete(props, ref) {
    const {
        label,
        model,
        column,
        onChange,
        sx,
        reload,
        value: _value,
        textfieldError,
        textfieldHelpertext,
        name,
        getDefaultValue,
        size,
        variant,
        disabled,
        orderConfig,
        apiRoute,
        isMatch,
        CustomTextField = TextField,
        conditions = {} } = props;

    const [error, setError] = useState(null);
    const [value, setvalue] = useState(_value ? _value : "");
    const [inputValue, setinputValue] = useState("");

    const [data, setData] = useState([]);

    const [lengthData, setLengthData] = useState(0);

    const queryData = async () => {
        //try {

            const url = apiRoute ?? "api/autocomplete/loadFullData";
            const hash = md5("autocompleteLoadFull"+model+column+JSON.stringify(conditions));
            var res = [];
            try{
                res = await api.post(url, { model, column, conditions });
                localStorage.setItem(hash, JSON.stringify(res));
            }catch(err){
                let responseHash = localStorage.getItem(hash);
                if(responseHash){
                    res = JSON.parse(responseHash);
                }
            }

            var result = handleResponse(res);

            if (result?.data?.error) {
                setError(result.data.error);
            } else {
                setError(null);
                const options = result?.data?.data || [];

                // Buscar si existe un valor por default.
                const defualtValue = options.find(option => option?.isDefault);

                // Asignar el valor por defaulr si la propiedad _value no existe
                if (defualtValue && !_value) {
                    setvalue(defualtValue);
                    if (getDefaultValue instanceof Function) getDefaultValue(defualtValue);
                };

                // Validar si _value es un id
                if (_value && typeof value === "number") {
                    // Buscar el id dentro de las opciones de lista disponibles.
                    const valueMatch = options.find(option => option?.id === _value);
                    // Si se encuentra una coincidencia, se asigna al valor pordefecto.
                    if (valueMatch) {
                        setvalue(valueMatch);
                        if (getDefaultValue instanceof Function) getDefaultValue(valueMatch);
                        if (isMatch instanceof Function) isMatch(true);
                    } else {
                        setvalue("");
                        if (isMatch instanceof Function) isMatch(false);
                    }
                }

                let orderOptions = null;

                // Validar si se ha pasado un objeto de configuación para ordenar los datos.
                // El objecto orderConfig debe de tener el nombre de la columna (columnName) que contenga el modelo
                // sobre la cual se va a ordenar los datos, y se debe de pasar la dirección de ordenamiento en el campos order,
                // las cuales pueden ser "ASC" o "DESC".
                // orderConfig={columnName: string, order: string}, el campo order debe ser 'ASC' o 'DESC'.
                if (orderConfig) {
                    const { columnName, order } = orderConfig || {};
                    if (columnName && order) {
                        orderOptions = options.sort((a, b) => {
                            if (order === "ASC") {
                                return a?.[columnName] - b?.[columnName];
                            } else {
                                return b?.[columnName] - a?.[columnName];
                            }
                        });
                    }
                }

                // Si se paso la configuración para ordenar los datos, se agregán los datos ordenados,
                // de los contrario se asignan los valores que retorno la consulta.
                setData(orderOptions ?? options);
                setLengthData(orderOptions?.length ?? options?.length);
            }

        /*
        } catch (error) {
            if (error?.response?.data?.error) {
                setError(error.response.data.error);
            }
        }
        */

    }

    // Exponer la función para actualizar el valor de estado desde fuera del componente
    useImperativeHandle(ref, () => {
        return {
            updateValue(newValue) {
                setvalue(newValue);
            }
        };
    });

    useEffect(() => {
        async function init() {
            await queryData();
        }
        init();
    }, [reload]);

    return (
        <React.Fragment>
            {error &&
                <Box p={1}>
                    <Chip label="Error en la generación del selector" color="error" />
                </Box>
            }
            {!error &&
                <Box
                    sx={sx}
                >
                    <Autocomplete
                        disabled={disabled}
                        size={size}
                        isOptionEqualToValue={(option, value) => option.id === value.id || value === ""}
                        value={value}
                        disablePortal
                        options={data}
                        fullWidth
                        inputValue={inputValue}
                        getOptionLabel={(option) => option[column]?.toString() || ""}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={uuidv4()}>
                                    {option[column]}
                                </li>
                            )
                        }}
                        renderInput={(params) => {
                            return (
                                <CustomTextField
                                    variant={variant}
                                    {...params}
                                    label={label + " (" + lengthData + ")"}
                                    error={textfieldError}
                                    helperText={textfieldHelpertext}
                                />
                            );
                        }
                        }
                        onInputChange={(_, newInputValue) => {
                            setinputValue(newInputValue);
                        }}
                        onChange={(obj, val) => {
                            setvalue(val);
                            if (val) {
                                onChange(val.id, val.name, obj, val);
                            } else {
                                onChange(null);
                            }
                        }}
                    />
                </Box>
            }
        </React.Fragment>
    )
})

export default SelectAutocomplete;