import React from "react";
import { useSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { FileDownload, PictureAsPdf } from "@mui/icons-material";

import { quoteStatus } from "../../../../utils/constants";
import { downloadPDFOfQuote, getPDFOfQuote } from "../../../../apiCore/dwonload";

export default function ViewOrDownloadPDFButton({ id, row, onReload }) {
    const { enqueueSnackbar } = useSnackbar();

    const { pdfName, quoteStatusId } = row || {};

    const downloadPdf = async ({ showInNewPage = false }) => {
        try {
            if (id) {
                await downloadPDFOfQuote({ quoteId: id, showInNewPage });
                if (onReload instanceof Function) onReload();
            }
        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al consultar el PDF solicitado", { variant: "error" });
            }
        }
    }
    const getPdf = async () => {
        try {
            if (id) {

                await getPDFOfQuote({ quoteId: id });
            }
        } catch (err) {
            const { error, notFoundPdf } = err?.response?.data;

            // Si no existe un pdf de la cotización, entonces intenta descargarlo.
            if (notFoundPdf) {
                await downloadPdf({ showInNewPage: true });
                return;
            }
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al consultar el PDF solicitado", { variant: "error" });
            }
        }
    }

    let content = null;

    if (pdfName) {
        content = <Tooltip title="Ver PDF">
            <span>
                <IconButton
                    aria-label="view-pdf"
                    color='inherit'
                    size='small'
                    onClick={getPdf}
                    disabled={!pdfName}
                >
                    <PictureAsPdf />
                </IconButton>
            </span>
        </Tooltip>
    } else {
        content = <Tooltip title="Descargar">
            <span>
                <IconButton
                    aria-label="dwonload-pdf"
                    color='inherit'
                    size='small'
                    onClick={downloadPdf}
                    disabled={!(quoteStatusId === quoteStatus.aprobada || quoteStatusId === quoteStatus.lograda)}
                >
                    <FileDownload />
                </IconButton>
            </span>
        </Tooltip>
    }


    return content;
}
