import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSnackbar } from "notistack";

import ApprovalRequestItem from "./ApprovalRequestItem";
import { getApprovalRequests } from "../../../apiCore/approvalRequest";

export default function ApprovalRequestList({ quoteId, isRTCJunior, onClose, onReloadDatatable }) {
    const [approvalRequestList, setApprovalRequestList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [reload, setReload] = useState(false);

    const onReload = () => setReload(prevReload => !prevReload);

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        (async () => {
            try {

                const response = await getApprovalRequests({ quoteId });

                const { error, data } = response?.data || {};

                if (error) throw { error: response };

                if (data) setApprovalRequestList(data);
                setIsLoading(false);
            } catch (err) {
                const { error } = err?.response?.data || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al cargar los datos de la cotización", { variant: "error" });
                }
                setIsLoading(false);
                setIsError(true);
            }

        })();
    }, [reload]);

    if (isLoading) return <Box className="displayFlex flexJustifyContentCenter fullWidth">Cargando...</Box>

    if (isError) return <Box className="displayFlex flexJustifyContentCenter fullWidth error">Error al consultar la información.</Box>

    return approvalRequestList.map((approvalRequest, index, array) => (
        <Grid
            key={approvalRequest?.id ?? index}
            container
            item
            xs={12}
            sx={{
                paddingY: 1,
                borderBottom: `${(array.length - 1) !== index ? "1px solid #e1e1e1" : ""}`,
                "&.MuiGrid-container > .MuiGrid-item": {
                    sm: {
                        paddingLeft: 1 / 2,
                    }
                },
                ":hover": {
                    bgcolor: (theme) => theme.palette.action.hover
                },
            }}
        >
            <ApprovalRequestItem
                approvalRequest={approvalRequest}
                onReload={onReload}
                isRTCJunior={isRTCJunior}
                onClose={onClose}
                onReloadDatatable={onReloadDatatable}
            />
        </Grid >
    ));
}
