import React from "react";
import { Box, Typography } from "@mui/material";

export default function NotAuthorized401() {
    return (
        <Box
            className="displayFlex flexJustifyContentCenter flexDirectionColumn flexAlignItemsCenter height100 fullWidth whiteLetter"
        >
            <Typography align="center" variant="h1" >
                401
            </Typography>
            <Typography align="center" variant="h2" >
                Autorización requerida
            </Typography>
        </Box>
    )
}
