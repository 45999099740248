import api from "../axios/axios";

/**
 * Consulta la lista de reportes disponibles para consulta.
 * @returns {Object} - resultado de la consulta.
 */
export const getAvailableReports = async function () {
    try {
        const response = await api.get("/api/reports/reportsAvailable");
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

/**
 * Permite consultar la información en formato de Excel de un reporte especifico.
 * Si no se pasa parámetro apiRoute, se intentará realizar la consulta al endpoint por defecto "api/reports/download".
 * @param {Object} Object - Objecto de configuración.
 * @param {Number} Object.idReport - Id del reporte que se va a consultar.
 * @param {String} Object.apiRoute - ruta de la api de donde se va a consultar la información.
 * @param {Number} Object.year - filtro de año para el reporte.
 * @param {Number} Object.month - filtro de mes para el reporte.
 * @returns {Object} - resultado de la consulta.
 */
export const downloadReport = async function ({ idReport, apiRoute, year, month }) {
    try {
        const url = apiRoute ? apiRoute : "api/reports/download";
        const response = await api.post(url,
            { idReport, year, month },
            { responseType: "blob" });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}