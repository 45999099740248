import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Grade } from "@mui/icons-material";
import { ENV, quoteStatus } from "../../../../utils/constants";
import { brandColors } from "../../../../utils/colors";

import ConfirmModal from "../../../Controls/ConfirmModal";
import { updateRegister } from "../../../../apiCore/entity";
import { hideLoader, showLoader } from "../../../Controls/Loader";

/**
 * Botón para actualizar el estado de una cotización a lograda.
 * @returns 
 */
export default function QuoteAchievedButton({ quoteId, onReload, row }) {

    const { quoteStatusId } = row || {};
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    // Actualiza una cotización al estado de lograda.
    const updateQuoteState = async () => {
        try {
            showLoader();
            const response = await updateRegister({
                model: ENV.CLIENT_ROUTES.QUOTES.MODEL,
                id: quoteId,
                data: {
                    "quoteStatusId": quoteStatus.lograda,
                }
            });

            const { error, message } = response?.data || {};

            if (error) throw { error };

            if (message) { enqueueSnackbar(message, { variant: "success" }); }

            if (onReload instanceof Function) onReload();

            setOpenConfirmModal(false);

        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al actualizar el estado de la cotización", { variant: "error" });
            }
        } finally {
            hideLoader();
        }
    }

    const iconStyle = () => {
        if (quoteStatusId === quoteStatus.lograda) return { fill: brandColors.green };
        return {};
    }

    const handleOpenConfirmModal = () => {
        if (quoteStatusId === quoteStatus.lograda) return;
        setOpenConfirmModal(true)
    }

    return (
        <React.Fragment>
            <Tooltip title="Cotización lograda">
                <IconButton
                    aria-label="copy quote"
                    color='inherit'
                    size='small'
                    onClick={handleOpenConfirmModal}
                >
                    <Grade style={iconStyle()} />
                </IconButton>
            </Tooltip>

            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                titleMessage="Actualización de estado"
                confirmMessage={`¿Desea actualizar el estado de la cotización ${quoteId} a "Lograda"?`}
                confirmHandler={updateQuoteState}
            />
        </React.Fragment>
    );
}
