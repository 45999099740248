import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    Typography,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Box
} from '@mui/material';
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { showLoader, hideLoader } from '../Controls/Loader';
import { useSnackbar } from "notistack";
import { storeForm } from '../../apiCore/dataTable';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Field from './Field';
import { dataTypes } from "../../utils/constants";
import { getYupSchemaFromMetaData } from "../../utils/yupSchemaCreator";
import { useFormik } from "formik";
import { Transition } from "../common/Transition/Transition";
import UploadComponent from './UploadComponent';

export default function Form({ open, setOpenAddItem, close, model, columns, onReload, defaultVals = {} }) {
    const { enqueueSnackbar } = useSnackbar();

    // Generar schema de validación dinámico, según las columnas del formulario.
    const dinamicSchema = getYupSchemaFromMetaData(columns, [], []);
    const [columnasForm, setColumnasForm] = useState({});
    const [valuesForm, setValuesForm] = useState({});

    const formik = useFormik({
        initialValues: {},
        validationSchema: dinamicSchema,
        onSubmit: async (_) => {
            // Solo se usa formik para realizar validaciones sobre los campos.
            // Los datos que se envian para el registro de hacen a través de la función storeFullFormData(). 
            await storeFullFormData();

        }
    })

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const storeFullFormData = async () => {
        if (model && valuesForm) {
            //Se consulta la estructura del modelo que se desea analizar                
            //Validar valores digitados
            var campoNoValido = ""
            var arrayKeys = Object.keys(columnasForm);

            var arrayFinalFields = [];
            var validForm = true;
            let fieldName = "";

            // Campos que no se requiere incluir para realizar el registro.
            const excludeFields = ["_id", "id", "created", "empresa", "user", "secuencia"];

            for (var i = 0; i < arrayKeys.length; i++) {
                const key = arrayKeys[i]; // Nombre del campo

                const searchField = excludeFields.find(field => field === key);

                if (!searchField) {
                    const dataType = columnasForm[key].type; // Tipo de dato

                    if (dataType === dataTypes.text || dataType === dataTypes.float) {
                        arrayFinalFields.push({ key, value: valuesForm[key].value, type: dataType });

                    } else if (dataType === dataTypes.select) {
                        if (valuesForm[key].value != null) {
                            arrayFinalFields.push({ key, value: valuesForm[key].value.id, type: dataType });
                        }
                    } else if (dataType === dataTypes.boolean) {
                        arrayFinalFields.push({ key, value: valuesForm[key].value, type: dataType });

                    } else if (dataType === dataTypes.date) {
                        const value = valuesForm[key]?.value;
                        if (value !== null && value !== "") {
                            arrayFinalFields.push({ key, value: valuesForm[key].value, type: dataType });
                        }
                    } else {
                        arrayFinalFields.push({ key, value: valuesForm[key].value, type: dataType });
                    }
                }
            }

            for (var i = 0; i < arrayKeys.length; i++) {
                var fieldValidation = columnasForm[arrayKeys[i]];
                if (fieldValidation.key != "id" && fieldValidation.key != "created" && fieldValidation.key != "updated") {
                    if (fieldValidation.required === true && (valuesForm[arrayKeys[i]].value === "" || valuesForm[arrayKeys[i]].value === null)) {
                        fieldName = fieldValidation.name ? fieldValidation.name : fieldValidation.headerName;
                        campoNoValido = "(" + fieldName + ") del tipo (" + fieldValidation.type + ")";
                        enqueueSnackbar("El campo " + campoNoValido + " es requerido o cuenta con un valor no valido.", { variant: "error" });
                        validForm = false;
                        break;
                    }
                }
            }

            //Si no se encuentran errores en la validación, se procede al almacenamiento de los datos registrados
            if (validForm) {
                showLoader();
                const res = await storeForm(model, arrayFinalFields);
                if (res.data.error) {
                    enqueueSnackbar(res.data.error, { variant: "error" });
                } else {
                    enqueueSnackbar(res.data.message, { variant: "success" });
                    if (onReload instanceof Function) onReload();
                    close(true);
                }

                hideLoader();
            }
        } else {
            enqueueSnackbar("Configuración no valida", { variant: "error" });
        }
    }

    useEffect(() => {
        async function init() {
            var tempValuesArray = {};
            var valuesArray = {};
            for (var i = 0; i < columns?.length; i++) {
                //Se inicializan los valores del formulario en función de su tipo de dato
                var initValue = "";
                if (columns[i].type === dataTypes.boolean) {
                    initValue = false;
                } else if (columns[i].type === dataTypes.select) {
                    initValue = null;
                } else if (columns[i].type === dataTypes.array) {
                    initValue = [];
                }

                if (defaultVals[columns[i].field]) {
                    initValue = defaultVals[columns[i].field];
                }

                if (columns[i].field !== "_id" &&
                    columns[i].field !== "id" &&
                    columns[i].field !== "created" &&
                    columns[i].field !== "empresa" &&
                    columns[i].field !== "user" &&
                    columns[i].field !== "secuencia" &&
                    columns[i].field !== "deleted"
                ) {

                    tempValuesArray[columns[i].object.COLUMN_NAME] = {
                        ...columns[i],
                        value: initValue,
                        key: columns[i].object.COLUMN_NAME,
                        required: columns[i].object.IS_NULLABLE === "NO" ? true : false,
                    };

                    valuesArray[columns[i].object.COLUMN_NAME] = {
                        value: initValue
                    };
                }
            }

            //setValuesForm(valuesArray);
            setColumnasForm(tempValuesArray);
            setValuesForm(tempValuesArray);
        }

        init();
    }, [open]);

    useEffect(() => {        
        if (formik.submitCount > 0) {
            columns.map(column => {
                formik.setFieldTouched(column?.field, true);
            });
        }
    }, [formik.submitCount]);

    const onOpenCloseForm = async () => {
        setOpenAddItem(prevState => !prevState);
        resetTouchedFieldsAndForm();
    };

    const resetTouchedFieldsAndForm = () => {
        if (formik.submitCount > 0) {
            columns.map((column) => {
                // Quitar el touch de los campos.
                formik.setFieldTouched(column?.field, false);
            });
        }
        // Limpiar formulario.
        formik.resetForm();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onOpenCloseForm}
                fullScreen={fullScreen}
                maxWidth="sm"
                TransitionComponent={Transition}>
                <AppBar color="inherit">
                    <Toolbar>
                        <IconButton
                            className="whiteLetter"
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                close(false);
                                resetTouchedFieldsAndForm();
                            }}
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            sx={{
                                marginLeft: 2,
                                flex: 1,
                            }}>
                            Registro de datos
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box className='backgroundPlain'>
                    <Box
                        onSubmit={formik.handleSubmit}
                        component="form" className="panel">
                        <Box p={1}>
                            {
                                Object.keys(columnasForm).map((field, index) => {
                                    var column = columnasForm[field];

                                    if(field.indexOf("urlFile") != -1){
                                        return (
                                            <>
                                                <UploadComponent
                                                    formik={formik}
                                                    column={column}
                                                    key={index + 9000}
                                                    field={column.field}
                                                    onEndUpload={(value)=>{

                                                        var formTemporal = { ...valuesForm };
                                                        var tempObj = formTemporal[column.field];
                                                        tempObj.value = value;
                                                        formTemporal[column.field] = tempObj;
                                                        setValuesForm(formTemporal);
                                                    }}
                                                />
                                            </>
                                            
                                        )
                                    }else{
                                        return (
                                            <Field
                                                enqueueSnackbar={enqueueSnackbar}
                                                valuesForm={valuesForm}
                                                model={model}
                                                key={index + 9000}
                                                column={column}
                                                onChange={(value) => {
                                                    var formTemporal = { ...valuesForm };
                                                    var tempObj = formTemporal[column.field];
                                                    tempObj.value = value;
                                                    formTemporal[column.field] = tempObj;
                                                    setValuesForm(formTemporal);
                                                }}
                                                useDebounce={false}
                                                formik={formik}
                                                isForm={true}>
                                            </Field>
                                        )
                                    }
                                })}
                        </Box>
                        <Box p={1}>
                            <Button
                                variant="contained"
                                fullWidth
                                endIcon={<SaveIcon />}
                                type="submit">
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}