import React, { useState } from "react";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";
import BasicModal from "../common/BasicModal/BasicModal";
import DownloadForm from "./DownloadForm";

export default function DownloadButton({ apiRoute, idReport, reportName }) {
    const [open, setOpen] = useState(false);

    const onOpenCloseModal = () => setOpen(prevState => !prevState);

    return (
        <React.Fragment>
            <Button
                fullWidth
                className='botonDescargaPlantilla whiteLetter'
                variant="contained"
                endIcon={<Download />}
                onClick={onOpenCloseModal}
            >
                Descargar
            </Button>

            <BasicModal
                open={open}
                onClose={onOpenCloseModal}
                title="Filtro de descarga"
            >
                <DownloadForm
                    apiRoute={apiRoute}
                    idReport={idReport}
                    reportName={reportName}
                />
            </BasicModal>

        </React.Fragment>
    )
}
