import api from "../axios/axios";

// Obtener la lista de solicitudes de aprobación por cotización
export const getApprovalRequests = async function ({ quoteId }) {
    try {
        const response = await api.post("api/approvalRequest/get", { quoteId });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Aprovar solicitud de cotización.
export const approvalQuote = async function ({ approvalRequestId, quoteId }) {
    try {
        const response = await api.post("api/approvalRequest/approval", { approvalRequestId, quoteId });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Rechazar solicitud de aprobación de cotización.
export const rejectQuote = async function ({ approvalRequestId, quoteId, comment }) {
    try {
        const response = await api.post("api/approvalRequest/reject", { approvalRequestId, quoteId, comment });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

