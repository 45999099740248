import React from "react";
import { Grid } from "@mui/material";
import TableContentRow from "./TableContentRow";


export default function TableContent(props) {
    const {
        columns,
        rows,
        typeDatatable,
        setIdRegistro,
        setOpenDelete,
        model,
        ActionsButtons,
        loadData,
        modelIsView,
        permissions,
    } = props;
    return (
        <Grid item xs={12} className="contentTable displayContents">
            {rows.length == 0 &&
                <div
                    className="relativePosition">
                    <div className="centerContent fixedPosition displayFlex width100vw flexJustifyContentCenter flexAlignItemsCenter" style={{fontSize:"30px", color:"#888"}}>
                        No se encontrarón registros :(
                    </div>
                </div>
            }
            {rows.length > 0 &&
                rows.map((row, index) =>
                    <TableContentRow
                        key={row?.id ?? index}
                        row={row}
                        setIdRegistro={setIdRegistro}
                        setOpenDelete={setOpenDelete}
                        model={model}
                        typeDatatable={typeDatatable}
                        columns={columns}
                        ActionsButtons={ActionsButtons}
                        loadData={loadData}
                        modelIsView={modelIsView}
                        permissions={permissions}
                    />
                )
            }
        </Grid>
    )
}
