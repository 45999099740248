import React from "react";
import { Divider, Drawer as MuiDrawer, IconButton, Box, useTheme, styled } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import { Icon } from "../../../assets";
import ListSection from "./ListSection";
import { useMenuNavegacionContext } from "../../../hooks/useMenuNavegacionContext";

export const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export default function Drawer({ open, handleClose, anchor, modalProps, drawerHeaderProps }) {

    const { menuOptions, updateState } = useMenuNavegacionContext();

    const theme = useTheme();

    return (
        <MuiDrawer
            open={open}
            anchor={anchor}
            ModalProps={modalProps}
            onClose={handleClose}
        >
            <DrawerHeader>
                {drawerHeaderProps.title}
                <IconButton
                    onClick={handleClose}
                    className="whiteLetter"
                    sx={{
                        "&.MuiIconButton-root:hover": {
                            bgcolor: "rgba(255, 255, 255, 0.08)",
                        }
                    }}
                >
                    {theme.direction === "ltr" ? (<ChevronLeft />) : (<ChevronRight />)}
                </IconButton>
            </DrawerHeader>
            <Divider />

            {/* Opciones del menú */}

            <Box className="menuHeight">
                <ListSection
                    handleClose={handleClose}
                    listMenu={menuOptions}
                    level={0}
                    updateState={updateState}
                />
            </Box>

            {/* Logo */}
            <Box p={1} className="bottonLogoMenu">
                <Icon.LogoWhiteCiamsa className="logoCiamsa" />
            </Box>
        </MuiDrawer>
    )
}


