import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Button
} from "@mui/material";

import { Transition } from "../common/Transition/Transition";

export default function ConfirmModal({ open, setOpen, titleMessage, confirmMessage, confirmHandler, dialogProps, confirmButtonText = "Confirmar" }) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { setOpen(false) }}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                className: "whiteLetter backgroundDarkSecondary",
            }}
            {...dialogProps}
        >
            <DialogTitle>{titleMessage}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    className="confirmModalContentText"
                    id="alert-dialog-slide-description">
                    {confirmMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    "&.MuiDialogActions-root>:not(:first-of-type)": {
                        marginLeft: 0,
                    }
                }}>
                <Button
                    color="secondary"
                    className="confirmModalCancelBtn"
                    onClick={() => {
                        setOpen(false)
                    }}>
                    Cancelar
                </Button>
                <Button
                    color="primary"
                    className="confirmModalConfirmBtn"
                    autoFocus={true}
                    onClick={confirmHandler}>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}