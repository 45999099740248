import React, { useMemo } from "react";
import { useMainContext } from "../../../../hooks/useMainContext";
import { quoteStatus } from "../../../../utils/constants";
import { ButtonGroup } from "@mui/material";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import ViewOrDownloadPDFButton from "./ViewOrDownloadPDFButton";
import ApprovalRequestButton from "./ApprovalRequestButton";
import CopyButton from "./CopyButton";
import QuoteAchievedButton from "./QuoteAchievedButton";

/**
 * Permite renderizan un botón dentro de la columna de opciones del componente Datatabel.
 * Recibe los parametros del id del registro de la columna.
 * La función onReload que se encarga de recargar los datos de la tabla.
 * El objeto row que contienen la información con los datos de la fila. 
 * @param {Object} Object - Objeto de configuración.
 * @param {Number} Object.id - Id de una cotización.
 * @param {Function} Object.onReload - Función para recargar lista de cotizaciones.
 * @param {Object} Object.row - Objetos con datos de la cotización.
 */
export default function ActionButtons({ id, onReload, row }) {
    const { numberApprovalRequest, quoteStatusId, userId: userQuoteId } = row || {};
    const { user } = useMainContext() || {};
    const { id: userId } = user || {};

    const isOwner = useMemo(() => userQuoteId === userId, [row, user])

    // El botón de eliminar solo esta habilitado para cotizaciones con un estado de pendiente o rechazada.
    const enableDeleteButton = useMemo(() => (quoteStatusId === quoteStatus.enElaboracion) || (quoteStatusId === quoteStatus.rechazada), [row]);

    return (
        <ButtonGroup>
            <DeleteButton
                id={id}
                onReload={onReload}
                disabled={!enableDeleteButton || !isOwner}
            />

            <EditButton
                id={id}
                onReload={onReload}
                isOwner={isOwner}
                row={row}
            />

            <ViewOrDownloadPDFButton
                id={id}
                row={row}
                onReload={onReload}
            />

            {isOwner && (
                <CopyButton
                    quoteId={id}
                    onReload={onReload}
                    isOwner={isOwner}
                />
            )}

            {(isOwner && (quoteStatusId === quoteStatus.aprobada || quoteStatusId === quoteStatus.lograda)) && (
                <QuoteAchievedButton
                    quoteId={id}
                    onReload={onReload}
                    row={row}
                />
            )}

            {(numberApprovalRequest !== 0) && (
                <ApprovalRequestButton
                    id={id}
                    row={row}
                    onReload={onReload}
                />
            )}
        </ButtonGroup>
    );
}
