import React, { useEffect } from "react";
import { setupAxiosInterceptor } from "../../axios/axios";
import { useMainContext } from "../../hooks/useMainContext";


export default function AxiosInterceptor() {
    const { setUser } = useMainContext();

    const updateUser = () => {
        setUser(null);
    }
    useEffect(() => {
        setupAxiosInterceptor(updateUser);
    }, []);

    return null;
}
