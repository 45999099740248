import React, { useState } from "react";
import SelectAutocomplete from "../../Controls/SelectAutocomplete";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Stack, TextField } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Add } from "@mui/icons-material";
import NewRegisterButton from "../../common/NewRegisterButton/NewRegisterButton";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./QuoteForm.form";
import { useMainContext } from "../../../hooks/useMainContext";
import { ENV } from "../../../utils/constants";
import { hideLoader, showLoader } from "../../Controls/Loader";
import { useSnackbar } from "notistack";
import { createNewRegister, updateRegister } from "../../../apiCore/entity";


export const CustomStack = ({ children, direction, spacing, sx, ...props }) => {
    return (
        <Stack
            className="displayFlex flexJustifyContentSpaceBetween"
            rowGap={1}
            direction={{
                xs: "column",
                sm: "row",
                ...direction,
            }}
            spacing={{
                xs: 1,
                sm: 5,
                ...spacing
            }}
            sx={{
                mb: 2,
                ...sx,
            }}
            {...props}
        >
            {children}
        </Stack >
    );
}

export default function QuoterForm({ quote, setQuote, nextStep, setReloading, availableEdit }) {

    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const [reload, setReload] = useState(false);

    const { user } = useMainContext();
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: initialValues({ quote, userId: user?.id }),
        validationSchema: validationSchema(),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values) => {

            const newQuote = {
                deliveryTime: values?.deliveryTime,
                deliveryLocation: values?.deliveryLocation,
                date: values?.date,
                validityId: values?.validityId,
                approvalRequired: values?.approvalRequired,
                sendToCustomer: values?.sendToCustomer,
                userId: values?.userId,
                quoteStatusId: values?.quoteStatusId,
                customerId: values?.customerId,
                deadlineId: values?.deadlineId,
                includeFletePDF: values?.includeFletePDF,
                includeSourcesPDF: values?.includeSourcesPDF,
            };
            // Actualizar 
            if (quote) {
                try {
                    // Validar si hubieron cambios 
                    if (quote?.customerId !== values.customerId
                        || quote?.validityId !== values.validityId
                        || quote?.deadlineId !== values.deadlineId
                        || Number(quote?.deliveryTime) !== Number(values.deliveryTime)
                        || quote?.deliveryLocation !== values.deliveryLocation
                        || quote?.includeFletePDF !== values.includeFletePDF
                        || quote?.includeSourcesPDF !== values.includeSourcesPDF
                    ) {
                        showLoader();

                        // Validar si esta habilitado para llamar al servicio de actualización
                        if (availableEdit) {
                            const response = await updateRegister({
                                model: ENV.CLIENT_ROUTES.QUOTES.MODEL,
                                id: quote?.id,
                                data: newQuote
                            });

                            if (response?.data?.error) {
                                enqueueSnackbar(response.data.error, { variant: "error" });
                                hideLoader();
                                return;
                            }

                            setQuote(prevState => ({ ...prevState, ...values, ...response.data.data[0] }));

                            // Actualizar variable para recargar datos al cerrar el modal.
                            if (setReloading instanceof Function) setReloading();

                            if (response?.data?.message) {
                                enqueueSnackbar(response.data.message, { variant: "success" });
                            }
                        }
                        hideLoader();
                        if (nextStep instanceof Function) {
                            nextStep();
                        }
                    } else {
                        if (nextStep instanceof Function) {
                            nextStep();
                        }
                    }

                } catch (error) {
                    if (error?.response?.data?.error) {
                        enqueueSnackbar(error.response.data.error, { variant: "error" });
                    } else {
                        enqueueSnackbar("Error al actualizar la cotización", { variant: "error" });
                    }
                    hideLoader();
                }
            } else {
                try {
                    showLoader();

                    if (!availableEdit) {
                        enqueueSnackbar("La cotización no se puede editar", { variant: "error" });
                        return;
                    }
                    // Crear cotización
                    const response = await createNewRegister({ model: ENV.CLIENT_ROUTES.QUOTES.MODEL, data: newQuote });

                    if (response?.data?.error) {
                        enqueueSnackbar(response.data.error, { variant: "error" });
                        hideLoader();
                        return;
                    }
                    setQuote(prevState => ({ ...prevState, ...values, ...response.data.data[0] }));
                    if (response?.data?.message) {
                        enqueueSnackbar(response.data.message, { variant: "success" });
                    }
                    hideLoader();

                    // Actualizar variable para recargar datos al cerrar el modal.
                    if (setReloading instanceof Function) setReloading();

                    if (nextStep instanceof Function) {
                        nextStep();
                    }

                } catch (error) {
                    if (error?.response?.data?.error) {
                        enqueueSnackbar(error.response.data.error, { variant: "error" });
                    } else {
                        enqueueSnackbar("Error al registrar la cotización", { variant: "error" });
                    }
                    hideLoader();
                }
            }
        },
    })

    const onReload = () => setReload(prevState => !prevState);

    return (
        <>
            <Box onSubmit={formik.handleSubmit} id="quote-form" component="form" sx={{ width: "100%" }}>
                <CustomStack
                    direction={{ xs: "row" }}
                    spacing={{ sm: 1 }}>
                    <SelectAutocomplete
                        disabled={!availableEdit}
                        value={quote?.customerId || ""}
                        textfieldError={formik?.touched?.customerId && Boolean(formik?.errors?.customerId)}
                        textfieldHelpertext={formik?.touched?.customerId && formik?.errors?.customerId}
                        reload={reload}
                        sx={{
                            height: "inherit",
                            flexGrow: 8
                        }}
                        model="customers"
                        label="Seleccionar cliente"
                        column="name"
                        name="customers"
                        getDefaultValue={(option) => {
                            if (option) {
                                formik.setFieldValue("customerId", option?.id);
                                formik.setFieldValue("customer", option)
                            }
                        }}
                        onChange={async (id, __, ___, val) => {
                            await formik.setFieldValue("customerId", id ?? "");
                            formik.setFieldValue("customer", val);
                        }}
                    />
                    <div
                        className="quoteFormNewRegisterBtnContainer"
                    >
                        <NewRegisterButton
                            onReload={onReload}
                            model="customers"
                            endIcon={<Add />}
                            label={smBreakpoint ? "Nuevo cliente" : ""}
                        />
                    </div>
                </CustomStack>

                <CustomStack>
                    <SelectAutocomplete
                        disabled={!availableEdit}
                        sx={{
                            width: "100%"
                        }}
                        onBlur={formik.handleBlur}
                        value={quote?.validityId ?? ""}
                        reload={reload}
                        model="validity"
                        label="Vigencia"
                        column="name"
                        name="validity"
                        textfieldError={formik?.touched?.validityId && Boolean(formik?.errors?.validityId)}
                        textfieldHelpertext={formik?.touched?.validityId && formik?.errors?.validityId}
                        onChange={async (id, __, ___, val) => {
                            await formik.setFieldValue("validityId", id);
                            formik.setFieldValue("validity", val);
                        }}
                        getDefaultValue={(option) => {
                            if (option) {
                                formik.setFieldValue("validityId", option?.id);
                                formik.setFieldValue("validity", option)
                            }
                        }}
                        orderConfig={{ columnName: "value", order: "ASC" }}
                    />

                    <SelectAutocomplete
                        disabled={!availableEdit}
                        sx={{
                            width: "100%"
                        }}
                        value={quote?.deadlineId ?? ""}
                        reload={reload}
                        model="deadline"
                        label="Plazo de pago"
                        column="name"
                        name="deadline"
                        textfieldError={formik?.touched?.deadlineId && Boolean(formik?.errors?.deadlineId)}
                        textfieldHelpertext={formik?.touched?.deadlineId && formik?.errors?.deadlineId}
                        onChange={async (id, __, ___, val) => {
                            await formik.setFieldValue("deadlineId", id);
                            formik.setFieldValue("deadline", val);
                        }}
                        getDefaultValue={(option) => {
                            if (option) {
                                formik.setFieldValue("deadlineId", option?.id);
                                formik.setFieldValue("deadline", option)
                            }
                        }}
                        orderConfig={{ columnName: "value", order: "ASC" }}
                    />
                </CustomStack>

                <CustomStack>
                    <TextField
                        disabled={!availableEdit}
                        value={formik.values.deliveryTime}
                        onChange={formik.handleChange}
                        label="Tiempo de entrega (ingresar número de días)"
                        name="deliveryTime"
                        fullWidth
                        error={formik.touched.deliveryTime && Boolean(formik.errors.deliveryTime)}
                        helperText={formik.touched.deliveryTime && formik.errors.deliveryTime}
                    />
                    <TextField
                        disabled={!availableEdit}
                        id="deliveryLocation"
                        value={formik.values.deliveryLocation}
                        onChange={formik.handleChange}
                        label="Lugar de entrega"
                        name="deliveryLocation"
                        fullWidth
                        error={formik.touched.deliveryLocation && Boolean(formik.errors.deliveryLocation)}
                        helperText={formik.touched.deliveryLocation && formik.errors.deliveryLocation}
                    />
                </CustomStack>

                <CustomStack>
                    <FormGroup
                        className="displayFlex flexJustifyContentSpaceBetween flexDirectionRow fullWidth">
                        <FormControl
                            sx={{
                                width: {
                                    sm: "50%",
                                }
                            }}>
                            <FormControlLabel
                                name="includeFletePDF"
                                label="Incluir valor del flete en el PDF de la cotización"
                                disabled={!availableEdit}
                                onChange={formik.handleChange}
                                control={
                                    <Checkbox
                                        checked={formik.values.includeFletePDF}
                                        inputProps={{ 'aria-label': "controlled" }}
                                    />
                                } />
                        </FormControl>
                        <FormControl
                            sx={{
                                width: {
                                    sm: "50%",
                                }
                            }}>
                            <FormControlLabel
                                name="includeSourcesPDF"
                                label="Incluir fuentes en el PDF de la cotización"
                                disabled={!availableEdit}
                                onChange={formik.handleChange}
                                control={
                                    <Checkbox
                                        checked={formik.values.includeSourcesPDF}
                                        inputProps={{ 'aria-label': "controlled" }}
                                    />
                                } />
                        </FormControl>
                    </FormGroup>
                    <TextField
                        id="date"
                        disabled
                        value={formik.values.date}
                        label="Fecha"
                        type="date"
                        name="date"
                        onChange={formik.handleChange}
                        fullWidth
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </CustomStack>
            </Box>
        </>
    )
};