import React, { useCallback, useState, useEffect } from "react";
import { styled, Box, AppBar as MuiAppBar, Toolbar, IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";

import "../../custom.css";
import Header from "./Header";
import Drawer from "./Drawer/Drawer";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `${drawerWidth}px`,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}
));

export default function MenuNavegacion(Props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.onbeforeunload = function() {
        return true;
    };
  
    return () => {
        window.onbeforeunload = null;
    };
  }, []);

  const onOpenCloseDrawer = useCallback(() => setOpen(prevState => !prevState), []);

  return (
    <Box
      className="displayFlex flexDirectionColumn height100vh"
      flexGrow={1}>
      <AppBar position="fixed" open={open} color="default">
        <Toolbar>
          <IconButton
            className="whiteLetter"
            aria-label="open drawer"
            onClick={onOpenCloseDrawer}
            edge="start">
            <Menu />
          </IconButton>
          <Header {...Props} />
          <Drawer
            open={open}
            anchor="left"
            handleClose={onOpenCloseDrawer}
            drawerHeaderProps={{ title: "Cotizador agrícola" }}
          />
        </Toolbar>
      </AppBar>
      <Main open={open} className="backgroundPage">
        <Props.ContentComponent {...Props} />
      </Main>
    </Box>
  );
}
