import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Grid, IconButton, useMediaQuery, } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { v4 as uuidv4 } from "uuid";
import ElementsSection from "./Sections/ElementsSection";
import NutrientsSection from "./Sections/NutrientsSection";
import FertiliziersSection from "./Sections/FertiliziersSection";
import FooterSection from "./Sections/FooterSection";
import "./MixesForm.css";
import { hideLoader, showLoader } from "../../Controls/Loader";
import { ENV, typeOfMixes } from "../../../utils/constants";
import { createNewRegister, loadFullData, updateRegister } from "../../../apiCore/entity";
import { deleteRegister } from "../../../apiCore/dataTable";
import { useSnackbar } from "notistack";
import { getAllFertilizerWithPrice } from "../../../apiCore/ferilizers";
import { CONVERSION_FACTOR_TO_PERCENTAJE, ELEMENT_GRADE_CONVERSION_FACTOR_TO_TONS } from "../../../utils/conversionFactors";

export default function MixesForm({ mix, mixType, mixName, quote, onOpenCloseModal, onReload, availableEdit }) {

    const { enqueueSnackbar } = useSnackbar();
    const [totalCantidadRealState, setTotalCantidadRealState] = useState(0);
    const [show, setShow] = useState(true);
    const [elements, setElements] = useState([]);
    const [composition, setComposition] = useState([]);
    const [selectedFertilizers, setSelectedFertilizers] = useState([]); // Lista de fertilizantes seleccionados
    const [availableFertilizerList, setAvailableFertilizerList] = useState([]); // Lista de fertilizantes disponibles
    const [fertilizersForCalculateGrade, setFertilizersForCalculateGrade] = useState([]); // Lista de fertilizantes para calcular el grado.
    const [renderFertilizerList, setRenderFertilizerList] = useState([]); // Lista de fertlizantes asignados a la mezcla.
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [columnsWidth, setColumnsWidth] = useState([]); // Almacenar el tamaño de las columnas de la tabla central.

    const selectedFertilizersRef = useRef([]); // Se usa para obtener el valor actual de los fertilizantes seleccionados.
    const elementsRef = useRef([]); // Se usa para obtener el valor actual de los elementos seleccionados.

    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    // Calcula la cantidad total de kg según los fertilizantes seleccionados.
    const totalQuantityFertilizer = useMemo(() => {
        let totalCantidadReal = selectedFertilizers.reduce((acum, currentValue) => acum + Number(currentValue?.value), 0);
        let factorConversionTonelada = totalCantidadReal != 0 ? (1000 / totalCantidadReal):0;
        let totalCantidadRealAjustada = selectedFertilizers.reduce((acum, currentValue) => acum + (factorConversionTonelada * Number(currentValue?.value)), 0);

        setTotalCantidadRealState(totalCantidadReal);
        return totalCantidadRealAjustada.toFixed(0);
    }, [selectedFertilizers, mix]);

    // Calcular la cantidad de Kg real de los fertilizantes seleccionados.
    const totalQuantityFertilizerReal = useMemo(() => {
        return selectedFertilizers.reduce((acum, currentValue) => acum + Number(currentValue?.value), 0);
    }, [selectedFertilizers, mix]);

    // Calcula el total de la composición de los fertilizantes seleccionados según el elemento.
    const getTotalCompositionByElements = useCallback((element) => {
        let total = 0;
        selectedFertilizers.map(fertilizer => {
            const list = composition.filter(item => item.fertilizerId === fertilizer?.id && item.elementId === element?.id);
            const percentageComposition = list[0]?.percentageComposition || 0;
            const fertilizierValue = fertilizer?.value || 0;
            total += (percentageComposition / 100) * fertilizierValue;
        })
        return total;
    }, [selectedFertilizers]);

    // Calcula el precio total de los fertilizantes seleccionados dependiendo del número de kg
    const totalPriceFertilizers = useMemo(() => {
        return selectedFertilizers.reduce((acum, fertilizer) => {
            //Dependiendo del tipo de mezcla se utiliza el facto aproximado a 1000
            let valueQuantity = fertilizer?.value;
            if(mixType?.id === typeOfMixes.byFertilizer){
                valueQuantity = (valueQuantity) * (1000 / totalQuantityFertilizerReal)
            }

            const total = Number(valueQuantity) * Number(fertilizer?.pricePerKg);
            return acum + total;
        }, 0)
    }, [selectedFertilizers, mix]);

    // Actualiza el valor de un elemento en especifico.
    const updateElementValue = (element, value) => {
        setElements(prevState => {
            const newState = prevState.map(item => {
                if (item.id === element.id) return ({ ...item, value });
                return item;
            });

            elementsRef.current = newState;
            return newState;
        });
    }


    // Permite agregar un nuevo fertilizante de la lista disponible.
    const addFertilizier = () => {
        if (availableFertilizerList.length > 0) {
            const fertilizer = availableFertilizerList[0];

            setSelectedFertilizers(prevState => {
                const newState = [...prevState, fertilizer];
                selectedFertilizersRef.current = newState;
                return newState;
            });
            setRenderFertilizerList(prevState => [...prevState, { uuid: uuidv4(), fertilizer }]);
            availableFertilizerList.splice(0, 1);
        } else {
            alert("No hay más fertilizantes disponibles")
        }
    };

    // Permite eliminar un fertlizante.
    const deleteFertilizier = (component) => {
        if (component?.fertilizer) {
            console.log({ component })
            // Eliminar de la lista de componentes seleccionados
            setSelectedFertilizers(prevState => {
                const newState = prevState.filter(item => item.id !== component.fertilizer?.id);
                selectedFertilizersRef.current = newState;
                return newState;
            });
            // Agregar a la lista de componentes disponible
            setAvailableFertilizerList(prevState => {
                const findFertilizer = prevState.find(item => item.id === component.fertilizer?.id);
                if (findFertilizer) return prevState;
                // Actualizar el valor del fertilizante a cero.
                const fertilizer = { ...component.fertilizer, value: 0 };
                return [...prevState, fertilizer].sort((a, b) => a.id - b.id);
            })
        }
        setRenderFertilizerList(prevState => prevState.filter(item => item.uuid !== component.uuid));
    }

    // Permite actualizar los valores seleccionados de las listas de fertilizantes disponibles, seleccionados y el renderizado de fertilizantes.
    const updateFertilizer = (component, value, type = "select", fertilizerIsUsedForGrade = false) => {

        if (type === "select") {
            if (!value) {
                // Validar si existe un componente asignado
                if (component?.fertilizer) {
                    // Eliminar de la lista de fertilizantes seleccionados
                    setSelectedFertilizers(prevState => {
                        const newState = prevState.filter(item => item.id !== component.fertilizer?.id);
                        selectedFertilizersRef.current = newState;
                        return newState;
                    });

                    // Validar si el fertilizante que se desea actualizar es de la lista de fertilizantes para el calculo del grado.
                    // Si el fertilizante se usa para calcular el grado, no se actualiza de las listas availableFertilizerList y renderFertilizerList
                    if (!fertilizerIsUsedForGrade) {
                        // Agregar a la lista de fertlizantes disponibles y ordenar por id
                        setAvailableFertilizerList(prevState => {
                            const findFertilizer = prevState.find(item => item.id === component.fertilizer?.id);
                            if (findFertilizer) return prevState;
                            return [...prevState, component.fertilizer].sort((a, b) => a.id - b.id);
                        });

                        // Eliminar el fertilizante asignado
                        setRenderFertilizerList(prevState => {
                            return prevState.map(item => {
                                if (item.uuid === component.uuid) return ({ ...item, fertilizer: null });
                                return item;
                            })
                        });
                    }

                }
            } else {
                // Agregar a la lista de fertilizantes seleccionados el nuevo valor
                setSelectedFertilizers(prevState => {
                    const newState = prevState?.map(fertilizer => (component?.fertilizer?.id === fertilizer?.id) ? value : fertilizer);
                    selectedFertilizersRef.current = newState;
                    return newState;
                });

                // Si el fertilizante se usa para calcular el grado, no se actualiza de las listas availableFertilizerList y renderFertilizerList.
                if (!fertilizerIsUsedForGrade) {
                    // Eliminar de la lista de fertilizantes disponibles el fertilizante seleccionado.
                    setAvailableFertilizerList(prevState => {
                        let newState = prevState.filter(item => item.id !== value.id);
                        if (component?.fertilizer) {
                            const findFertilizer = newState.find(item => item.id === component.fertilizer?.id);

                            if (findFertilizer) return newState;
                            return [...newState, component.fertilizer].sort((a, b) => a.id - b.id);
                        } else {
                            return newState;
                        }
                    });

                    // Actualiza la lista de componentes que se renderizan con el nuevo fertilizante seleccionado
                    setRenderFertilizerList(prevState => {
                        return prevState.map(item => {
                            if (item.uuid === component.uuid) return ({ ...item, fertilizer: value });
                            return item;
                        });
                    });
                }
            }
        } else {
            // Actualizar el valor asignado al fertilizante.
            if (component?.fertilizer) {

                // Actualizar lista de fertilizantes seleccionados
                setSelectedFertilizers(prevState => {
                    const newState = prevState.map(item => {
                        if (item.id === component.fertilizer?.id) return { ...item, value }
                        return item;
                    });
                    selectedFertilizersRef.current = newState;
                    return newState;
                });

                // Si el fertilizante se usa para calcular el grado, no se actualiza de las listas availableFertilizerList y renderFertilizerList.
                if (!fertilizerIsUsedForGrade) {
                    // Actualizar valor en la lista de fertilizantes disponibles
                    setAvailableFertilizerList(prevState => {
                        return prevState.map(item => {
                            if (item.id === component.fertilizer?.id) return { ...item, value }
                            return item;
                        });
                    });

                    // Actualizar valores de la lista de componentes
                    setRenderFertilizerList(prevState => {
                        return prevState.map(item => {
                            if (item.uuid === component.uuid) return { ...item, fertilizer: { ...item.fertilizer, value } }
                            return item;
                        });
                    });
                }
            }
        }


    }

    // Calcula el grado del elemento de la mezcla, se usa para visualizar en el sección de elementos.
    const calculateGrade = useCallback((element) => {
        const total = getTotalCompositionByElements(element);
        const result = (total / totalQuantityFertilizerReal) * 100 || 0;
        return parseFloat(result).toFixed(1);
    }, [selectedFertilizers]);

    // Calcula el grado del elemento de la mezcla, se usa para calcular el valor al registrar la mezcla.
    const getGrade = (element) => {
        const total = getTotalCompositionByElements(element);
        const result = (total / totalQuantityFertilizerReal) * 100 || 0;
        return parseFloat(result).toFixed(1);
    }

    // Registrar nuevas mezclas
    const registerNewMix = async () => {
        try {
            if (mixType?.id === typeOfMixes.byGrade && totalQuantityFertilizer != 1000) {
                enqueueSnackbar("para la cotización por grado, la recomendación final, debe ser igual a 1000kg ", { variant: "error" });
            }else {

                // Validar para que solo se registren mezclas de cotizaciones que esten disponibles para editar
                if (!availableEdit) {
                    enqueueSnackbar("La cotización no se puede editar", { variant: "error" });
                    return;
                }

                // Si la mezcla tiene fertilizantes con valores negativos no se permite registrar.
                if (selectedFertilizersHaveNegativeValues()) return;

                showLoader();
                const newMix = {
                    name: mixName,
                    typeOfMixId: mixType?.id,
                    totalTons: (mixType?.id === typeOfMixes.byGrade) ? totalQuantityFertilizerReal:totalQuantityFertilizer,
                    price: totalPriceFertilizers,
                    quoteId: quote?.id,
                };

                // Registrar mezcla.
                const response = await createNewRegister({
                    model: ENV.CLIENT_ROUTES.MIXES.MODEL,
                    data: newMix,
                });
                const { error, data } = response?.data || {};
                if (error) throw { error: response };

                if (data) {
                    const { id: mixId } = data[0] || {};

                    //Objeto tempora para la construcción de la previsualización de mezclas
                    let newMixTemp = newMix;
                    newMixTemp.mixId = data[0].id;
                    newMixTemp.type = mixType;
                    newMixTemp.elements = elements.map((el)=>({...el, elementId:el.id}));
                    newMixTemp.selectedFertilizers = selectedFertilizers;

                    // Registrar elementos
                    for (let index = 0; index < elements.length; index++) {
                        let value = elements[index]?.value;

                        // Si la mezcla es de tipo fertilizantes, se debe de calcular el valor
                        // del grado del elemento.
                        if (mixType?.id === typeOfMixes.byFertilizer) {
                            value = getGrade(elements[index]);
                        };


                        //Seteo de arreglo temporal local
                        newMixTemp.elements[index].value = value;

                        const resNewElement = await createNewRegister({
                            model: ENV.CLIENT_ROUTES.ELEMENTBYMIX.MODEL,
                            data: {
                                value,
                                elementId: elements[index]?.id,
                                mixId: mixId,
                            }
                        });
                        const { error } = resNewElement?.data || {};
                        if (error) throw { error: response };
                    };

                    // Registrar fertilizantes
                    for (let index = 0; index < selectedFertilizers.length; index++) {
                        if (selectedFertilizers[index]?.value) {
                            const resNewFertilizer = await createNewRegister({
                                model: ENV.CLIENT_ROUTES.FERTLIZERBYMIX.MODEL,
                                data: {
                                    value: selectedFertilizers[index]?.value,
                                    pricePerKg: selectedFertilizers[index]?.pricePerKg,
                                    fertilizerId: selectedFertilizers[index]?.id,
                                    mixId: mixId,
                                }
                            });
                            const { error } = resNewFertilizer?.data || {};
                            if (error) throw { error: response };
                        };
                    };

                    // Recargar tabla de mezclas.
                    if (onReload instanceof Function) {
                        onReload(newMix);
                    } else {
                        hideLoader();
                    };

                    // Cerrar formulario.
                    if (onOpenCloseModal instanceof Function) {
                        onOpenCloseModal();
                    };

                } else {
                    enqueueSnackbar("Error al obtener los datos del servidor", { variant: "error" });
                }
            }
        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al registrar la mezcla", { variant: "error" });
            }
            hideLoader();
        };
    };

    // Actualizar mezclas.
    const updateMix = async () => {
        try {

            if (!availableEdit) {
                enqueueSnackbar("La cotización no se puede editar", { variant: "error" });
                return;
            }

            if (mixType?.id === typeOfMixes.byGrade && totalQuantityFertilizer != 1000) {
                enqueueSnackbar("para la cotización por grado, la recomendación final, debe ser igual a 1000kg ", { variant: "error" });
            }else {

                // Si hay valore negativos no se permite actualizar.
                if (selectedFertilizersHaveNegativeValues()) return;

                const {
                    id: mixId,
                    name,
                    typeOfMixId,
                    price,
                    totalTons,
                    quoteId,
                    elements: mixElements = [],
                    fertilizers: mixFertilizers = [],
                } = mix || {};

                showLoader();
                const currentMix = {
                    name: mixName,
                    typeOfMixId: mixType?.id,
                    totalTons: (mixType?.id === typeOfMixes.byGrade) ? totalQuantityFertilizerReal:totalQuantityFertilizer,
                    price: totalPriceFertilizers,
                    quoteId: quote?.id,
                };

                // Validar si la mezclas tuvo cambios para realizar actualización de datos.
                if (name !== currentMix.name ||
                    typeOfMixId !== currentMix.typeOfMixId ||
                    totalTons !== currentMix.totalTons ||
                    price !== currentMix.price ||
                    quoteId !== currentMix.quoteId
                ) {
                    // Actualizar datos de la mezcla
                    const response = await updateRegister({
                        model: ENV.CLIENT_ROUTES.MIXES.MODEL,
                        id: mixId,
                        data: currentMix,
                    });
                    const { error } = response?.data || {};
                    if (error) throw { error: response };
                };

                // Actualizar valores de los elementos existentes.
                for (let index = 0; index < mixElements?.length; index++) {
                    const findElement = elements.find(element => element?.id === mixElements[index]?.elementId);
                    let value = findElement?.value;
                    if (mixType?.id === typeOfMixes.byFertilizer) {
                        value = getGrade(findElement);
                    };

                    if (Number(value) !== Number(mixElements[index]?.value)) {
                        const response = await updateRegister({
                            model: ENV.CLIENT_ROUTES.ELEMENTBYMIX.MODEL,
                            id: mixElements[index]?.id,
                            data: {
                                value,
                            }
                        });
                        const { error } = response?.data || {};
                        if (error) throw { error: response };
                    };
                };

                // Registrar nuevos elementos.
                for (let index = 0; index < elements.length; index++) {
                    const findElement = mixElements?.find(element => element?.elementId === elements[index]?.id);
                    if (!findElement) {
                        let value = elements[index]?.value;

                        // Si la mezcla es de tipo fertilizantes, se debe de calcular el valor
                        // del grado del elemento.
                        if (mixType?.id === typeOfMixes.byFertilizer) {
                            value = getGrade(elements[index]);
                        };
                        const response = await createNewRegister({
                            model: ENV.CLIENT_ROUTES.ELEMENTBYMIX.MODEL,
                            data: {
                                value,
                                elementId: elements[index]?.id,
                                mixId,
                            }
                        });
                        const { error } = response?.data || {};
                        if (error) throw { error: response };
                    };
                };

                // Actualizar fertilizantes
                // Recorrer los fertilizantes para actualizar el valor y el precio.
                // Si el valor del fertilizante no se encuentra dentro de la lista de mixFertilizers debe de ser eliminado.
                for (let index = 0; index < mixFertilizers?.length; index++) {
                    const findFertilizer = selectedFertilizers.find(fertilizer => fertilizer?.id === mixFertilizers[index]?.fertilizerId);

                    // Se valida si el fertilizante se encuentra seleccionado y que tenga un valor diferente de cero o vacío.
                    if (findFertilizer && findFertilizer?.value) {
                        // Actualizar valores del fertilizante.
                        const response = await updateRegister({
                            model: ENV.CLIENT_ROUTES.FERTLIZERBYMIX.MODEL,
                            id: mixFertilizers[index]?.id,
                            data: {
                                value: findFertilizer?.value,
                                pricePerKg: findFertilizer?.pricePerKg,
                            },
                        });
                        const { error } = response?.data || {};
                        if (error) throw { error: response };
                    } else {
                        // Eliminar fertilizante
                        const response = await deleteRegister(ENV.CLIENT_ROUTES.FERTLIZERBYMIX.MODEL, mixFertilizers[index]?.id);
                        const { error } = response?.data || {};
                        if (error) throw { error: response };
                    };
                };

                // Registrar nuevos fertilizantes
                for (let index = 0; index < selectedFertilizers.length; index++) {
                    const findFertilizer = mixFertilizers?.find(fertilizer => fertilizer?.fertilizerId === selectedFertilizers[index]?.id);

                    if (!findFertilizer && selectedFertilizers[index]?.value) {
                        const response = await createNewRegister({
                            model: ENV.CLIENT_ROUTES.FERTLIZERBYMIX.MODEL,
                            data: {
                                value: selectedFertilizers[index]?.value,
                                pricePerKg: selectedFertilizers[index]?.pricePerKg,
                                fertilizerId: selectedFertilizers[index]?.id,
                                mixId,
                            }
                        });
                        const { error } = response?.data || {};
                        if (error) throw { error: response };
                    }
                };

                // Recargar tabla de mezclas.
                if (onReload instanceof Function) {
                    onReload()
                } else {
                    hideLoader();
                };

                // Cerrar formulario.
                if (onOpenCloseModal instanceof Function) {
                    onOpenCloseModal();
                };
            }
        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al actualizar la mezcla", { variant: "error" });
            };
            hideLoader();
        }
    };

    /**
     * Calcular el tamaño de las columnas de la tabla central
     * donde se renderizan las columnas de los elementos.
     * @param {React.Ref} tableRef - Referencia a la tabla donde se renderiza los títulos de los elementos. 
     */
    const calculateColumnWidths = (tableRef) => {
        if (tableRef?.current) {
            const firstRow = tableRef.current.querySelector('thead tr');
            if (firstRow) {
                const widths = Array.from(firstRow.children).map((column) => column.offsetWidth + 'px');
                setColumnsWidth(widths);
            }
        }
    };

    useEffect(() => {

        // Validar si la pantalla es menor a 600px, si es así se muestra la versión comprimida.
        if (!smBreakpoint) {
            setShow(false);
        }
        (async () => {
            try {
                showLoader();
                const resFfertilizers = await getAllFertilizerWithPrice();
                const resComposition = await loadFullData({model: ENV.CLIENT_ROUTES.FERTILIZERSBYELEMENTS.MODEL});
                const resElements = await loadFullData({model: ENV.CLIENT_ROUTES.ELEMENTS.MODEL});

                if (resComposition?.data?.error || resFfertilizers?.data?.error || resElements?.data?.error) {
                    throw new Error("Error al consultar los datos");
                }

                let _fertilizersForCalculateGrade = [];

                let { data: fertilizers = [] } = resFfertilizers?.data || {};

                // Si la mezcla es por grado, se consultan los fertilizantes para el calculo de la mezcla automatica.
                if (mixType?.id === typeOfMixes.byGrade) {
                    const resFertilizerByAutomaticGrade = await loadFullData({model: ENV.CLIENT_ROUTES.AUTOMATIC_CALCULATION_BY_GRADE.MODEL});

                    if (resFertilizerByAutomaticGrade?.data?.error) { throw new Error("Error al consultar los datos"); }

                    // Lista de fertilizantes para el calculo automatico del grado.
                    _fertilizersForCalculateGrade = resFertilizerByAutomaticGrade?.data?.data?.map(item => {
                        // Validar si la mezcla existe.
                        const fertilizer = resFfertilizers?.data?.data?.find(fertilizer => fertilizer?.id === item?.fertilizerId);
                        if (mix) {
                            const selectFertilizer = mix?.fertilizers?.find(fertilizerOfMix => fertilizerOfMix?.fertilizerId === item?.fertilizerId);
                            return ({ ...item, fertilizer: { ...fertilizer, value: selectFertilizer ? selectFertilizer?.value : 0 } });
                        } else {
                            return ({ ...item, fertilizer: { ...fertilizer, value: 0 } });
                        }
                    });

                    setFertilizersForCalculateGrade(_fertilizersForCalculateGrade);

                    fertilizers = fertilizers?.filter(fertilizer => !_fertilizersForCalculateGrade.find(item => item?.fertilizerId === fertilizer?.id));
                }

                // Validar si se esta actualizando una mezcla.
                if (mix) {
                    // Consultar los valores de los elementos asignados a la mezcla.
                    const { data: elements } = resElements?.data || [];

                    const consultedSelectedElements = elements.map(element => {
                        const findElement = mix?.elements?.find(item => item?.elementId === element?.id);
                        if (findElement) return { ...element, value: findElement?.value };
                        return { ...element, value: 0 };
                    });

                    setElements(consultedSelectedElements);
                    elementsRef.current = consultedSelectedElements;

                    // Constultar los valores de los fertilizantes asignados a la mezcla.
                    // Filtrar los fertilizantes seleccionados.
                    // const { data: fertilizers } = resFfertilizers?.data || [];
                    let consultedSelectedFertilizers = fertilizers?.filter(fertilizer => mix?.fertilizers.find(item => item?.fertilizerId === fertilizer?.id));

                    consultedSelectedFertilizers = consultedSelectedFertilizers.map(fertilizer => {
                        const findFertilizer = mix?.fertilizers.find(item => item?.fertilizerId === fertilizer?.id);
                        if (findFertilizer) return { ...fertilizer, value: findFertilizer?.value };
                        return { ...fertilizer, value: 0 };
                    });

                    // Variable para controlar si un fertilizante de un grupo ya ha sido seleccionado.
                    const selectedGroups = {};

                    // Se filtran los fertilizantes que se encuentran seleccionados en la mezcla.
                    const filterSelectedFertilizersByGradeInMix = _fertilizersForCalculateGrade?.filter(fertilizerByGrade => {
                        const fertilizerInMix = mix?.fertilizers?.find(fertilizerOfMix => fertilizerByGrade?.fertilizerId === fertilizerOfMix?.fertilizerId);
                        if (fertilizerInMix) { selectedGroups[`${fertilizerByGrade?.groupName}`] = true };
                        return fertilizerInMix;
                    });

                    // Se filtran los fertilizantes que no se encuentran seleccionados en la mezcla, pero que se deben de seleccionar por defecto.
                    const filterSelectedFertilizersByGradeSelectedByDefault = _fertilizersForCalculateGrade?.filter(fertilizerByGrade => {
                        const fertilizerInMix = mix?.fertilizers?.find(fertilizerOfMix => fertilizerByGrade?.fertilizerId === fertilizerOfMix?.fertilizerId);
                        return !fertilizerInMix && fertilizerByGrade?.selectedByDefault && !selectedGroups[`${fertilizerByGrade?.groupName}`]
                    });

                    // Se mapean los fertilizantes para obtener las propiedades de los fertilizantes.
                    const consultedSelectedFertilizersByGrade = [...filterSelectedFertilizersByGradeInMix, ...filterSelectedFertilizersByGradeSelectedByDefault]?.map(fertilizerByGrade => fertilizerByGrade?.fertilizer);

                    const currentSelecteFertilizers = [...consultedSelectedFertilizersByGrade, ...consultedSelectedFertilizers,];

                    setSelectedFertilizers(currentSelecteFertilizers);
                    selectedFertilizersRef.current = currentSelecteFertilizers;

                    // Fertilizantes que se deeb de renderizar.
                    setRenderFertilizerList(prevState => {
                        return consultedSelectedFertilizers.map(fertilizer => ({ ...prevState, uuid: uuidv4(), fertilizer }));
                    });

                    // Buscar los fertilizantes disponibles.
                    let avalaibleFertilzers = [...fertilizers] || [];
                    mix?.fertilizers?.forEach(fertilizer => {
                        avalaibleFertilzers = avalaibleFertilzers.filter(item => item.id !== fertilizer.fertilizerId);
                    });

                    setAvailableFertilizerList(avalaibleFertilzers.map(fertilizer => ({ ...fertilizer, value: 0 })));

                } else {
                    // Se va a crear una nueva mezcla.
                    const resFertilizerDefault = await loadFullData({
                        model: ENV.CLIENT_ROUTES.FERTILIZERDEFAULT.MODEL,
                    });

                    if (resFertilizerDefault?.data?.error) {
                        throw { error: resFertilizerDefault };
                    }

                    // Asignar los elementos disponibles, con valor inicial de cero.
                    const availabeElements = resElements.data.data.map(element => ({ ...element, value: 0 }))
                    setElements(availabeElements);
                    elementsRef.current = availabeElements;

                    // Asignar toda la lista de fertilizantes disponibles.
                    let avalaibleFertilzers = fertilizers || [];

                    const fertilizersDefaultByMix = resFertilizerDefault.data.data.filter(item => item?.typeOfMixId === mixType?.id) || [];

                    // Asignar los fertilizantes seleccionados previamente cuando la mezcla es por fertilizante.
                    // if (mixType?.id === typeOfMixes.byFertilizer) {

                    // Filtrar los fertilizantes que ya se encuentran seleccionados.
                    const selectedFerilizers = avalaibleFertilzers.filter(fertilizer => {
                        return fertilizersDefaultByMix.find(item => item?.fertilizerId === fertilizer?.id);
                    }).map(fertilizer => ({ ...fertilizer, value: 0 }));

                    // Obteber el array con los fertilizantes que se usan para el calculo del grado.

                    const selectedFertilizersByGrade = _fertilizersForCalculateGrade?.filter(item => Boolean(item?.selectedByDefault)).map(item => item?.fertilizer);

                    const newSelectedFertilizers = [...selectedFertilizersByGrade, ...selectedFerilizers,];

                    setSelectedFertilizers(newSelectedFertilizers);
                    selectedFertilizersRef.current = newSelectedFertilizers;

                    setAvailableFertilizerList(avalaibleFertilzers.filter(fertilizer => {
                        return !fertilizersDefaultByMix.find(item => item?.fertilizerId === fertilizer?.id);
                    }).map(fertilizer => ({ ...fertilizer, value: 0 })));

                    setRenderFertilizerList(avalaibleFertilzers.filter(fertilizer => {
                        return fertilizersDefaultByMix.find(item => item?.fertilizerId === fertilizer?.id);
                    }).map(fertilizer => ({ uuid: uuidv4(), fertilizer: { ...fertilizer, value: 0 } })));
                }

                setComposition(resComposition?.data?.data);
                hideLoader();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
                hideLoader();
            }
        })();
    }, []);



    /**
     * Permite calcular automaticamente la cantidad de fertilizante necesaria con base en la cantidad de grado de un elemento registrado.
     * El parámetro currentFertilizer se usa para evitar el recalculo de la cantidad de fertilizante cuando se ingresa 
     * manualmente la cantidad de fertilizante, recalculando solamente los valores de los demás fertilizantes.
     * @param {Object} currentFertilizer - corresponde a un fertilizante y puede ser null.
     */
    function automaticGradeCalculation(currentFertilizer = null) {
        // Obtener los valores de composición de los fertilizantes para cada uno de los elementos.
        let fertilizersForCalculateGradeWithComposition = fertilizersForCalculateGrade.map(fertilizer => {
            const _fertilizerCompostion = composition.filter(item => (fertilizer?.fertilizerId == item?.fertilizerId));
            return ({ ...fertilizer, composition: _fertilizerCompostion })
        });

        // De los fertilizantes seleccionados, filtrar los que tengan un valor mayor a cero para almacenarlos y
        // realizar los calculo de los valores de fertilizantes.
        let currentSelectedFertilizersUpdated = selectedFertilizersRef.current?.filter(fertilizer => fertilizer?.value > 0) || [];

        // Recorrer la lista de fertilizantes seleccionados con los valores actualizados.
        const newSelectedFertilizers = selectedFertilizersRef.current?.map(fertilizer => {

            // Si el valor del currentFertilizer existe, significa que se ingreso manualmente el valor de esta fertilizante,
            // por lo que no se debe de recalcular los valores de fertilizante.
            if (fertilizer?.id === currentFertilizer?.id) return fertilizer;

            // De los fertilizantes seleccionados, encontrar cual se encuentra dentro de la lista de fertilizantes para calcular el grado.
            const findFertilizer = fertilizersForCalculateGradeWithComposition?.find(item => fertilizer?.id === item?.fertilizerId);

            // Validar si el fertiliziante seleccionado se encuentra dentro de la lista de fertilizantes que se usan para el calculo automatico.
            if (!findFertilizer) return fertilizer;

            // De los fertilizantes que se usan para calcular el grado, se filtra los que tengan porcentaje de composición en el mismo elemento del
            // fertilizante que se encuentra asignado en la variable findFertilizer.
            const sameCompositionFertilizers = fertilizersForCalculateGradeWithComposition.filter(item => {
                const _composition = item?.composition || [];
                return _composition?.find(item2 => item2?.elementId === findFertilizer?.elementId && item2?.percentageComposition > 0) && item?.fertilizerId !== findFertilizer?.fertilizerId;
            });

            // Se calcula el porcentaje de aporte que se debe de descontar, según el grado de composición de los fertilizantes que cuentan con
            // aporte en el elemento asociado al fertilizante asignado en la variable findFertilizer.
            const contributionOtherFertilizers = sameCompositionFertilizers.reduce((acc, currentValue) => {
                // Se busca el fertilizante asociado al fertilizante asociado a currentValue.
                const currentFindFertilizer = currentSelectedFertilizersUpdated.find(fertilizer => fertilizer?.id === currentValue?.fertilizerId);

                if (!currentFindFertilizer) return acc;

                // Se busca el porcentaje de composición del fertilizante.
                const percentageComposition = currentValue?.composition.find(item => item?.elementId === findFertilizer?.elementId);

                if (!percentageComposition?.percentageComposition) return acc;

                // Se retornan el acumulado, más la multiplicación del valor de fertilizante por el porcentaje de composición del elemento divido entre
                // el factor de conversión a porcentaje (100).
                return acc + (currentFindFertilizer?.value * (percentageComposition?.percentageComposition / CONVERSION_FACTOR_TO_PERCENTAJE));
            }, 0);

            // Se consulta la composición del fertilizante.
            const getCompositionOfFindFertilizer = findFertilizer?.composition?.find(item => item?.fertilizerId === findFertilizer?.fertilizerId && item?.elementId === findFertilizer?.elementId);

            /// Obtener el valor del grado del elemento seleccionado.
            const gradeValue = elementsRef.current?.find(element => element?.id === findFertilizer?.elementId);

            // Se calcula el nuevo valor del fertilizante, teniendo en cuenta el valor del grado del elemento asignado al fertilizante, restando el aporte de los
            // demás fertilizantes por el porcentaje de aporte en el elemento asignado al fertilizante, y el total de este resultado se divide entre el 
            // porcentaje de composición de elemento del fertilizante.
            const fertilizerValue = ((Number(gradeValue?.value) * ELEMENT_GRADE_CONVERSION_FACTOR_TO_TONS) - contributionOtherFertilizers) / (getCompositionOfFindFertilizer?.percentageComposition / 100);

            // Redondear el nuevo valor del fertilizante.
            const roundedValue = Math.round(Number(fertilizerValue));
            // const roundedValue = Math.round(Number(fertilizerValue / 10)) * 10; // Redondear al múltiplo de 10 inferior.

            // Asignar al fertilizante el nuevo valor calculado.
            const fertilizerUpdate = ({ ...fertilizer, value: (!isFinite(roundedValue) || isNaN(roundedValue)) ? 0 : roundedValue });

            // Se valida si el fertilizante se encuentra de la lista de fertilizantes seleccionados, en caso de existir se actualiza el valor
            // y en caso contrario se agrega al array.
            if (currentSelectedFertilizersUpdated.find(item => item?.id === fertilizerUpdate?.id)) {
                currentSelectedFertilizersUpdated = currentSelectedFertilizersUpdated?.map(item => item?.id === fertilizerUpdate?.id ? fertilizerUpdate : item);
            } else {
                currentSelectedFertilizersUpdated.push(fertilizerUpdate);
            }

            return fertilizerUpdate;
        });

        setSelectedFertilizers(newSelectedFertilizers);
        selectedFertilizersRef.current = newSelectedFertilizers;
    }

    function selectedFertilizersHaveNegativeValues() {
        const hasNegativeValues = selectedFertilizersRef.current.some(fertilizer => fertilizer?.value < 0);

        if (hasNegativeValues) {
            enqueueSnackbar("La mezcla tiene fertilizantes con valores negativos, por favor ajuste los valores", { variant: "error" });
        }
        return hasNegativeValues;
    }

    return (
        <Box
            className="formMixes"
            style={{padding:"5px", paddingTop:"23px"}}
            sx={{
                px: {
                    xs: 0,
                    sm: 1,
                    lg: show ? 5 : 18,
                },
            }}>
            {isError && (
                <h2 className="error">Error al cargar el formulario</h2>
            )}
            {(!isLoading && !isError) && (
                <>
                    <Grid
                        container
                        className="backgroundWhite">
                        {/* Sección de elementos */}
                        <ElementsSection
                            mixType={mixType}
                            elements={elements}
                            show={show}
                            updateElementValue={updateElementValue}
                            automaticGradeCalculation={automaticGradeCalculation}
                            calculateGrade={calculateGrade}
                            availableEdit={availableEdit}
                            calculateColumnWidths={calculateColumnWidths}
                        />
                        {/* Sección de nutrientes */}
                        <NutrientsSection
                            elements={elements}
                            show={show}
                            mixName={mixName}
                            mixType={mixType}
                            getTotalCompositionByElements={getTotalCompositionByElements}
                            totalQuantityFertilizer={(mixType?.id === typeOfMixes.byGrade) ? totalQuantityFertilizerReal:totalQuantityFertilizer}
                            columnsWidth={columnsWidth}
                        />

                        {/* Sección de fertilizantes */}
                        <FertiliziersSection
                            addFertilizier={addFertilizier}
                            handleOnChange={updateFertilizer}
                            deleteFertilizier={deleteFertilizier}
                            selectedFertilizers={selectedFertilizers}
                            setFertiliziers={setSelectedFertilizers}
                            availableFertilizerList={availableFertilizerList}
                            renderFertilizerList={renderFertilizerList}
                            show={show}
                            elements={elements}
                            composition={composition}
                            availableEdit={availableEdit}
                            columnsWidth={columnsWidth}
                            fertilizersForCalculateGrade={fertilizersForCalculateGrade}
                            automaticGradeCalculation={automaticGradeCalculation}
                            mixType={mixType}
                            totalQuantityFertilizer={totalCantidadRealState}
                        />

                        {/* Sección footer */}
                        <FooterSection
                            totalQuantityFertilizer={(mixType?.id === typeOfMixes.byGrade) ? totalQuantityFertilizerReal:totalQuantityFertilizer}
                            fertiliziers={selectedFertilizers}
                            show={show}
                            elements={elements}
                            getTotalCompositionByElements={getTotalCompositionByElements}
                            totalPriceFertilizers={totalPriceFertilizers}
                            mixType={mixType}
                            columnsWidth={columnsWidth}
                        />
                    </Grid>
                    {availableEdit && (
                        <Box pt={3}>
                            <Button
                                startIcon={<AddCircleIcon />}
                                color="success"
                                type=""
                                disabled={!availableEdit}
                                variant="contained"
                                onClick={() => {
                                    if (availableEdit) {
                                        mix ? updateMix() : registerNewMix()
                                    } else {
                                        enqueueSnackbar("La cotización no se puede editar", { variant: "error" });
                                    }
                                }}>
                                {mix ? "Actualizar mezcla" : "Adicionar mezcla"}
                            </Button>
                        </Box>
                    )}

                    <IconButton
                        className="iconFormMixes"
                        onClick={() => setShow(prevState => !prevState)}>
                        {show
                            ? (<ChevronLeft sx={{ fontSize: "3rem" }} />)
                            : (<ChevronRight sx={{ fontSize: "3rem" }} />)}
                    </IconButton>
                </>
            )}
        </Box>
    )
}