import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { deleteRegister } from "../../../../apiCore/dataTable";
import { ENV } from "../../../../utils/constants";
import { IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";

import ConfirmModal from "../../../Controls/ConfirmModal";

export default function DeleteButton({ id, onReload, disabled }) {
    const [open, setOpen] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const onDelete = async () => {
        try {
            const response = await deleteRegister(ENV.CLIENT_ROUTES.QUOTES.MODEL, id);
            const { error, message } = response?.data || {};

            if (error) throw { error: response };

            if (message) enqueueSnackbar(message, { variant: "success" });

            setOpen(false);

            if (onReload instanceof Function) onReload();

        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar(`Error al interntar eliminar el registro con ID: ${id}`, { variant: "error" });
            }
        }
    }

    return (
        <React.Fragment>
            <Tooltip title="Eliminar">
                <span>
                    <IconButton
                        aria-label="delete"
                        color='inherit'
                        size='small'
                        onClick={() => setOpen(true)}
                        disabled={disabled}
                    >
                        <Delete fontSize="inherit" />
                    </IconButton>
                </span>
            </Tooltip>

            <ConfirmModal
                open={open}
                setOpen={setOpen}
                confirmButtonText="Confirmar Eliminación"
                titleMessage="Borrar Registro"
                confirmMessage={"¿Confirma que desea eliminar el registro con ID: " + id + "?"}
                confirmHandler={onDelete}
            />
        </React.Fragment >
    );
}
