import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useMainContext } from "../../hooks/useMainContext";
import { ENV } from "../../utils/constants";

export default function AuthRoutes() {
    const { user } = useMainContext();

    const location = useLocation();

    return (
        user
            ? <Outlet />
            : <Navigate to={ENV.CLIENT_ROUTES.LOGIN} state={{ from: location }} replace />
    )
}