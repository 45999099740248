import * as Yup from "yup";

export function initialValues({ mix, maquilaCost }) {
    return {
        quantityTons: mix?.quantityTons ?? "",
        packagingTypeId: mix?.packagingTypeId || "",
        packagingUnitPrice: mix?.packagingUnitPrice || "",
        profitability: mix?.profitability ?? "",
        discount: mix?.discount ?? "", // El valor del descuento puede ser cero.
        flete: mix?.flete ?? "", // // El valor del flete puede ser cero.
        maquilaCost: mix?.maquilaCost || maquilaCost,
    }
}

export function validationSchema() {
    return Yup.object({
        quantityTons: Yup.number().typeError("Ingrese un valor númerico").required("Ingrese una cantidad de toneladas").moreThan(0, "La cantidad de toneladas debe ser mayor a cero"),
        packagingTypeId: Yup.string().required("Selecciones un tipo de empaque"),
        maquilaCost: Yup.string().required("Ingrese un valor de maquila"),
        profitability: Yup.number().typeError("Ingrese un valor númerico").required("Ingrese un valor de rentabilidad").positive("El valor de rentabilidad debe ser un número positivo"),
        discount: Yup.number().typeError("Ingrese un valor númerico").required("Ingrese un valor de descuento").min(0, "El valor del descuento debe ser un número positivo").max(100, "El valor del descuento debe ser menor a 100"), //.positive("El valor del descuento debe ser un número positivo"),
        flete: Yup.number().typeError("Ingrese un valor númerico").required("Ingrese un valor de flete por tonelada").min(0, "El valor del flete debe ser un número positivo"),
    });
}