import React from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import RenderColumns from "../common/RenderColumns";
import SectionContainer from "../common/SectionContainer";
import { CustomPaper } from "../common/CustomPaper";
import { UnlabeledTextField } from "../common/UnlabeledTextField";
import { typeOfMixes } from "../../../../utils/constants";

export default function NutrientsSection({ elements, mixName, show, mixType, getTotalCompositionByElements, totalQuantityFertilizer, columnsWidth }) {
    return (
        <SectionContainer>
            <Grid item xs={show ? 3 : 8}>
                <TableContainer component={CustomPaper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        Nombre mezcla
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className="height-row">
                                <TableCell>
                                    <Typography>
                                        <strong>{mixName ?? ""}</strong>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {show && (
                <Grid item xs={7}>
                    <TableContainer component={CustomPaper}>
                        <Table className="table-min-width">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        className="textAlignCenter"
                                        colSpan={elements.length}
                                    >
                                        <Typography>
                                            NUTRIENTES (Kg/ha)
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="height-row">
                                    <RenderColumns
                                        elements={elements}
                                        Component={ElementItemInput}
                                        getTotalCompositionByElements={getTotalCompositionByElements}
                                        totalQuantityFertilizer={totalQuantityFertilizer}
                                        mixType={mixType}
                                        columnsWidth={columnsWidth}
                                    />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            <Grid item xs={show ? 2 : 4}>

            </Grid>
        </SectionContainer>
    );
}

const ElementItemInput = ({ element, mixType, getTotalCompositionByElements, totalQuantityFertilizer, width }) => {
    const getValue = () => {
        let result = 0;

        if (mixType?.id === typeOfMixes.byGrade) {
            if (element?.value) {
                result = Number(element.value) * 10;
            }
            if (isNaN(result)) {
                result = 0;
            }
            if (!isFinite(result)) {
                result = 0;
            }
        } else if (mixType?.id === typeOfMixes.byFertilizer) {
            const total = getTotalCompositionByElements(element);
            result = ((total * 1000) / totalQuantityFertilizer) || 0;
        }

        return (result === 0) ? result : parseFloat(result).toFixed(1);
    }

    return (
        <TableCell
            sx={{
                width: width ?? "auto",
            }}
        >
            <UnlabeledTextField
                type="number"
                InputProps={{
                    readOnly: true,
                    className: "mixesNotEditBtn",
                }}
                size="small"
                value={getValue()}
            />
        </TableCell>
    );
}
