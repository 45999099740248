import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ThemeProvider as ThemeProvideMuiV5 } from "@mui/material";
import NotificationBar from './components/Controls/NotificationBar';
import { MainContextProvider } from "./components/Contextos/MainContext";
import { themeMuiV5 } from "./mui.theme.config";
import MenuNavegacionProvider from "./components/Contextos/MenuNavegacionContext";
import { register as registerServiceWorker } from './serviceWorkerRegistration';

/*
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

const queryClient = new QueryClient({
  defaultOptions:{    
    queries:{
      refetchOnWindowFocus:false,
      cacheTime: 60 * 60 * 48,
      staleTime: 60 * 60 * 48,
    }
  }
});
*/

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
  <BrowserRouter basename={baseUrl}>
    <ThemeProvideMuiV5 theme={themeMuiV5}>
      <NotificationBar>
        <MainContextProvider>
          <MenuNavegacionProvider>
            <App />            
          </MenuNavegacionProvider>
        </MainContextProvider>
      </NotificationBar>
    </ThemeProvideMuiV5>
  </BrowserRouter>
);

registerServiceWorker();
