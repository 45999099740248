import React from 'react';
import Section from "./Section";
import { Divider } from "@mui/material";

export default function ListSection({ handleClose, level, updateState, listMenu }) {

    if (!listMenu) return null;

    return listMenu.map((option, index, array) => {
        return (
            <React.Fragment
                key={option.id}
            >
                <Section
                    option={option}
                    handleClose={handleClose}
                    level={level}
                    updateState={updateState}
                />
                {(index !== (array.length - 1) && level === 0) && <Divider />}
            </React.Fragment>
        )
    });
}