import api from "../axios/axios";
import { ENV } from "../utils/constants";
import md5 from "md5";

export const createNewRegister = async function ({ model, data }) {
    //Se genera una cola de los elementos que se deben almacenar en base de datos
    //Se genera un id local que se anexa a la consulta, en caso de requerirse
    //Se genera un tag, con los datos de modelo y parametros, el tag se genera con estos datos con la finalidad de almacenar elementos no repetidos    
    try {
        if(localStorage.getItem("ONLINE") === "false")
            throw new Error('OFFLINE');

        const response = await api.post(ENV.API_ROUTES.ENTITY.CREATE, { model, data });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        //Si hay un error en la comunicación
        let res = localStorage.getItem("UPLOAD_QUEUE");
        let uploadQueue = [];
        if(res){
            uploadQueue = JSON.parse(res);            
        }

        let uid = (new Date()).getTime();
        uploadQueue.push({url:ENV.API_ROUTES.ENTITY.CREATE, model, data, uid});
        localStorage.setItem("UPLOAD_QUEUE", JSON.stringify(uploadQueue));
        //Se genera un id local, para continuidad del proceso        
        return {
            "data":{
                "message": "Registro almacenado, en memoría local, pendiente de recuperar conexión",
                "data": [{"id": uid}]
            }
        };

        //throw error;
    }
}

export const updateRegister = async function ({ model, id, data }) {
    try {
        const response = await api.post(ENV.API_ROUTES.ENTITY.UPDATE, { model, id, data });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateRegisterMix = async function ({ model, id, data }) {
    try {
        if(localStorage.getItem("ONLINE") === "false")
            throw new Error('OFFLINE');

        const response = await api.post(ENV.API_ROUTES.ENTITY.UPDATE, { model, id, data });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        let res = localStorage.getItem("UPLOAD_QUEUE");
        let uploadQueue = [];
        if(res){
            uploadQueue = JSON.parse(res);            
        }

        let uid = (new Date()).getTime();
        uploadQueue.push({url:ENV.API_ROUTES.ENTITY.UPDATE, model, data, uid, id});
        localStorage.setItem("UPLOAD_QUEUE", JSON.stringify(uploadQueue));
        //Se genera un id local, para continuidad del proceso        
        return {
            "data":{
                "message": "Registro actualizado",
                "data": [data]
            }
        };

        //throw error;
    }
}

export const loadFullData = async function ({ model, column = "name", conditions = {} }) {
    const hash = md5("loadFullData"+model+column+JSON.stringify(conditions));

    try {
        const response = await api.post(ENV.API_ROUTES.AUTOCOMPLETE.LOAD_FULL_DATA, { model, column, conditions });
        localStorage.setItem(hash, JSON.stringify(response));
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {

        let res = localStorage.getItem(hash);
        if(res){
            return JSON.parse(res);
        }else{
            throw error;
        }        
    }
}

export const getData = async ({ model, idSearch, column }) => {
    const hash = md5(model+idSearch);

    try {        
        const response = await api.post(ENV.API_ROUTES.ENTITY.GET, { model, idSearch, column });

        if(model != ENV.CLIENT_ROUTES.MIXES.MODEL){
            localStorage.setItem(hash, JSON.stringify(response));
        }
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {        

        console.log("Error de red");
        let res = localStorage.getItem(hash);
        if(res){
            console.log("Se encontraron datos");
            return JSON.parse(res);
        }else{
            //throw error;
            //Bajo cualquier escenario de conexión tenemos navegación
            console.log("No se encontraron datos ni en cache ni en red")
            return {data:{message:"Datos disponibles",data:[]}}
        } 
    }
}

export const download = async function ({ model, year, month }) {
    try {
        const response = await api.post(ENV.API_ROUTES.ENTITY.DOWNLOAD_BY_CREATED_FIELD,
            { model, year, month },
            { responseType: "blob" });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}