import React from "react"
import ItemSection from "./ItemSection"
import { Collapse, List } from "@mui/material"
import ListSection from "./ListSection";

export default function MenuSectionMemo({ option, handleClose, level, updateState }) {

    if ((!option?.childrens || option?.childrens.length === 0)) {
        return (
            <List>
                <ItemSection
                    level={level}
                    option={option}
                    handleClose={handleClose}
                    classes={option?.classes}
                    updateState={updateState}
                />
            </List>
        );
    }

    return (
        <List>
            <ItemSection
                option={option}
                handleClose={handleClose}
                classes={option?.classes}
                level={level}
                updateState={updateState}
            />

            <Collapse in={option.open}>
                <ListSection
                    listMenu={option.childrens}
                    handleClose={handleClose}
                    level={level + 1}
                    classes={option?.classes}
                    updateState={updateState}
                />
            </Collapse>
        </List>
    );
}