import api from "../axios/axios";

// Enviar cotización al cliente
export const sendQuoteToCustomer = async function ({ quoteId }) {
    try {
        const response = await api.post("api/quotes/sendToCustomer", { quoteId });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Descargar pdf de la cotización
export const downloadQuotePDF = async function ({ quoteId }) {
    try {
        const response = await api.post("api/quotes/downloadPDF", { quoteId }, { responseType: "blob" });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Validar si la cotización requiere aprobación
export const validateApprovalRequired = async function ({ quoteId }) {
    try {
        const response = await api.post("api/quotes/approvalRequired", { quoteId });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Enviar cotización a aprobación
export const sendQuoteForApproval = async function ({ quoteId, approvalUserEmail, approvalUserId }) {
    try {
        const response = await api.post("api/quotes/sendForApproval", { quoteId, approvalUserEmail, approvalUserId });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Copiar una cotización
export const copyQuote = async function ({ quoteId }) {
    try {
        const response = await api.post("api/quotes/copyQuote", { quoteId });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Post para aprobación de cotización, fuente de acceso a front, mail
export const approvalPublicQuote = async function ({ idQuoteString, mailUserString, idRequestString }) {
    try {
        const response = await api.post("api/public/quotes/approval", { idQuoteString, mailUserString, idRequestString });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Post para aprobación de cotización, fuente de acceso a front, mail
export const rejectPublicQuote = async function ({ idQuoteString, mailUserString, idRequestString }) {
    try {
        const response = await api.post("api/public/quotes/reject", { idQuoteString, mailUserString, idRequestString });
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}