/**
 * Retorna la fecha actual en formato ISO yyyy-mm-dd 
 * @returns fecha actual en formato yyyy-mm-dd
 */
export function getCurrentDateInFormatISO() {
    const date = new Date();
    const offsetMs = date.getTimezoneOffset() * 60 * 1000; // En milisegundos.
    const msLocal = date.getTime() - offsetMs;
    const currentDateLocal = new Date(msLocal);
    const localDateFormatISO = currentDateLocal.toISOString().slice(0, 10);
    return localDateFormatISO;
}

/**
 * Retorna un array con los años disponibles desde un años de inicio dado (por defecto 2020), hasta el año actual.
 * Incluye el año inicial dado. 
 * @param {Number} [startYear=2020] - Año de inicio
 * @returns {Array<Number>} Array con los años desde la fecha de inicio hasta el año actual.
 */
export function getArrayOfYears(startYear = 2020) {
    let currentYear = new Date().getFullYear();
    let years = [];
    if (startYear > currentYear) return years;

    while (startYear <= currentYear) {
        years.push(startYear++)
    }
    return years;
}

/**
 * Lista con los nombres de los meses.
 */
export const monthOptions = [
    {
        id: 1,
        name: "Enero"
    },
    {
        id: 2,
        name: "Febrero"
    },
    {
        id: 3,
        name: "Marzo"
    },
    {
        id: 4,
        name: "Abril"
    },
    {
        id: 5,
        name: "Mayo"
    },
    {
        id: 6,
        name: "Junio"
    },
    {
        id: 7,
        name: "Julio"
    },
    {
        id: 8,
        name: "Agosto"
    },
    {
        id: 9,
        name: "Septiembre"
    },
    {
        id: 10,
        name: "Octubre"
    },
    {
        id: 11,
        name: "Noviembre"
    },
    {
        id: 12,
        name: "Diciembre"
    },
];