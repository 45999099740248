import React, { useState } from "react";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import { Clear, Done } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { green, red } from "@mui/material/colors";

import ApprovalRequestComments from "./ApprovalRequestComments";
import BasicModal from "../../common/BasicModal/BasicModal";
import { quoteStatus, } from "../../../utils/constants";
import { useMainContext } from "../../../hooks/useMainContext";
import { sendQuoteToCustomer } from "../../../apiCore/quotes";
import { approvalQuote } from "../../../apiCore/approvalRequest";

export default function ApprovalRequestItem({ approvalRequest, onReload, isRTCJunior, onClose, onReloadDatatable }) {
    const { quoteId, quoteStatusId, id, quoteStatusName, fullName, userRoleName, approvalUserId, comment } = approvalRequest || {};
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { user } = useMainContext() || {};


    const onOpenCloseModal = () => setOpen(prevState => !prevState);

    const iconApprovalStyleSx = {
        ...((quoteStatusId === quoteStatus.aprobada) && {
            color: green[500],
            '&:hover': {
                color: green[700],
            },
        }),
    };
    const iconRejectStyleSx = {
        ...((quoteStatusId === quoteStatus.rechazada) && {
            color: red[500],
            '&:hover': {
                color: red[700],
            },
        }),
    };

    // Enviar cotización al cliente
    const sendToCustomer = async () => {
        try {
            enqueueSnackbar("Enviando cotización al cliente", { variant: "info" });

            const response = await sendQuoteToCustomer({ quoteId });

            const { error, message } = response?.data || {};

            if (error) throw { response };

            if (message) enqueueSnackbar(message, { variant: "success" });

            if (onReload instanceof Function) onReload();

            if (onReloadDatatable instanceof Function) onReloadDatatable();

            if (onClose instanceof Function) onClose();

        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al envíar cotización al cliente", { variant: "error" });
            }
        }
    }

    // Aprobar cotización.
    const handleApproval = async () => {
        try {
            setIsLoading(true);
            const response = await approvalQuote({
                approvalRequestId: id,
                quoteId,
            });

            const { error, message } = response?.data || {};

            if (error) throw { response };

            if (message) enqueueSnackbar(message, { variant: "success" });

            // Enviar la cotización al cliente
            await sendToCustomer();
        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" })
            }
            else {
                enqueueSnackbar("Error al aprobar la cotización", { variant: "error" });
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (

        <React.Fragment>
            <Grid item xs={1}>
                {quoteId}
            </Grid>
            <Grid item xs={2}>
                {quoteStatusName}
            </Grid>
            <Grid item xs={isRTCJunior ? 3 : 2}>
                {fullName}
            </Grid>
            <Grid item xs={2}>
                {userRoleName}
            </Grid>
            <Grid item xs={isRTCJunior ? 4 : 3}>
                {comment}
            </Grid>

            {(!isRTCJunior) && (
                <Grid item xs={2}>
                    <IconButton
                        onClick={handleApproval}
                        disabled={(quoteStatusId !== quoteStatus.pendiente) || (approvalUserId !== user?.id) || (isLoading)}
                    >
                        <Done sx={iconApprovalStyleSx} />
                        {isLoading && (
                            <CircularProgress
                                size={35}
                                sx={{
                                    color: green[500],
                                    position: "absolute",
                                    zIndex: 1,
                                }}
                            />
                        )}
                    </IconButton>
                    <IconButton
                        aria-label="reject quote"
                        onClick={onOpenCloseModal}
                        disabled={(quoteStatusId === quoteStatus.rechazada) || (approvalUserId !== user?.id) || (quoteStatusId === quoteStatus.aprobada) || (isLoading)}
                    >
                        <Clear sx={iconRejectStyleSx} />
                    </IconButton>
                </Grid>
            )}

            {/* Modal para ingresar el comentario de rechazo de la cotización */}
            <BasicModal
                open={open}
                onClose={() => setOpen(false)}
                title="Rechazar cotización"
            >
                <ApprovalRequestComments
                    quoteId={quoteId}
                    approvalRequestId={id}
                    onReload={onReload}
                    comment={comment}
                    onClose={() => setOpen(false)}
                    onReloadDatatable={onReloadDatatable}
                    onCloseApprovalListModal={onClose}
                />
            </BasicModal>
        </React.Fragment>
    )
}
