import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, MobileStepper, Paper, Typography, Button, Tooltip, ButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import QuoterForm from "../QuoteForm/QuoterForm";
import Mixes from "../../Mixes/Mixes/Mixes";
import QuoteSummary from "../QuoteSummary/QuoteSummary";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { getData } from "../../../apiCore/entity";
import { ENV, quoteStatus } from "../../../utils/constants";
import { useSnackbar } from "notistack";
import { hideLoader, showLoader } from "../../Controls/Loader";
import ConfirmModal from "../../Controls/ConfirmModal";
import { downloadQuotePDF, sendQuoteForApproval, sendQuoteToCustomer, validateApprovalRequired } from "../../../apiCore/quotes";
import { useMainContext } from "../../../hooks/useMainContext";

import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';

const buttonStyled = {
    border: "none",
    "&:hover": {
        border: "none",
    },
    "&.Mui-disabled": {
        border: "none",
    }
};

const stepsList = ["Crear cotización", "Mezclas", "Resumen cotización"];

export default function QuoteStepper({ quote: _quote, onClose, onReload }) {
    const { user } = useMainContext() || {};
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false);

    const maxSteps = stepsList.length;
    const [quote, setQuote] = useState(null);

    // Controlar si se debe de recargar los datos al cerrar el modal.
    const reloading = useRef(false);

    const [ onlineOperation, setOnlineOperation ] = useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClose = () => {
        if (onClose instanceof Function) onClose();
        handleReloading();
    }
    const setReloading = () => {
        reloading.current = true;
    }

    const handleReloading = () => {
        if (reloading.current && (onReload instanceof Function)) {
            onReload();
        }
        reloading.current = false;
    }

    const onKeyUpHandle = (e) => {
        // Validar si presiono la tecla Escape.
        if (e?.code === "Escape" || e?.key === "Escape") {
            handleReloading();
        }
    }

    // Valida si una cotización esta disponible para editar según el estado.
    const isQuoteAvailableToEdit = useMemo(() => {
        // Si la cotización no existe significa que se esta creando.
        if (!_quote && !quote) return true;

        const { quoteStatusId } = quote || {};

        // La cotización solo se puede editar cuando tiene un estado de en elaboración o rechazada.
        return quoteStatusId === quoteStatus.enElaboracion || quoteStatusId === quoteStatus.rechazada;
    }, [quote]);

    // Validar si la persona que consulta la cotización es el mismo usuario que creo la cotización
    const isQuoteOwner = useMemo(() => {
        // Si la cotización no existe significa que se esta creando.
        if (!_quote && !quote) return true;

        const { userId: quoteUserId } = quote || {};
        const { id: userId } = user || {};

        if (!quoteUserId || !userId) return false;

        return quoteUserId === userId;
    }, [user, quote]);

    // Validar la edición de la cotización.
    // Para editar la cotización se requiere que tenga un estado de En Elaboración o Rechazada y la persona que la esta 
    // consultando sea el que creo la cotización.
    const availableEditQuote = useMemo(() => {
        return isQuoteAvailableToEdit && isQuoteOwner;
    }, [isQuoteAvailableToEdit, isQuoteOwner])

    useEffect(() => {
        document.addEventListener("keyup", onKeyUpHandle)

        // Validar el tipo de dato que es _quote cuando se pasa como props.
        if (_quote) {
            if (typeof _quote === "number") {
                (async () => {
                    try {
                        setIsLoading(true);
                        showLoader();
                        const response = await getData({
                            model: ENV.CLIENT_ROUTES.QUOTES.MODEL,
                            idSearch: _quote,
                            column: "id",
                        });

                        const { error, data } = response?.data || {};

                        if (error) throw { error: response };

                        if (data) {
                            let customer = {};
                            let deadline = {};
                            const { customerId, deadlineId } = data[0] || {};
                            if (customerId) {
                                const resCustomer = await getData({
                                    model: ENV.CLIENT_ROUTES.CUSTOMERS.MODEL,
                                    idSearch: customerId,
                                    column: "id",
                                });
                                const { data } = resCustomer?.data || {};
                                customer = data[0];
                            }

                            if (deadlineId) {
                                const resDeadline = await getData({
                                    model: ENV.CLIENT_ROUTES.DEADLINE.MODEL,
                                    idSearch: deadlineId,
                                    column: "id",
                                });
                                const { data } = resDeadline?.data || {};
                                deadline = data[0];
                            }
                            setQuote({ ...data[0], deadline, customer });
                        };
                        hideLoader();
                        setIsLoading(false);

                    } catch (err) {
                        const { error } = err?.response?.data || {};
                        if (error) {
                            enqueueSnackbar(error, { variant: "error" });
                        } else {
                            enqueueSnackbar("Error al cargar los datos de la cotización", { variant: "error" });
                        }
                        hideLoader();
                        setIsLoading(false);
                        setIsError(true);
                    }
                })();
            }

            if (_quote instanceof Object) {
                setQuote(_quote);
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }

        // Remover evento.
        return () => document.removeEventListener("keyup", onKeyUpHandle);
    }, []);

    useEffect(()=>{
        if(activeStep == 1){
            //El siguiente estado del formulario es abierto
            //Se verifica el estado de conexión, en este momento para en caso de no tener conexión se activa el modo offline
            if(window.navigator.onLine){
                localStorage.setItem("ONLINE", true);
                setOnlineOperation(true)
            }else{
                localStorage.setItem("ONLINE", false);
                setOnlineOperation(false)
            }
        }
    },[activeStep])

    if (isLoading) return null;

    return (
        <Box
            className="quoteStepperContainer">
            <Paper
                className="quoteStepperHead"
                square
                elevation={0}>
                {onClose && (
                    <IconButton
                        className="whiteLetter"
                        aria-label="close"
                        edge="start"
                        onClick={handleClose}>
                        <Close />
                    </IconButton>
                )}
                <Typography variant="h5">
                    {(_quote && activeStep === 0) ? "Actualizar cotización" : stepsList[activeStep]}
                </Typography>
            </Paper>

            {isError && (
                <Typography variant="h5" className="error">Error al cargar los datos de la cotización</Typography>
            )}

            {(!isError && !isLoading) && (
                <React.Fragment>
                    <Box
                        className="quoteStepperContent panel">
                        {activeStep === 0 && (
                            <QuoterForm
                                nextStep={handleNext}
                                quote={quote}
                                setQuote={setQuote}
                                setReloading={setReloading}
                                availableEdit={availableEditQuote}
                            />
                        )}
                        {activeStep === 1 && (
                            <Mixes
                                quote={quote}
                                setQuote={setQuote}
                                setReloading={setReloading}
                                availableEdit={availableEditQuote}
                            />
                        )}
                        {activeStep === 2 && 
                            <QuoteSummary quote={quote} />
                        }
                    </Box>
                    <MobileStepper
                        variant="dots"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={(
                            <NextButton
                                onlineOperation={onlineOperation}
                                activeStep={activeStep}
                                nextStep={handleNext}
                                onClose={onClose}
                                onReload={onReload}
                                quote={quote}
                                disabled={((activeStep === (maxSteps - 1)) || (quote?.mixes?.length === 0))}
                                availableEdit={availableEditQuote}
                                setQuote={setQuote}
                            />
                        )}
                        backButton={
                            <Button
                                color="secondary"
                                onClick={handleBack}
                                disabled={(activeStep === 0) || !onlineOperation}
                                sx={buttonStyled}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Atrás
                            </Button>
                        }
                    />
                </React.Fragment>
            )}
        </Box >
    );
}

const IconButtonDirection = () => {
    const theme = useTheme();
    return theme.direction === "rtl" ? (<KeyboardArrowLeft />) : (<KeyboardArrowRight />)
}

const NextButton = ({ activeStep, nextStep, onClose, onReload, disabled, quote, availableEdit, setQuote, onlineOperation }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [allowDownload, setAllowDownload] = useState(false);
    const [open, setOpen] = useState(false);
    const [approvalUser, setApprovalUser] = useState(null);
    const [wasSentForApprovalFlag, setWasSentForApprovalFlag] = useState(false)    

    const { id: quoteId } = quote || {};

    const sendToCustomer = async () => {
        try {
            enqueueSnackbar("Enviando cotización al cliente", { variant: "info" });

            const response = await sendQuoteToCustomer({ quoteId });

            const { error, message } = response?.data || {};

            if (error) throw { response };

            if (message) enqueueSnackbar(message, { variant: "success" });

            if (onClose instanceof Function) onClose();
            if (onReload instanceof Function) onReload();

            enqueueSnackbar("Proceso finalizado", { variant: "success" });

        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al envíar cotización al cliente", { variant: "error" });
            }
        }
    }

    const downloadPdf = async () => {
        try {
            showLoader();
            const response = await downloadQuotePDF({ quoteId });

            if (response?.data?.error) throw { response };

            if (response?.data) {
                const tmpFileUrl = window.URL.createObjectURL(response.data);
                let alink = document.createElement("a");
                alink.href = tmpFileUrl;
                alink.download = `cotizacion_${quoteId}.pdf`;
                alink.click();
            }

            await sendToCustomer();
        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al consultar el PDF solicitado", { variant: "error" });
            }
        } finally {
            hideLoader();
        }
    }

    const downloadPdfNoSend = async () => {
        try {
            showLoader();
            const response = await downloadQuotePDF({ quoteId });

            if (response?.data?.error) throw { response };

            if (response?.data) {
                const tmpFileUrl = window.URL.createObjectURL(response.data);
                let alink = document.createElement("a");
                alink.href = tmpFileUrl;
                alink.download = `cotizacion_${quoteId}.pdf`;
                alink.click();
            }

        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al consultar el PDF solicitado", { variant: "error" });
            }
        } finally {
            hideLoader();
        }
    }

    const approvalRequired = async () => {
        try {
            showLoader();
            const response = await validateApprovalRequired({ quoteId });

            const { error, approvalRequired, approvalUserEmail, approvalUserName, approvalUserId } = response?.data || {};

            if (error) throw { response };

            if (approvalRequired) {
                setOpen(true);
                setApprovalUser({ approvalUserEmail, approvalUserName, approvalUserId });
            } else {
                // Actualizar estado de la cotización
                
                enqueueSnackbar("La cotización actual no requiere aprobación, cotización aprobada", { variant: "success" });
                setQuote(prevQuote => ({ ...prevQuote, quoteStatusId: quoteStatus.aprobada }))
                setAllowDownload(true);
                setWasSentForApprovalFlag(true);
            }

        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al validar si la cotización requiere aprobación", { variant: "error" });
            }
        } finally {
            hideLoader();
        }
    }

    const handleConfirmApprovalRequest = async () => {
        try {
            showLoader();
            const response = await sendQuoteForApproval({
                quoteId,
                approvalUserEmail: approvalUser?.approvalUserEmail,
                approvalUserId: approvalUser?.approvalUserId
            });

            const { error, data, message } = response?.data || {};

            if (error) throw { response };

            if (message) {
                enqueueSnackbar(message, { variant: "success" });
                // Actualizar estado de la cotización
                setQuote(prevQuote => ({ ...prevQuote, quoteStatusId: quoteStatus.pendiente }))
            }
            if (onReload instanceof Function) onReload();
            if (onClose instanceof Function) onClose();
        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al realizar la solicitud de aprobación", { variant: "error" });
            }
        } finally {
            hideLoader();
        }
    }

    const handleClick = async () => {
        if (!availableEdit && !wasSentForApprovalFlag && !allowDownload) {
            if (onClose instanceof Function) onClose();
            return;
        }

        if (!allowDownload) return await approvalRequired();

        await downloadPdf();
    }

    const handleDownloadClick = async () => {
        if (!availableEdit && !wasSentForApprovalFlag && !allowDownload) {
            if (onClose instanceof Function) onClose();
            return;
        }

        if (!allowDownload) return await approvalRequired();

        await downloadPdfNoSend();
    }    

    useEffect(() => {
        setAllowDownload(false);
        //Si el paso es 1, se define el estado de la conexón        
    }, [activeStep]);

    if (activeStep === 0) return (
        <Button
            color="secondary"
            form="quote-form"
            type="submit"
            sx={buttonStyled}>
            Mezclas
            <IconButtonDirection />
        </Button>
    );

    if (activeStep === 2) return (
        <React.Fragment>
            <ButtonGroup variant="text">
                <Button                
                    color="secondary"
                    variant="outlined"
                    sx={buttonStyled}
                    onClick={onClose}
                    disabled={!quote?.mixes?.every(mix => mix?.quantityTons > 0)}>
                    {allowDownload ? "Cerrar Sin Envió" : "Guardar Borrador"}
                    <SaveIcon className="marginButtonText"/>
                </Button>
                {allowDownload && 
                    <Button
                        color="secondary"
                        variant="outlined"
                        sx={buttonStyled}
                        onClick={handleDownloadClick}
                        disabled={!quote?.mixes?.every(mix => mix?.quantityTons > 0)}>
                            Descargar Cotización
                        <DownloadIcon className="marginButtonText"/>
                    </Button>
                }
                <Button
                    color="secondary"
                    variant="outlined"
                    sx={buttonStyled}
                    onClick={handleClick}
                    disabled={!quote?.mixes?.every(mix => mix?.quantityTons > 0)}>
                    {allowDownload ? "Descargar y Enviar PDF" : "Confirmar Cotización"}
                    {allowDownload ? <SendIcon className="marginButtonText"/> : <CheckIcon className="marginButtonText"/>}                    
                </Button>
            </ButtonGroup>

            <ConfirmModal
                color="secondary"
                open={open}
                setOpen={setOpen}
                titleMessage="Solicitar aprobación"
                confirmMessage={`La cotización requiere aprobación de ${approvalUser?.approvalUserName} (${approvalUser?.approvalUserEmail}). ¿Desea continuar?`}
                confirmHandler={handleConfirmApprovalRequest}
                confirmButtonText="Continuar"/>
        </React.Fragment>
    );

    if (onlineOperation){
        return (<Button            
                    color="secondary"
                    onClick={nextStep}
                    disabled={!quote?.mixes?.every(mix => mix?.quantityTons > 0)}
                    sx={buttonStyled}>
                    Siguiente
                    <IconButtonDirection />
                </Button>)
    }else{
        return (<Tooltip title="Cotización offline, será almacenada en local y se notificará para su sincronización. puedes cerrar ese modal y sincronizar posteriormente">
                    <Button onClick={onClose}>
                        Operación offline, cerrar y guardar cotización
                    </Button>
                </Tooltip>)
    }
}