import React, { useEffect } from "react";
import BasicMenu from "../common/BasicMenu/BasicMenu";
import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from '@mui/icons-material/Groups';
import { useLocation } from "react-router-dom";
import { useMainContext } from "../../hooks/useMainContext";
import { ENV, roles } from "../../utils/constants";
import { ManageAccounts } from "@mui/icons-material";
import CustomLink from "../common/CustomLink/CustomLink";

export default function UserMenu(props) {
    const { open, anchorEl, onClose, user } = props;

    const { logout } = useMainContext();
    const location = useLocation();

    const handleCloseSession = async () => {
        onClose();
        // Cerrar la sesión desde el main context.
        await logout();
    };

    useEffect(() => {
        return () => onClose();
    }, [location]);

    return (
        <BasicMenu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <CustomLink to="#" >
                <MenuItem onClick={onClose}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{user?.email ? user.email.toLowerCase() : ""}</ListItemText>
                </MenuItem>
            </CustomLink>
            <div>
                <MenuItem>
                    <ListItemIcon>
                        <BadgeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{user?.roleId == roles.administrador ? "Administrador":user?.roleId == roles.aprobador ? "Aprobador":user?.roleId == roles.RTCSenior ? "Jefe Regional":user?.roleId == roles.RTCJunior ? "RTC":"--"}</ListItemText>
                </MenuItem>
            </div>
            {user?.roleId === roles.administrador && <Divider />}
            {user?.roleId === roles.administrador &&
                <CustomLink onClick={onClose} to={ENV.CLIENT_ROUTES.USERS.PATH} >
                    <MenuItem >
                        <ListItemIcon>
                            <AdminPanelSettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Administración de usuarios</ListItemText>
                    </MenuItem>
                </CustomLink>
            }
            {user?.roleId === roles.administrador && <Divider />}
            {user?.roleId === roles.administrador &&
                <CustomLink onClick={onClose} to={ENV.CLIENT_ROUTES.GROUPS.PATH} >
                    <MenuItem >
                        <ListItemIcon>
                            <GroupsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Administración de grupos</ListItemText>
                    </MenuItem>
                </CustomLink>
            }
            {user?.roleId === roles.administrador && <Divider />}
            {user?.roleId === roles.administrador &&
                <CustomLink onClick={onClose} to={ENV.CLIENT_ROUTES.ACCESSCONTROL.PATH} >
                    <MenuItem >
                        <ListItemIcon>
                            <ManageAccounts fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Administración de accesos</ListItemText>
                    </MenuItem>
                </CustomLink>
            }
            <Divider />
            <MenuItem onClick={handleCloseSession}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Cerrar Sesión</ListItemText>
            </MenuItem>
        </BasicMenu>
    )
}
