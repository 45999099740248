import React, { useState } from "react";
import { Box, Grid, styled } from "@mui/material";
import FertilizersPriceUpdate from "../Fertilizers/FertilizersPriceUpdate/FertilizersPriceUpdate";
import FertilizersWithoutComposition from "../Fertilizers/FertilizersWithoutComposition/FertilizersWithoutComposition";
import BasicColumnChart from "../Charts/BasicColumnChart";

const GridItem = styled(Grid)(() => ({
    overflow: "hidden",
}));

const BoxItem = styled(Box)(() => ({
    border: "1px solid #bbb",
    overflow: "auto",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    borderRadius: "4px",
}));

export default function Dashboard() {
    // Validar si las tablas contienen datos.
    const [fertilizersWithoutCompositionHasData, setFertilizersWithoutCompositionHasData] = useState(true);
    const [fertilizersPriceUpdateHasData, setfertilizersPriceUpdateHasData] = useState(true);

    return (
        <Box
            padding={1}
            className="backgroundPlain">
            <Grid
                container
                rowGap={2}
                width="100%"
                columnSpacing={{ xs: 1, sm: 2 }}>
                {fertilizersPriceUpdateHasData && (
                    <GridItem
                        item
                        xs={12}
                        md={fertilizersWithoutCompositionHasData ? 6 : 12}>
                        <BoxItem>
                            <FertilizersPriceUpdate hasData={(result) => setfertilizersPriceUpdateHasData(result)} />
                        </BoxItem>
                    </GridItem>
                )}

                {fertilizersWithoutCompositionHasData && (
                    <GridItem
                        item
                        xs={12}
                        md={fertilizersPriceUpdateHasData ? 6 : 12}>
                        <BoxItem>
                            <FertilizersWithoutComposition hasData={(result) => setFertilizersWithoutCompositionHasData(result)} />
                        </BoxItem>
                    </GridItem>
                )}

                <GridItem
                    item
                    xs={12}
                    md={6}>
                    <BoxItem>
                        <BasicColumnChart
                            title="Cotizaciones aprobadas y logradas"
                            model="quotesByStateMonthYearAndOwnerIndicator"
                            xAxisColumnNameForCategories="month"
                            order={{
                                columnName: "monthNumber",
                                direction: "ASC",
                            }}
                            series={["quoteStatusName"]}
                            columnNameForValues="numberOfQuotes"
                            tooltip={{ valueSuffix: " cotizaciones" }}
                            yAxisTitle="Número de cotizaciones"
                            filterFields={[
                                { label: "Año", columnName: "year" },
                                { label: "Usuario", columnName: "fullNameUser" },
                            ]}/>
                    </BoxItem>
                </GridItem>

                <GridItem
                    item
                    xs={12}
                    md={6}>
                    <BoxItem>
                        <BasicColumnChart
                            title="Cotizaciones realizadas vs aprobadas vs rechazadas por mes"
                            model="quotesByAllStateMonthYearAndOwnerIndicator"
                            xAxisColumnNameForCategories="month"
                            order={{ columnName: "monthNumber", direction: "ASC" }}
                            series={["quoteStatusName"]}
                            columnNameForValues="numberOfQuotes"
                            tooltip={{ valueSuffix: " cotizaciones" }}
                            yAxisTitle="Número de cotizaciones"
                            filterFields={[
                                { label: "Año", columnName: "year" },
                                { label: "Usuario", columnName: "fullNameUser" },
                            ]}/>
                    </BoxItem>
                </GridItem>


                <GridItem
                    item
                    xs={12}>
                    <BoxItem>
                        <BasicColumnChart
                            title="Cotizaciones realizadas por RTC por mes"
                            model="quotesByStateMonthYearAndOwnerIndicator"
                            xAxisColumnNameForCategories="month"
                            order={{ columnName: "monthNumber", direction: "ASC" }}
                            series={["fullNameUser"]}
                            columnNameForValues="numberOfQuotes"
                            tooltip={{ valueSuffix: " cotizaciones" }}
                            yAxisTitle="Número de cotizaciones"
                            filterFields={[
                                { label: "Año", columnName: "year" },
                                { label: "Mes", columnName: "month" },
                            ]}/>
                    </BoxItem>
                </GridItem>


                <GridItem
                    item
                    xs={12}>
                    <BoxItem>
                        <BasicColumnChart
                            title="Materiales cotizados por volumen"
                            model="quantityOfFertilizerByMonthAndYearIndicator"
                            xAxisColumnNameForCategories="month"
                            order={{
                                columnName: "monthNumber",
                                direction: "ASC",
                            }}
                            series={["fertilizerName"]}
                            columnNameForValues="quantityOfFertilizer"
                            tooltip={{ valueSuffix: " Kg" }}
                            yAxisTitle="Fertilizante (Kg)"
                            filterFields={[
                                { label: "Año", columnName: "year" },
                                { label: "Fertilizante", columnName: "fertilizerName" },
                                { label: "Mes", columnName: "month" },
                            ]}/>
                    </BoxItem>
                </GridItem>

                <GridItem
                    item
                    xs={12}>
                    <BoxItem>
                        <BasicColumnChart
                            title="Eficiencia de las cotizaciones"
                            model="efficiencyPercentageQuotes"
                            xAxisColumnNameForCategories="month"
                            order={{
                                columnName: "monthNumber",
                                direction: "ASC",
                            }}
                            // series={["month"]}
                            series="Porcentaje de eficiencia"
                            columnNameForValues="efficiencyPercentage"
                            tooltip={{ valueSuffix: " %" }}
                            yAxisTitle="% de eficiencia"
                            filterFields={[
                                { label: "Año", columnName: "year" },
                            ]}
                            dataLabelFormat="{y} %"/>
                    </BoxItem>
                </GridItem>

                <GridItem
                    item
                    xs={12}>
                    <BoxItem>
                        <BasicColumnChart
                            title="Inicio de sesión por usuario"
                            model="userActivityTracking"
                            xAxisColumnNameForCategories="fullNameUser"
                            order={{
                                columnName: "monthNumber",
                                direction: "ASC",
                            }}
                            series="Inicio de sessión últimos 30 días"
                            columnNameForValues="numberOfRecords"
                            tooltip={{ valueSuffix: " ingresos" }}
                            yAxisTitle="Número de inicios de sesión"
                            xAxisTitle="Usuario"
                            filterFields={[
                                { label: "Año", columnName: "year" },
                                { label: "Usuario", columnName: "fullNameUser" },
                            ]}/>
                    </BoxItem>
                </GridItem>
            </Grid>
        </Box >
    );
}