import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CurrencyFormat from 'react-currency-format';
import SectionContainer from "../common/SectionContainer";
import RenderColumns from "../common/RenderColumns";
import { UnlabeledTextField } from "../common/UnlabeledTextField";
import { CustomPaper } from "../common/CustomPaper";
import { groupBy } from "../../../../utils/groupBy";

export default function FertiliziersSection({
    elements,
    show,
    composition,
    availableFertilizerList,
    renderFertilizerList,
    addFertilizier,
    handleOnChange,
    deleteFertilizier,
    availableEdit,
    columnsWidth,
    selectedFertilizers,
    fertilizersForCalculateGrade,
    automaticGradeCalculation,
    mixType=null,
    totalQuantityFertilizer=0
}) {
    return (
        <SectionContainer>
            {/* Sección izquierda: adicionar fertilizante */}
            <Grid
                item
                xs={show ? 3 : 8}>
                <TableContainer component={CustomPaper}>
                    <Table className="form-sectio-item-left">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="textAlignCenter"
                                    colSpan={2}
                                >
                                    <Typography>
                                        Fuente
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className="textAlignCenter">
                                        Kg
                                    </Typography>
                                </TableCell>
                                {mixType?.id == 2 &&
                                    <TableCell>
                                        <Typography className="textAlignCenter">
                                            Conv Tn
                                        </Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Listar los fertilizantes para el calculo automatico del grado */}                            
                            <RenderFertilizerForCalculateGradeList
                                availableEdit={availableEdit}
                                deleteFertilizier={deleteFertilizier}
                                handleOnChange={handleOnChange}
                                selectedFertilizers={selectedFertilizers}
                                fertilizersForCalculateGrade={fertilizersForCalculateGrade}
                                Component={FertilizerForCalculateGradeItem}
                                automaticGradeCalculation={automaticGradeCalculation}/>

                            {/* Renderizar los demás fertlizantes */}
                            {renderFertilizerList.map((component => {
                                return (
                                    <TableRow key={component.uuid}
                                        className="fullWidth height-row">
                                        {availableEdit && (
                                            <TableCell
                                                sx={{width: availableEdit ? "5%" : "0%",}}>
                                                <IconButton
                                                    disabled={!availableEdit}
                                                    onClick={() => {
                                                        if (availableEdit) {
                                                            deleteFertilizier(component)
                                                        }
                                                    }}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                        <TableCell
                                            sx={{width: availableEdit ? mixType?.id == 2 ? "50%":"65%" : mixType?.id == 2 ? "60%":"70%"}}>
                                            <CustomAutocomplete
                                                fullWidth
                                                disabled={!availableEdit}
                                                value={component?.fertilizer || ""}
                                                size="small"
                                                options={availableFertilizerList}
                                                nameColumnForLabel="name"
                                                onChange={(newValue) => {
                                                    if (handleOnChange instanceof Function) handleOnChange(component, newValue)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{width: availableEdit ? mixType?.id == 2 ? "15%":"30%" : mixType?.id == 2 ? "20%":"30%"}}>
                                            <UnlabeledTextField
                                                InputProps={{
                                                    className: (availableEdit) ? "mixesEditBtn" : "mixesNotEditBtn",
                                                }}
                                                fullWidth
                                                size="small"
                                                onChange={(e) => {
                                                    handleOnChange(component, e.target.value, "input");
                                                }}
                                                type="number"
                                                value={component.fertilizer?.value || ""}
                                                disabled={!component.fertilizer || !availableEdit}
                                            />
                                        </TableCell>
                                        {mixType?.id == 2 &&
                                            <TableCell
                                                sx={{width: availableEdit ? "15%" : "20%"}}>
                                                <UnlabeledTextField
                                                    InputProps={{className: "mixesNotEditBtn",}}
                                                    fullWidth
                                                    size="small"
                                                    onChange={(e) => handleOnChange(component, e.target.value, "input")}
                                                    type="number"
                                                    value={(parseFloat(component.fertilizer?.value) * (1000 / totalQuantityFertilizer)).toFixed(0) || ""}
                                                    disabled={!component.fertilizer || !availableEdit}
                                                />
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                            }))}
                            
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                {availableEdit && (
                    <Button
                        width={150}
                        startIcon={<AddCircleIcon />}
                        disabled={!availableEdit}
                        variant="contained"
                        onClick={() => {
                            if (availableEdit) {
                                addFertilizier()
                            }
                        }}>
                        <Typography>Adicionar fertilizante</Typography>
                    </Button>
                )}
            </Grid>
            {/* Sección central: elementos */}
            {show && (
                <Grid
                    item
                    xs={7}>
                    <TableContainer component={CustomPaper}>
                        <Table className="table-min-width">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={elements.length}>
                                        <Typography className="textAlignCenter">
                                            Fertilización
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    selectedFertilizers?.map(fertilizer => (
                                        <TableRow key={fertilizer?.id} className="height-row">
                                            <RenderColumns
                                                elements={elements}
                                                Component={ElementsWithComposition}
                                                component={{ fertilizer }}
                                                composition={composition}
                                                columnsWidth={columnsWidth}
                                            />
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {/* Seción derecha: total por fertilizante */}
            <Grid
                item
                xs={show ? 2 : 4}>
                <TableContainer component={CustomPaper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="textAlignCenter">
                                    <Typography>
                                        Precio/tn
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    className="textAlignCenter">
                                    <Typography>
                                        Disponible
                                    </Typography>
                                </TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {selectedFertilizers.map(fertilizer => {
                                const getPricePerTons = () => {
                                    const pricePerKg = fertilizer?.pricePerKg || 0;
                                    var fertilizerValue = fertilizer?.value || 0;

                                    //Se debe evaluar el tipo de mezcla, para deterninar que cantidad utilizar
                                    if(mixType?.id == 2){
                                        fertilizerValue = (parseFloat(fertilizer?.value) * (1000 / totalQuantityFertilizer)).toFixed(0);
                                    }

                                    const total = Number(pricePerKg) * Number(fertilizerValue);
                                    if (isNaN(total)) return 0;
                                    return total;
                                }

                                return (
                                    <TableRow key={fertilizer?.id} className="height-row">
                                        <TableCell
                                            className="textAlignCenter">
                                            <CurrencyFormat
                                                customInput={CustomCurrencyFormatTexfield}
                                                InputProps={{
                                                    readOnly: true,
                                                    className: "mixesNotEditBtn",
                                                }}
                                                value={getPricePerTons() || 0}
                                                thousandSeparator
                                                prefix={`$ `}
                                            />
                                        </TableCell>
                                        <TableCell
                                            className="cellTableLittelFontSize">
                                                <CurrencyFormat
                                                    customInput={CustomCurrencyFormatTexfield}
                                                    InputProps={{
                                                        readOnly: true,
                                                        className: fertilizer.quantity == 0 ? "mixesNotEditBtn mixesEditBtnNotCompliance":"mixesNotEditBtn"
                                                    }}
                                                    value={fertilizer.quantity}
                                                    thousandSeparator
                                                    suffix={` ${fertilizer.unitMeasurement}`}
                                                />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </SectionContainer>
    );
}

function CustomCurrencyFormatTexfield({ ...props }) {
    return <UnlabeledTextField
        type="number" {...props}
        size="small"
    />
}

const ElementsWithComposition = ({ element, component, width, composition = [] }) => {
    const getComposition = () => {
        const compositionListByElement = composition.filter(item => {
            return (item.fertilizerId === component.fertilizer?.id && item.elementId === element.id);
        });

        const percentageComposition = compositionListByElement[0]?.percentageComposition || 0;
        const fertilizierValue = component.fertilizer?.value || 0;

        const result = (percentageComposition / 100) * fertilizierValue;

        return !result ? 0 : parseFloat(result).toFixed(1);
    }

    return (
        <TableCell
            className="textAlignCenter"
            sx={{
                width: width ?? "auto",
            }}
        >
            <UnlabeledTextField
                type="number"
                size="small"
                value={getComposition() || 0}
                InputProps={{
                    readOnly: true,
                    className: "mixesNotEditBtn",
                }}
            />
        </TableCell>
    )
}

function CustomAutocomplete({
    value: _value,
    onChange,
    options,
    nameColumnForLabel,
    label = "",
    ...props
}) {
    const [value, setValue] = useState(_value ?? "");
    const [inputValue, setInputValue] = useState("");

    return (
        <Autocomplete
            disableClearable
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            value={value}
            inputValue={inputValue}
            options={options}
            onInputChange={(_, newValue) => setInputValue(newValue)}
            onChange={(_, newValue) => {
                setValue(newValue);
                if (onChange instanceof Function) onChange(newValue);
            }}
            getOptionLabel={(option) => option?.[nameColumnForLabel]?.toString() || ""}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                />
            )}
            {...props}
        />
    );
}

function RenderFertilizerForCalculateGradeList({ fertilizersForCalculateGrade, automaticGradeCalculation, selectedFertilizers, Component, children, ...props }) {

    // Agrupar los fertilizantes por el nombre del grupo. {"Grupo 1": []}
    const groupedFertilizers = groupBy(fertilizersForCalculateGrade, "groupName");

    return Object.keys(groupedFertilizers).map((key, index) => {
        const groups = groupedFertilizers[key];
        let options = groups?.map(group => group?.fertilizer) || [];

        return <Component key={key} options={options} selectedFertilizers={selectedFertilizers} automaticGradeCalculation={automaticGradeCalculation} {...props}>{children}</Component>
    });
}


function FertilizerForCalculateGradeItem({
    options,
    availableEdit,
    deleteFertilizier,
    handleOnChange,
    selectedFertilizers,
    automaticGradeCalculation,
}) {
    const fertilizer = selectedFertilizers?.find(fertilizer => options?.find(option => option?.id === fertilizer?.id));

    return (
        <TableRow
            className="fullWidth height-row">
            {availableEdit && (
                <TableCell
                    sx={{
                        width: availableEdit ? "10%" : "0%",
                    }}>
                    <IconButton
                        disabled={true}
                        onClick={() => {
                            if (availableEdit) {
                                deleteFertilizier({ fertilizer })
                            }
                        }}
                    >
                        <Delete />
                    </IconButton>
                </TableCell>
            )}
            <TableCell
                sx={{
                    width: availableEdit ? "60%" : "65%",
                }}>
                <CustomAutocomplete
                    fullWidth
                    disabled={!availableEdit}
                    value={fertilizer || ""}
                    size="small"
                    options={options}
                    nameColumnForLabel="name"
                    onChange={(newValue) => {

                        if (handleOnChange instanceof Function) {
                            handleOnChange({ fertilizer }, newValue, "select", true);
                        }

                        if (automaticGradeCalculation instanceof Function) automaticGradeCalculation(null);
                    }}
                />
            </TableCell>
            <TableCell
                sx={{
                    width: availableEdit ? "30%" : "35%",
                }}>
                <UnlabeledTextField
                    InputProps={{
                        className: (availableEdit) ? (fertilizer?.value < 0 ? "mixesEditBtnNotCompliance" : "mixesEditBtn") : "mixesNotEditBtn",
                    }}
                    fullWidth
                    size="small"
                    onChange={(e) => {
                        handleOnChange({ fertilizer: { ...fertilizer, value: e.target.value } }, e.target.value, "input", true);

                        // Ejecutar función para calcular el valor del fertilizante automáticamente a partir del grado.
                        if (automaticGradeCalculation instanceof Function) automaticGradeCalculation(fertilizer);
                    }}
                    type="number"
                    value={fertilizer?.value || ""}
                    disabled={!fertilizer || !availableEdit}
                />
            </TableCell>
        </TableRow>);

}