import React from "react";
import BasicModal from "../../common/BasicModal/BasicModal";
import DownloadForm from "./DownloadForm";
import { Button } from "@mui/material";

export default function DownloadModal({ open, onClose, model }) {
    return (
        <BasicModal
            open={open}
            onClose={onClose}
            title="Confirmación de descarga"
            actions={(
                <React.Fragment>
                    <Button
                        onClick={onClose}
                        color="secondary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        form="download-datatable-form"
                        type="submit"
                    >
                        Descargar
                    </Button>
                </React.Fragment>
            )}
            sxDialogActions={{
                bgcolor: "#006530",
                fontSize: {
                    xs: "1.1rem",
                    sm: "1.25rem",
                },
            }}
        >
            <DownloadForm
                model={model}
                onClose={onClose}
            />
        </BasicModal>
    )
}
