import * as Yup from "yup";

/**
 * Permite crear esquemas de validación de Yup.
 * 
 * @param {Object} schema 
 * @param {Object[]} config - Array de configuración que contiene las columnas del formulario.
 * @param {String} config[].field - Nombre del campo del formulario.
 * @param {String} config[].validationType - Tipo de validación de Yup: string, number, boolean, date.
 * @param {Object[]} config[].validations - Array con las validaciones a aplicar.
 * @param {String} config[].validations[].type - Nombre del tipo de validación. Deber ser una validación de yup como: requiered, min, max, email, etc.
 * @param {Object[any]} config[].validations[].params - Array con los parámetros que se van a pasar a la función de validacións, según el campo type.
 * @returns {Yup.Schema} - Retorna un schema de validación de Yup.
 */

export function createYupSchema(schema, config) {

    const { field, validationType, validations = [] } = config;

    if(validationType != "email"){
        if (!Yup?.[validationType]?.()) return schema;
    }

    let validator = null;

    console.log("TIPO DE CAMPO")
    console.log(validationType);

    if(validationType == "email"){
        validator = Yup["string"]()[validationType]();
    }else{
        validator = Yup[validationType]();
    }    

    validations.forEach(validation => {
        const { params, type } = validation;
        if (!validator?.[type]) return;
        validator = validator[type](...params);
    });

    schema[field] = validator;

    return schema;
}

/**
 * Permite generar un schema a partir de un array.
 * 
 * @param {Object[]} metadata 
 * @param {String} metadata[].field - Nombre del campo del formulario.
 * @param {String} metadata[].validationType - Tipo de validación de Yup: string, number, boolean, date.
 * @param {Object[]} metadata[].validations - Array con las validaciones a aplicar.
 * @param {String} metadata[].validations[].type - Nombre del tipo de validación. Deber ser una validación de yup como: requiered, min, max, email, etc.
 * @param {Object[any]} metadata[].validations[].params - Array con los parámetros que se van a pasar a la función de validacións, según el campo type.
 * @param {Object} additionalValidations - Validaciones adicionales.
 * @param {Array<String>} forceRemove - Array con lista de campos que se quieren remover del schema. 
 * @returns {Yup.Schema} - Retorna un schema de validación de Yup.
 */
export const getYupSchemaFromMetaData = (metadata, additionalValidations, forceRemove) => {

    const yupSchema = metadata.reduce(createYupSchema, {});

    const mergedSchema = { ...yupSchema, ...additionalValidations };

    forceRemove.forEach(field => delete mergedSchema[field]);

    const validateSchema = Yup.object().shape(mergedSchema);

    return validateSchema;
}