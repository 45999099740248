import api from "../axios/axios";
import { ENV } from "../utils/constants";
export const getFertilizersByMix = async (mixId) => {
    try {

        const response = await api.post(ENV.API_ROUTES.ENTITY.GET, { model: ENV.CLIENT_ROUTES.FERTLIZERBYMIX.MODEL, idSearch: mixId, column: "mixId" });
        if (response.status !== 200) throw response;
        return response?.data;
    } catch (error) {
        throw error;
    }

};

export const getElementsByMix = async (mixId) => {
    try {
        const response = await api.post(ENV.API_ROUTES.ENTITY.GET, { model: ENV.CLIENT_ROUTES.ELEMENTBYMIX.MODEL, idSearch: mixId, column: "mixId" });
        if (response.status !== 200) throw response;
        return response.data;
    } catch (error) {
        throw error;
    }

}