import { ReactComponent as LogoWhiteCiamsa } from "./logo_ciamsa.svg";

// Exportar imagenes
import backgroundBlackAndWhite from "./bg_ciamsa_blanco_negro.jpg";

const Icon = {
    LogoWhiteCiamsa,
};

const image = {
    backgroundBlackAndWhite,
};

export { Icon, image };