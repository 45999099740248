import { Grid, Paper } from "@mui/material";
import React, { useMemo } from "react";
import ApprovalRequestList from "./ApprovalRequestList";
import { useMainContext } from "../../../hooks/useMainContext";
import { roles } from "../../../utils/constants";

export default function ApprovalRequest({ quoteId, onClose, onReloadDatatable }) {

    const { user } = useMainContext() || {};

    const isRTCJunior = useMemo(() => user?.roleId === roles.RTCJunior, [user]);

    return (
        <Grid
            className="panel approvalRequest"
            container
            component={Paper}
            elevation={0}
        >
            <Grid container item xs={12} sx={{ paddingY: 1, borderBottom: "1px solid #bababa", fontWeight: 500 }}>
                <Grid item xs={1}>
                    Id
                </Grid>
                <Grid item xs={2}>
                    Estado
                </Grid>
                <Grid item xs={isRTCJunior ? 3 : 2}>
                    Aprovador
                </Grid>
                <Grid item xs={2}>
                    Role
                </Grid>
                <Grid item xs={isRTCJunior ? 4 : 3}>
                    Comentario
                </Grid>
                {(!isRTCJunior) && (
                    <Grid item xs={2}>
                        Acciones
                    </Grid>
                )}
            </Grid>

            {/* Listar las solicitudes de aprobación */}
            <ApprovalRequestList
                quoteId={quoteId}
                isRTCJunior={isRTCJunior}
                onClose={onClose}
                onReloadDatatable={onReloadDatatable}
            />

        </Grid>
    )
}
