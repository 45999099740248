import React from "react";
import FilterField from "./FilterField";

export default function FilterFieldGroup({ model, updateFilter, filterFields = [] }) {
    return filterFields.map((field, index) => (
        <FilterField
            key={index}
            model={model}
            updateFilter={updateFilter}
            columnName={field?.columnName}
            label={field?.label}
        />
    ));
}
