import React, { useEffect, useMemo, useState } from "react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Typography, tableCellClasses, styled } from "@mui/material";
import MixesHeader from "../../Mixes/MixesHeader/MixesHeader";
import CurrencyFormat from "react-currency-format";
import { loadFullData } from "../../../apiCore/entity";
import { ENV } from "../../../utils/constants";
import { useSnackbar } from "notistack";

/**
 * Calcula el precio total de una mezcla.
 * @param {Object} mix objeto del tipo mezcla. 
 * @returns costo total del mezcla.
 */
const getTotalPriceByMix = (mix) => {

    const {
        quantityTons = 0,
        price = 0,
        totalTons = 0,
        maquilaCost = 0,
        packaging,
        profitability = 0,
        discount = 0,
        flete = 0,
    } = mix || {};

    if (quantityTons) {
        const rawMaterialCost = (price / totalTons) * 1000 || 0;
        const maquila = (Number(rawMaterialCost) + Number(maquilaCost)) || 0;
        const { value: packagingCapacity = 0, unitPrice = 0 } = packaging || {};
        const packagingQuantity = Number(1000 / Number(packagingCapacity)) || 0;
        const subtotal = Number(maquila + packagingQuantity * unitPrice) || 0;
        const withProfitability = Number(subtotal / (1 - (profitability / 100))) || 0;
        const withDiscount = Number(withProfitability * (1 - (discount / 100))) || 0;
        const result = Number(withDiscount) + Number(flete);

        return result * quantityTons;
    } else {
        return 0;
    }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#006530",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    minHeight: "40px",
    height: "40px",
}));


export default function QuoteSummary({ quote }) {
    const [elements, setElements] = useState([]);
    const { mixes } = quote || {};
    const { enqueueSnackbar } = useSnackbar();

    const total = useMemo(() => {
        return mixes.reduce((acumulado, mix) => acumulado + getTotalPriceByMix(mix), 0);
    }, [quote]);

    useEffect(() => {

        console.log("Cambio en la cotización")
        console.log(quote);

        (async () => {
            try {
                const response = await loadFullData({model: ENV.CLIENT_ROUTES.ELEMENTS.MODEL});

                const { error, data } = response?.data || {};

                if (error) throw { error: response };

                if (data) setElements(data);
            } catch (err) {
                const { error } = err?.response?.data || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al cargar la lista de elementos", { variant: "error" });
                }
            }
        })();
    }, [quote]);

    return (
        <Box
            className="quoteSummary">
            <MixesHeader
                quote={quote}
                showButon={false}/>

            <Box
                className="quoteSummaryTableContainer">
                <TableContainer
                    className="quoteSummaryTable"
                    component={Paper}
                    elevation={5}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell rowSpan={2} className="cellSeveralCenterMix">
                                    Nombre
                                </StyledTableCell>
                                <StyledTableCell rowSpan={2} className="cellSeveralCenterMix">
                                    Tipo
                                </StyledTableCell>
                                <StyledTableCell colSpan={elements.length} className="cellSeveralCenterMix">
                                    Elementos
                                </StyledTableCell>
                                <StyledTableCell rowSpan={2} className="cellSeveralCenterMix">
                                    Cantidad (Tn)
                                </StyledTableCell>
                                <StyledTableCell rowSpan={2} className="cellSeveralCenterMix">
                                    Total
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                {elements.map(element => (
                                    <StyledTableCell key={element?.id} className="elementHeader">
                                        {element?.name}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(mixes.length === 0) && (
                                <TableRow>
                                    <StyledTableCell
                                        className="textAlignCenter cellSeveralMix"
                                        colSpan={elements?.length + 4}>
                                        <Typography variant="body2">
                                            No hay datos disponibles
                                        </Typography>
                                    </StyledTableCell>
                                </TableRow>
                            )}
                            {(mixes.length > 0) && mixes?.map(mix => {
                                return (
                                    <TableRow
                                        key={mix?.id}
                                        className="quoteSummaryTableRow"
                                        sx={{
                                            ":hover": (theme) => ({
                                                backgroundColor: theme.palette.action.hover,
                                            })
                                        }}>
                                        <StyledTableCell className="cellSeveralMix elementContentLeft">
                                            {mix?.name}
                                        </StyledTableCell>
                                        <StyledTableCell className="cellSeveralMix elementContentLeft">
                                            {mix?.type?.name}
                                        </StyledTableCell>
                                        {elements.map(element => {
                                            const matchElement = mix?.elements?.find(mixElement => mixElement?.elementId === element?.id) || {};
                                            return <StyledTableCell className="elementContent" key={element.id}>{matchElement?.value ?? 0}</StyledTableCell>;
                                        })}
                                        <StyledTableCell className="cellSeveralMixRight elementContentRight">
                                            {mix?.quantityTons ?? 0}
                                        </StyledTableCell>
                                        <StyledTableCell className="cellSeveralMixRight elementContentRight">
                                            <CurrencyFormat
                                                renderText={(value) => <b>{value}</b>}
                                                displayType="text"
                                                value={parseFloat(getTotalPriceByMix(mix)).toFixed(0)}
                                                thousandSeparator
                                                prefix="$ "
                                            />
                                        </StyledTableCell>
                                    </TableRow>
                                )
                            })}

                            {(mixes.length > 0) && (
                                <StyledTableRow>
                                    <StyledTableCell
                                        colSpan={elements.length + 2}
                                    />
                                    <StyledTableCell className="cellSeveralMixRight titleText">
                                        <strong>Total</strong>
                                    </StyledTableCell>
                                    <StyledTableCell className="cellSeveralMixRight titleText">
                                        <CurrencyFormat
                                            renderText={(value) => <b>{value}</b>}
                                            displayType="text"
                                            value={parseFloat(total).toFixed(0)}
                                            thousandSeparator
                                            prefix="$ "
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box>
    )
}
