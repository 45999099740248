/**
 * Función de rebote que genera un retraso en la ejecución de una función determinada.
 * Se usa para hacer la solicitud de datos al servidor cuando cambia el valor de los campos filtro de la tabla.
 * 
 * @param {Function} callback  - Función a ejecutar.
 * @param {Number} wait - Tiempo de espera en milisegundos. Por defecto 1000 milisegundos.
 * @returns {function} Retorna una función.
 * 
 * @copyright Source: https://www.freecodecamp.org/espanol/news/curso-debounce-javascript-como-hacer-que-tu-js-espere/
 */
export function debounce (callback, wait=1000) {
    let timerId;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {callback(...args);}, wait);
    }
}
