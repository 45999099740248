import api from "../axios/axios";

/**
 * Permite consultar en una ruta especifica los valores de confifuración para obtener los datos
 * para realizar un gráfico de columnas. Retorna el array de las etiquetas y el array de las series.
 * 
 * @param {Object} Object - Configuración.
 * @param {String} [Object.apiRoute] - url de la api donde se va a consultar la información.
 * @param {String} Object.viewName - nombre de la vista o modelo de donde se va a consultar la información.
 * @param {Object} [Object.order] - Objecto de configuración para el ordenamiento de los datos.
 * @param {String} Object.order.columnName - nombre de la columna por la cual se quiere ordenar los datos.
 * @param {String} Object.order.direction - dirección de ordenamiento, "ASC" o "DESC".
 * @param {String} Object.xAxisColumnNameForCategories - nombre de la columna de donde se van a extraer las categorias para el eje x del gráfico.
 * @param {Array<String>} series - array con el nombre de las columnas de las cuales se van a sacar las series.
 * @param {String} Object.columnNameForValues - nombre de la columna que se va a usar para calcular los valores.
 * @param {Object} Object.filter - Objeto con datos de filtro de la información.
 * @param {String} Object.filter.columnName - nombre de la columna a la que se va aplicar el filtro.
 * @param {String} Object.filter.value - valor de filtro.
 */
export const getDataForBasicColumnChartPromise = async ({apiRoute = "api/dashboard/basicColumnChart",viewName,order,xAxisColumnNameForCategories,series,columnNameForValues,filter}) => {
    try {
        return api.post(apiRoute, {viewName,order,xAxisColumnNameForCategories,series,columnNameForValues,filter});
    } catch (error) {
        throw error;
    }
}

export const getDataForBasicColumnChart = async ({apiRoute = "api/dashboard/basicColumnChart",viewName,order,xAxisColumnNameForCategories,series,columnNameForValues,filter}) => {
    try {
        const response = await api.post(apiRoute, {viewName,order,xAxisColumnNameForCategories,series,columnNameForValues,filter});
        const { error } = response?.data || {};
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

// Retorna la lista de opciones para el filtro de los gráficos del dashboard principal
export const getSelectorOptions = async ({ model, columnName }) => {
    try {
        const response = await api.post("api/Dashboard/getSelectorOptions", { model, columnName })
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}
