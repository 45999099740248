import React, { useState } from "react";
import { Avatar, IconButton, Typography } from "@mui/material";
import UserMenu from "./UserMenu";
import { useMainContext } from "../../hooks/useMainContext";
import ManualIcon from "../Manuals/ManualIcon";

export default function Header(Props) {
    const { user } = useMainContext() || {};

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }

    return (
        <>
            <Typography
                variant="h6"
                component="div"
                fontSize={{
                    xs: "1.0rem",
                    sm: "1.1rem",
                    md: "1.25rem"
                }}
            >
                {Props.titlePage}
            </Typography>
            <div className="buttonAppBarSection">
                <ManualIcon />
                <IconButton
                    onClick={handleOpen}
                    aria-haspopup="true"
                    size="small">

                    <Avatar style={{ textTransform: 'uppercase' }}>
                        {user?.email ? user?.email[0] : ""}
                    </Avatar>

                </IconButton>
                <UserMenu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    user={user}
                />
            </div>
        </>
    )
}
