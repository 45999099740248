import React from "react";

import Datatable from "../../Controls/Datatable";
import NewUserButton from "./NewUserButton";
import UserActionButtons from "./UserActionButtons";
import { ENV } from "../../../utils/constants";

export default function DatatableUser() {
    return (
        <Datatable
            filterColumns={["code", "codeGenerated"]}
            model={ENV.CLIENT_ROUTES.USERS.MODEL}
            NewRegisterButton={NewUserButton}
            ActionsButtons={UserActionButtons}
            modelIsView={true} // Es modelo de users no es una vista, pero se coloca en true para desactivar la opción de editar.
        />
    );
}