import React, { useState } from 'react';
import {
    Slide,
    Button,
    Box,
    FormControl,
} from '@mui/material';
import { Autocomplete } from "@mui/material";
import { CssTextField } from "./CustomTextField";
import BasicModal from "../common/BasicModal/BasicModal";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExportConfirmModal(props) {
    const {
        open,
        setOpen,
        titleMessage,
        confirmHandler,
        filterExportColumns,
        setFilterExportColumns,
        setFilterExportParams,
    } = props;
    // const [dateRange, setDateRange] = useState({});

    const onChange = (value) => {
        Object.keys(value).forEach(key => {
            setFilterExportParams(prevState => ({ ...prevState, [`${key}`]: value[key] }))
        })
    }

    const onCloseOrCancel = () => {
        setFilterExportParams(null);

        if (filterExportColumns) {
            setFilterExportColumns(prevState => {
                return prevState.map(item => ({ ...item, isEmpty: false }))
            });
        }
        setOpen(false);
    }

    return (
        <BasicModal
            open={open}
            className='maxSizeModalDateRange'
            maxWidth='md'
            onClose={onCloseOrCancel}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            title={titleMessage}
            showIconClose={false}
            actions={(
                <React.Fragment>
                    <Button
                        onClick={onCloseOrCancel}
                        color="secondary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        autoFocus
                        onClick={() => {
                            confirmHandler();
                        }}
                        color="primary">
                        Descargar datos
                    </Button>
                </React.Fragment>
            )}
            sxDialogActions={{
                bgcolor: "#006530",
                fontSize: {
                    xs: "1.1rem",
                    sm: "1.25rem",
                },
            }}
        >
            {filterExportColumns &&
                <Box
                    className="exportConfirmModalConatiner"
                >
                    <FormControl
                        className="exportConfirmModalForm"
                    >
                        {filterExportColumns.map((column, index) => (
                            <SelectAutocomplete
                                key={index}
                                column={column}
                                onChange={onChange}
                                setFilterExportColumns={setFilterExportColumns}
                            />
                        ))}
                    </FormControl>
                </Box>
            }
        </BasicModal>
    )
}


function SelectAutocomplete(props) {
    const { column, onChange, setFilterExportColumns } = props;
    const [value, setValue] = useState(null);

    const handleEmptyInputs = (value) => {
        if (!value) {
            setFilterExportColumns(prevState => {
                return prevState.map(item => {
                    if (item.field === column.field) return ({ ...item, isEmpty: true });
                    return ({ ...item })
                })
            });
        } else {
            setFilterExportColumns(prevState => {
                return prevState.map(item => {
                    if (item.field === column.field) return ({ ...item, isEmpty: false });
                    return ({ ...item })
                })
            });
        }
    }
    return (
        <Autocomplete
            options={column.options}
            onChange={(_, value) => {
                setValue(value);
                handleEmptyInputs(value);
                // Se toma el valor del id, para facilitar la consulta en la BBDD.
                onChange({ [`${column.field}`]: value?.id })
            }}
            value={value}
            getOptionLabel={option => option.name}
            renderInput={(params) => (
                <CssTextField
                    {...params}
                    error={column.isEmpty}
                    name={column.field}
                    required
                    label={`Seleccione un ${column.headerName.toLowerCase()}`}
                    helperText={column.isEmpty ? `El campo ${column.headerName.toLowerCase()} es obligatorio.` : ""}
                />
            )}
            renderOption={(props, option) => {
                return <li {...props}>{`${option.name}`}</li>;
            }}
        />)
}