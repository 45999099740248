import React, { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { Box, Button, FormControl, FormControlLabel, FormGroup, Switch, } from "@mui/material";
import { Save } from "@mui/icons-material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import api from "../../../axios/axios";
import SelectAutocomplete from "../../Controls/SelectAutocomplete";
import { CssTextField } from "../../Controls/CustomTextField";
import { getYupSchemaFromMetaData } from "../../../utils/yupSchemaCreator";
import { ENV, roles } from "../../../utils/constants";
import { createNewRegister, updateRegister } from "../../../apiCore/entity";
import { hideLoader, showLoader } from "../../Controls/Loader";

function initialValues(row) {
    return {
        name: row?.name ?? "",
        lastName: row?.lastName ?? "",
        cedula: row?.cedula ?? "",
        SAPCode: row?.SAPCode ?? "",
        email: row?.email ?? "",
        roleId: row?.roleId ?? "",
        zoneId: row?.zoneId ?? "",
        phone: row?.phone ?? "",
        groupId: row?.groupId ?? "",
        isActive: row?.isActive ?? false,
        notifyRegistration: row?.notifyRegistration ?? false
    };
}

export default function UserForm({ columns, onReload, onClose, row }) {

    const { enqueueSnackbar } = useSnackbar();

    // Validación adicional para el grupo, dependiendo del role seleccionado se vuelve obligatorio.
    // Si el role que se va a crear es Jefe Regional o RTC, se debe de asignar un grupo obligatoriamente.
    const additionalValidations = {
        groupId: Yup.string()
            .when("roleId",
                {
                    is: (roleId) => roleId === roles.RTCJunior || roleId === roles.RTCSenior,
                    then: (schema) => schema.required("El campo grupo es obligatorio"),
                    otherwise: (schema) => schema.notRequired()
                }),
    };

    const dinamicSchema = getYupSchemaFromMetaData(columns,
        additionalValidations,
        []);

    const reloadAndClose = () => {
        // Recargar tabla de datos
        if (onReload instanceof Function) onReload();
        // Cerrar modal
        if (onClose instanceof Function) onClose();
    }

    const formik = useFormik({
        initialValues: initialValues(row),
        validationSchema: dinamicSchema,
        onSubmit: async (values) => {
            try {
                showLoader();

                if (row) {
                    const { id, roleId } = row || {};
                    if (!id) {
                        enqueueSnackbar("Error, se requiere un ID valido para actualizar el registro", { variant: "error" });
                        return
                    }

                    // Validar si cambio el role del usuario
                    if (roleId !== values.roleId) {
                        // Consultar los grupos que tiene asignado el usuario.
                        try {
                            const response = await api.post("api/autocomplete/loadFullData", {
                                model: ENV.CLIENT_ROUTES.GROUPS.MODEL,
                                column: "name",
                                conditions: { ownerId: id }
                            });

                            const { error, data = [] } = response?.data || {};

                            if (error) {
                                enqueueSnackbar(error, { variant: "error" });
                                return;
                            }

                            if (data?.length > 0) {

                                let message = (
                                    <div>
                                        No se puede cambiar el rol del usuario porque tiene {data.length > 1 ? "los siguientes grupos asignados" : "el siguiente grupo asignado"}:
                                        <ul>
                                            {data.map((group, index) => (<li key={index}>Nombre: {group?.name} Tipo de grupo: {group?.groupType}</li>))}
                                        </ul>
                                        Para cambiar el rol primero cambie la propiedad {data?.length > 1 ? "de los grupos asignados" : " del grupo asignado"}
                                    </div>
                                );

                                enqueueSnackbar(message, { variant: "error" });
                                return;
                            }
                        } catch (error) {
                            enqueueSnackbar("Error al consultar los grupos asignados", { variant: "error" });
                            return;
                        }
                    }
                    // Actualizar datos de usuario
                    const response = await updateRegister({
                        model: ENV.CLIENT_ROUTES.USERS.MODEL,
                        id,
                        data: values,
                    });

                    const { error, message } = response?.data || {};
                    if (error) {
                        enqueueSnackbar(error, { variant: "error" });
                        return;
                    }

                    if (message) enqueueSnackbar(message, { variant: "success" });

                } else {
                    // Si el role seleccionado es administrador o aprobador, se elimina el campo groupId
                    // para evitar errores de registro por llave foranea.
                    if (values.roleId === roles.administrador || values.roleId === roles.aprobador) {
                        delete values.groupId;
                    }
                    // Crear nuevo usuario.
                    const response = await createNewRegister({ model: ENV.CLIENT_ROUTES.USERS.MODEL, data: values });
                    const { error, message } = response.data || {};
                    if (error) {
                        enqueueSnackbar(error, { variant: "error" });
                        return;
                    }

                    if (message) {
                        enqueueSnackbar(message, { variant: "success" });
                    }
                }

                // Recargar datos de tabla y cerra modal.
                reloadAndClose();

            } catch (err) {
                const { error } = err?.response?.data;
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al registrar la información", { variant: "error" })
                }
            } finally {
                hideLoader();
            }
        }
    });

    const roleToConsult = useMemo(() => {
        if (formik.values.roleId === roles.RTCJunior) {
            return roles.RTCSenior;
        } else if (formik.values.roleId === roles.RTCSenior) {
            return roles.aprobador;
        } else {
            return null;
        }
    }, [formik.values.roleId]);

    useEffect(() => {
        if (formik.submitCount > 0) {
            columns.map(column => {
                formik.setFieldTouched(column?.field, true);
            });
        }
    }, [formik.submitCount]);

    return (
        <Box
            className="backgroundPlain"
        >
            <Box
                onSubmit={formik.handleSubmit}
                component="form"
                className="panel"
            >
                <Box
                    className="displayFlex flexDirectionColumn"
                    padding={1}
                    gap={1}
                >
                    <CssTextField
                        label="Nombre"
                        name="name"
                        fullWidth
                        size="small"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik?.touched?.["name"] && Boolean(formik?.errors?.["name"])}
                        helperText={formik?.touched?.["name"] && formik?.errors?.["name"]}
                    />
                    <CssTextField
                        label="Apelidos"
                        name="lastName"
                        fullWidth
                        size="small"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik?.touched?.["lastName"] && Boolean(formik?.errors?.["lastName"])}
                        helperText={formik?.touched?.["lastName"] && formik?.errors?.["lastName"]}
                    />
                    <CssTextField
                        label="Cedula"
                        name="cedula"
                        fullWidth
                        size="small"
                        value={formik.values.cedula}
                        onChange={formik.handleChange}
                        error={formik?.touched?.["cedula"] && Boolean(formik?.errors?.["cedula"])}
                        helperText={formik?.touched?.["cedula"] && formik?.errors?.["cedula"]}
                    />
                    <CssTextField
                        label="Código SAP"
                        name="SAPCode"
                        fullWidth
                        size="small"
                        value={formik.values.SAPCode}
                        onChange={formik.handleChange}
                        error={formik?.touched?.["SAPCode"] && Boolean(formik?.errors?.["SAPCode"])}
                        helperText={formik?.touched?.["SAPCode"] && formik?.errors?.["SAPCode"]}
                    />
                    <CssTextField
                        label="Email"
                        name="email"
                        fullWidth
                        size="small"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik?.touched?.["email"] && Boolean(formik?.errors?.["email"])}
                        helperText={formik?.touched?.["email"] && formik?.errors?.["email"]}
                    />

                    <SelectAutocomplete
                        CustomTextField={CssTextField}
                        size="small"
                        value={formik.values.roleId}
                        textfieldError={formik?.touched?.roleId && Boolean(formik?.errors?.roleId)}
                        textfieldHelpertext={formik?.touched?.roleId && formik?.errors?.roleId}
                        model="roles"
                        label="Role"
                        column="name"
                        name="role"
                        onChange={async (id, __, ___, val) => {
                            if (id !== roles.administrador) {
                                formik.setFieldValue("notifyRegistration", false);
                            }
                            await formik.setFieldValue("roleId", id ?? "");
                            await formik.setFieldValue("groupId", "");
                        }}
                    />

                    <FormGroup
                        className="displayFlex flexJustifyContentSpaceBetween flexDirectionRow fullWidth"
                    >
                        <FormControl>
                            <FormControlLabel
                                name="notifyRegistration"
                                label="Notificar registro"
                                disabled={formik.values?.roleId !== roles.administrador}
                                onChange={formik.handleChange}
                                control={
                                    <Switch
                                        checked={formik.values?.notifyRegistration}
                                        value={formik.values?.notifyRegistration}
                                        className={formik.values?.notifyRegistration ? "Mui-checked" : ""}
                                        onChange={(event) => {
                                            formik.setFieldValue("notifyRegistration", event.target.checked);
                                        }}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                } />
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                name="isActive"
                                label="Activo"
                                onChange={formik.handleChange}
                                control={
                                    <Switch
                                        checked={formik.values?.isActive}
                                        value={formik.values?.isActive}
                                        className={formik.values?.isActive ? "Mui-checked" : ""}
                                        onChange={(event) => {
                                            formik.setFieldValue("isActive", event.target.checked);
                                        }}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                } />
                        </FormControl>
                    </FormGroup>

                    <SelectAutocomplete
                        CustomTextField={CssTextField}
                        size="small"
                        model="zone"
                        label="Zona"
                        column="name"
                        name="zone"
                        value={formik.values.zoneId}
                        textfieldError={formik?.touched?.zoneId && Boolean(formik?.errors?.zoneId)}
                        textfieldHelpertext={formik?.touched?.zoneId && formik?.errors?.zoneId}
                        onChange={async (id) => {
                            await formik.setFieldValue("zoneId", id ?? "");
                        }}
                    />

                    <CssTextField
                        label="Teléfono"
                        name="phone"
                        fullWidth
                        size="small"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik?.touched?.["phone"] && Boolean(formik?.errors?.["phone"])}
                        helperText={formik?.touched?.["phone"] && formik?.errors?.["phone"]}
                    />

                    <SelectAutocomplete
                        CustomTextField={CssTextField}
                        key={formik.values.roleId}
                        reload={formik.values.roleId}
                        apiRoute={`${ENV.API_ROUTES.GROUPS.GET_GROUPS_BY_ROLE}?roleId=${roleToConsult}`}
                        size="small"
                        model="groups"
                        label="Grupo"
                        column="name"
                        name="group"
                        value={formik.values.groupId}
                        disabled={!Boolean(formik.values?.roleId) || formik.values?.roleId === roles.administrador || formik.values?.roleId === roles.aprobador}
                        textfieldError={formik?.touched?.groupId && Boolean(formik?.errors?.groupId)}
                        textfieldHelpertext={formik?.touched?.groupId && formik?.errors?.groupId}
                        onChange={async (id) => {
                            await formik.setFieldValue("groupId", id ?? "");
                        }}
                        isMatch={(match) => {
                            if (!match && row) {

                                const message = (
                                    <div>
                                        El campo grupo tiene asignado un valor que no corresponde a las opciones disponibles.
                                        <br />
                                        Por favor actualice el campo con una opción valida
                                    </div>
                                );
                                enqueueSnackbar(message, { variant: "error" });
                                formik.setFieldValue("groupId", "");
                            }
                        }}
                    />

                </Box>
                <Box
                    padding={1}
                >
                    <Button
                        variant="contained"
                        fullWidth
                        endIcon={<Save />}
                        type="submit"
                        disabled={formik.isSubmitting}
                    >
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
