import React from "react";
import { Grid } from "@mui/material";

export default function SectionContainer({ children, ...props }) {
    return (
        <Grid
            item
            container
            columnSpacing={{
                xs: 1 / 2,
                sm: 1,
                md: 2,
            }}
            {...props}
        >
            {children}
        </Grid>
    )
}
