import api from "../axios/axios";

/**
 * Permite descargar el pdf según el id de una cotización
 * @param {Object} Object - parámetros de configuración.
 * @param {Number} Object.quoteId - id de la cotización. 
 * @param {Boolean} [Object.showInNewPage=false] - si es True se visualiza el PDF en una nueva pestaña. Por defecto es false y descarga el pdf.
 *
 */
export const downloadPDFOfQuote = async ({ quoteId, showInNewPage = false }) => {
    try {
        const response = await api.post("api/quotes/downloadPDF", { quoteId }, { responseType: "blob" });
        if (response?.data?.error || response.status !== 200) throw response;

        if (response?.data) {
            const tmpFileUrl = window.URL.createObjectURL(response.data);
            let alink = document.createElement("a");
            alink.href = tmpFileUrl;
            if (showInNewPage) {
                alink.target = "_blank";
                alink.click();
            } else {
                alink.download = `cotizacion_${quoteId}.pdf`;
                alink.click();
            }
        }
    } catch (error) {
        throw error;
    }
}

/**
 * Permite descargar el pdf de una cotización. El pdf debe de estar generado en el servidor.
 * @param {Object} Object - parámetros de configuración.
 * @param {Number} Object.quoteId - Id de la cotización. 
 * 
 */
export const getPDFOfQuote = async ({ quoteId }) => {
    try {
        const response = await api.post("api/quotes/getPdf", { quoteId }, { responseType: "blob" });

        if (response?.data?.error || response?.status !== 200) throw response;

        if (response?.data) {
            const tmpFileUrl = window.URL.createObjectURL(response.data);

            let alink = document.createElement("a");
            alink.href = tmpFileUrl;
            alink.target = "_blank";
            alink.click();
        }
    } catch (error) {
        throw error;
    }
}

export const getFile = async ({ path }) => {
    try {
        const response = await api.post("api/uploadFiles/getFile", { path }, { responseType: "blob" });

        if (response?.data?.error || response?.status !== 200) throw response;

        if (response?.data) {
            const tmpFileUrl = window.URL.createObjectURL(response.data);
            let alink = document.createElement("a");
            alink.href = tmpFileUrl;
            alink.target = "_blank";
            alink.click();
        }
    } catch (error) {
        throw error;
    }
}