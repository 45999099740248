
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const CssTextField = styled(TextField)({
    label: { color: "#555" },
    '& label.Mui-focused': {
        color: '#bbb',        
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#bbb',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#bbb',            
        },
        '&:hover fieldset': {
            borderColor: '#bbb',            
        },
        '&.Mui-focused fieldset': {
            borderColor: '#bbb',
        },
    },
});
