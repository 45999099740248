import React, { useMemo, useState } from "react";
import { useMainContext } from "../../hooks/useMainContext";
import { roles } from "../../utils/constants";
import { navigationMenuOptions } from "../../utils/navigationMenuOptions";

export const MenuNavegacionContext = React.createContext({ menuOptions: [], updateState: (id) => { } });

export default function MenuNavegacionProvider({ children }) {

    const { availableMenuPaths = [], user } = useMainContext();

    /**
     * Permite filtrar el menú de navegación, según las rutas a las cuales tenga acceso.
     * @param {Array} menuPaths - Array de objetos con los datos del menu. 
     * Los objetos son del tipo {id: number, menuPath: string, roleId: number, editPermission: boolean, viewPermission: boolean}.
     * @param {Array} options - Array de objetos con las opciones disponibles del menú de navegación. 
     * @returns {Array} Array del menú de navegación filtrado según las rutas de acceso habilitadas.
     */
    const recursiveFilterMenu = (menuPaths, options) => {
        let filterMenuList = [];

        for (let i = 0; i < options?.length; i++) {
            const option = options[i];
            const { childrens } = option || {};

            if (childrens) {
                const filterChildrens = { ...option, childrens: recursiveFilterMenu(menuPaths, childrens) };

                // Solo se registran, los objetos que dentro de la propiedad children tengan datos.
                if (filterChildrens?.childrens?.length > 0) {
                    filterMenuList.push(filterChildrens);
                }
            } else {
                for (let j = 0; j < menuPaths.length; j++) {
                    const { menuPath, editPermission, viewPermission } = menuPaths[j];

                    if (option instanceof Array) {
                        // Se filtan los objetos con la misma url y que tengan permisos para visualizar o editar.
                        const filterOptions = option.filter(item => (item?.url === menuPath && (editPermission || viewPermission)));
                        filterMenuList.push(filterOptions);
                    } else {
                        // Se filtan los objetos con la misma url y que tengan permisos para visualizar o editar.
                        if (option?.url === menuPath && (editPermission || viewPermission)) {
                            filterMenuList.push(option)
                        }
                    }
                }
            }
        }

        return filterMenuList;
    }

    const getMenuList = useMemo(() => {
        // El usuario con el role de administrador puede ver el menú de navegación completo.
        if (user?.roleId == roles.administrador) {
            return navigationMenuOptions;
        } else {
            return recursiveFilterMenu(availableMenuPaths, navigationMenuOptions);
        }
    }, []);

    // Opciones del menú de navegación filtrada segun rol y accesos.
    const [menuOptions, setMenuOptions] = useState(getMenuList);

    // Validar si dentro de los array de hijos de un padre, se encuentra el id que se esta consultando.
    const parentContainsChildren = (idChildren, childrens) => {
        let isChildren = false;
        const childrenFound = childrens.find(children => children?.id === idChildren);

        if (!childrenFound && childrens?.childrens) {
            isChildren = parentContainsChildren(idChildren, childrens?.childrens);
        } else {
            isChildren = Boolean(childrenFound);
        }

        return isChildren;
    }

    // Actualizar la propiedad open, de las opciones del menú de navegación.
    const recursiveUpdateState = (id, options = []) => {
        let result = [];
        for (let i = 0; i < options.length; i++) {
            const section = options[i];

            if (section?.id === id) {
                result[i] = { ...section, open: !section?.open };
            } else {
                const { childrens } = section || {};

                if (childrens) {
                    const childrenResult = { ...section, childrens: recursiveUpdateState(id, childrens) };

                    // Consultar los hijos de la sección.
                    const { childrens: nestedChildren = [] } = childrenResult;
                    // Validar, si dentro de los hijos, alguno tiene la propiedad open en true.
                    const hastOpenChildren = nestedChildren.some(children => children?.open);

                    // Si hay un hijo, con la propiedad open en true, se modifica la propiedad open del padre a true.
                    if (hastOpenChildren) {
                        result[i] = { ...childrenResult, open: true };
                    } else {
                        // Si ninguno de los hijos tiene la propiedad open en true, se valida el id del hijo, para identificar si es un hijo directo del padre.
                        if (parentContainsChildren(id, nestedChildren)) {
                            result[i] = { ...childrenResult, open: true };
                        } else {
                            result[i] = { ...childrenResult, open: false };
                        }
                    }
                } else {
                    if (section instanceof Array) {
                        result[i] = section.map(item => {
                            if (item?.id === id) {
                                return { ...item, open: !item?.open };
                            } else {
                                return { ...item, open: false }
                            }
                        })
                    } else {
                        if (section?.id === id) {
                            result[i] = { ...section, open: !section?.open }
                        } else {
                            result[i] = { ...section, open: false }
                        }
                    }
                }
            }
        }

        return result;
    }

    // Actualizar el estado de la propiedad open de las opciones del menú de navegación, con base en el id.
    const updateState = (id) => {
        setMenuOptions(prevMenu => {
            const newState = recursiveUpdateState(id, prevMenu);
            return newState;
        });
    }

    const data = {
        menuOptions,
        updateState,
    }

    return <MenuNavegacionContext.Provider value={data}>{children}</MenuNavegacionContext.Provider>
}
