import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { getColumnsAndFilterValues } from "../../../apiCore/dataTable";
import Form from "../../Controls/Form";

export default function NewRegisterButton({ model, endIcon, buttonProps, sx, onReload, label = "Nuevo registro" }) {
    const [columns, setColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleClick = async () => {
        try {
            setIsLoading(true);
            const { columns: _columns } = await getColumnsAndFilterValues({ model });
            setColumns(_columns);
            setIsLoading(false);
            setOpenForm(true);
        } catch (error) {
            setIsLoading(false);
            enqueueSnackbar("Error al cagar el formulario", { variant: "error" });
        }
    }

    return (
        <>
            <Button
                color='success'
                fullWidth
                className="heightNewRegisterBtn"
                sx={{
                    "& .MuiButton-endIcon": {
                        margin: `${label ? "0px 0px 0px 10px" : 0}`,
                        "& .MuiSvgIcon-root": {
                            fontSize: "30px",
                        },
                    },
                    ...sx,
                }}
                variant="contained"
                onClick={handleClick}
                endIcon={isLoading ? (< CircularProgress color="primary" size="25px" />) : (endIcon)}
                {...buttonProps}
            >
                {label}
            </Button>

            {openForm && (
                <Form
                    onReload={onReload}
                    open={openForm}
                    setOpenAddItem={setOpenForm}
                    model={model}
                    columns={columns}
                    close={(_) => setOpenForm(false)}
                />
            )}
        </>
    )
}
