
import React, { memo, useEffect, useRef, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getSelectorOptions } from "../../apiCore/dashboard";
import { useSnackbar } from "notistack";

function FilterField({ model, columnName, label, updateFilter }) {

    const [value, setValue] = useState(null);
    const [filterOptions, setFilterOptions] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        (async () => {
            if (columnName === "year") setValue(new Date().getFullYear());
            try {
                const response = await getSelectorOptions({ model, columnName });
                const { error, data } = response?.data || {};

                if (error) throw { response };

                if (data.length > 0) {
                    setFilterOptions([...new Set(data.map(item => item?.[columnName]))]);
                }

            } catch (err) {
                const { error } = err?.data || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar(`Error al cargar los filtros del gráfico`, { variant: "error" });
                }
            }
        })();
    }, []);

    const ref = useRef();

    return (
        <Autocomplete
            ref={ref}
            disableClearable={columnName === "year"}
            value={value}
            name={columnName}
            options={filterOptions}
            getOptionLabel={(option) => option?.toString()}
            renderInput={(params) => <TextField {...params} label={label} name={columnName} />}
            onChange={(_, value) => {
                updateFilter(ref.current.getAttribute("name"), value);
                setValue(value);
            }}
        />
    )
}

export default memo(FilterField);
