import React, { useEffect } from "react";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import fileDownload from "js-file-download";
import { getArrayOfYears } from "../../utils/datetime";
import { hideLoader, showLoader } from "../Controls/Loader";
import { downloadReport } from "../../apiCore/reports";


export default function DownloadForm({ apiRoute, idReport, reportName }) {
    const { enqueueSnackbar } = useSnackbar();
    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            year: Yup.number().required("Por favor seleccione un año"),
            month: Yup.number().required("Por favor seleccione un mes"),
        }),
        onSubmit: async (values) => {
            try {
                showLoader();
                const response = await downloadReport({
                    idReport,
                    apiRoute,
                    year: values?.year,
                    month: values?.month
                });

                if (response?.data?.error || response?.error) throw { response };

                if (response?.data) {
                    const fileName = (reportName?.trim()?.replace(/\s+/g, "_")?.toLowerCase() || `report_${idReport}`) + "_" + values?.year;
                    fileDownload(response.data, fileName + ".xlsx");
                }

            } catch (error) {
                if (error?.response?.data?.error) {
                    enqueueSnackbar(error.response.data.error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al descargar el reportes", { variant: "error" });
                }
            } finally {
                hideLoader();
            }
        }
    });

    useEffect(() => {
        if (formik.submitCount > 0) {
            formik.setFieldTouched("month", true);
            formik.setFieldTouched("year", true);
        }
    }, [formik.submitCount]);

    return (
        <Box
            className="reportsDownloadForm"
            component="form"
            onSubmit={formik.handleSubmit}
        >
            <Autocomplete
                name="year"
                fullWidth
                options={getArrayOfYears()}
                getOptionLabel={(option) => option?.toString()}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Año"
                        name="year"
                        error={formik.touched.year && Boolean(formik.errors.year)}
                        helperText={formik.touched.year && formik.errors.year}
                    />
                )}
                onChange={(_, value) => formik.setFieldValue("year", value)}
            />
            <Autocomplete
                name="month"
                fullWidth
                options={monthOptions}
                getOptionLabel={(option) => option?.name?.toString()}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Mes"
                        name="month"
                        error={formik.touched.month && Boolean(formik.errors.month)}
                        helperText={formik.touched.month && formik.errors.month}
                    />
                )}
                onChange={(_, value) => formik.setFieldValue("month", value?.id || "")}
            />

            <Button
                fullWidth
                variant="contained"
                type="submit"
                size="large"
            >
                Descargar
            </Button>
        </Box>
    )
}

const monthOptions = [
    {
        id: 1,
        name: "Enero"
    },
    {
        id: 2,
        name: "Febrero"
    },
    {
        id: 3,
        name: "Marzo"
    },
    {
        id: 4,
        name: "Abril"
    },
    {
        id: 5,
        name: "Mayo"
    },
    {
        id: 6,
        name: "Junio"
    },
    {
        id: 7,
        name: "Julio"
    },
    {
        id: 8,
        name: "Agosto"
    },
    {
        id: 9,
        name: "Septiembre"
    },
    {
        id: 10,
        name: "Octubre"
    },
    {
        id: 11,
        name: "Noviembre"
    },
    {
        id: 12,
        name: "Diciembre"
    },
];
