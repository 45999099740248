import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import { useSnackbar } from "notistack";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { createNewRegister, updateRegister } from "../../../apiCore/entity";
import { useNetwork } from "../../../hooks/useNetwork";

export default function QueueQuotes({reload}) {
    // Columnas que se requiere información pero no se deben de mostrar en la tabla.
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const [numberQuotes, setNumberQuotes] = useState(0);

    const eliminarObjetosOffline = async () => {
        localStorage.removeItem("UPLOAD_QUEUE"); 
        verificarEstado();     
    };

    const sincronizateDate = async () => {
        //Se invoca el createNewRegister manualmente, iterando en la longitud del arreglo        
        let array = localStorage.getItem("UPLOAD_QUEUE");
        if(array){
            array = JSON.parse(array);            
            if(array.length > 0 && window.navigator.onLine){
                localStorage.setItem("ONLINE", true);                
                localStorage.setItem("SYNC", false);

                //Sincronizar cotizaciones
                console.log("Sinccronizar cotizaciones");
                await Promise.all(array.map(async (quote)=>{
                    //Se sincronizan unicamente las cotizaciones, para obtener sus respectivos ids y posteriormente registrar las mezclas y sus configuraciones
                    if(quote.model == "quotes" && quote.url.indexOf("create") != -1){
                        const response = await createNewRegister({model: quote.model, data: quote.data});
                        if(response.status == 200){                            
                            let idQuote = response.data.data[0].id;

                            //Se busca en todo el arreglo temporal, los elementos que contengan este uid, para reemplazarlo, concretamente las mezclas
                            array.map(async (mix)=>{
                                if(mix.model == "mixes"){
                                    if(mix.data.quoteId == quote.uid){
                                        mix.data.quoteId = idQuote;
                                    }
                                }

                                return mix
                            })
                        }
                    }
                }));

                //Sincronizacion de mezclas
                console.log("Sinccronizar mezclas")
                await Promise.all(array.map(async (mix)=>{
                    //Se sincronizan unicamente las cotizaciones, para obtener sus respectivos ids y posteriormente registrar las mezclas y sus configuraciones
                    if(mix.model == "mixes" && mix.url.indexOf("create") != -1){
                        const response = await createNewRegister({model: mix.model, data: mix.data});
                        if(response.status == 200){                            
                            let idMix = response.data.data[0].id;

                            //Se busca en todo el arreglo temporal, los elementos que contengan este uid, para reemplazarlo, concretamente las mezclas
                            array.map(async (elementMix)=>{
                                if(elementMix.model == "elementByMix" || elementMix.model == "fertlizerByMix"){
                                    if(elementMix.data.mixId == mix.uid){
                                        elementMix.data.mixId = idMix;
                                    }
                                }

                                //Actualiza los ids de los update de mezclas
                                if(elementMix.model == "mixes" && elementMix.url.indexOf("update") != -1){
                                    if(elementMix.id == mix.uid){
                                        elementMix.id = idMix;
                                    }
                                }

                                return elementMix
                            })
                        }
                    }
                }));                

                //Elemento de la mezlca
                console.log("Sinccronizar elementos en mezclas")
                await Promise.all(array.map(async (elementMix)=>{
                    //Se sincronizan unicamente las cotizaciones, para obtener sus respectivos ids y posteriormente registrar las mezclas y sus configuraciones
                    if(elementMix.model == "elementByMix" && elementMix.url.indexOf("create") != -1){
                        const response = await createNewRegister({model: elementMix.model, data: elementMix.data});                        
                    }
                })); 
                
                //Elemento de la mezlca
                console.log("Sinccronizar fertilizantes")
                await Promise.all(array.map(async (fertlizerByMix)=>{
                    //Se sincronizan unicamente las cotizaciones, para obtener sus respectivos ids y posteriormente registrar las mezclas y sus configuraciones
                    if(fertlizerByMix.model == "fertlizerByMix" && fertlizerByMix.url.indexOf("create") != -1){
                        const response = await createNewRegister({model: fertlizerByMix.model, data: fertlizerByMix.data});                        
                    }
                }));   
                
                //Elemento de la mezlca
                console.log("Actualizacion de mezclas, configuraciones finales")
                await Promise.all(array.map(async (mix)=>{
                    //Se sincronizan unicamente las cotizaciones, para obtener sus respectivos ids y posteriormente registrar las mezclas y sus configuraciones
                    if(mix.model == "mixes" && mix.url.indexOf("update") != -1){
                        const response = await updateRegister({model: mix.model, id: mix.id, data: mix.data});                        
                    }
                }));                   

                console.log("Sinccronización finalizada")
                //Con la transferencia realizada se eliminan de la cola de notificaciones las cotizaciones
                localStorage.removeItem("UPLOAD_QUEUE");     
                localStorage.setItem("SYNC", true);           

                reload();
            }else{
                localStorage.setItem("SYNC", true);
                enqueueSnackbar("Intente de nuevo, error de conexión...", { variant: "error" });
            }
        }

        verificarEstado();
    }

    const verificarEstado = () => {
        let array = localStorage.getItem("UPLOAD_QUEUE");
        let syncAvailable = localStorage.getItem("SYNC");   

        if(array){
            array = JSON.parse(array);
            let quotesNumber = 0;
            array.map((quote)=>{
                if(quote.model === "quotes"){
                    quotesNumber++
                }
            })
            setNumberQuotes(quotesNumber)
            if(window.navigator.onLine && syncAvailable === "true"){
                setOpen(true);
                setOpenWarning(false);
            }else{
                setOpenWarning(true);
                setOpen(false);
            }
        }else{
            setOpenWarning(false);
            setOpen(false);
        }  
    }

    useEffect(()=>{
        setInterval(()=>{
            verificarEstado();                      
        },2500)                
    },[])

    return (
        <Box sx={{ width: 400 }}>
            <Snackbar                
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open}
                onClose={()=>{}}>
                <Alert
                    onClose={()=>{}}
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}
                    action={
                        <Stack spacing={1}>
                          <Button 
                            variant="contained"
                            size="small" 
                            onClick={()=>sincronizateDate()}>
                            Sincronizar
                          </Button> 
                          <Button 
                            variant="contained"
                            size="small" 
                            color="error"
                            onClick={()=>eliminarObjetosOffline()}>
                            Eliminar (<b>{numberQuotes}</b>)
                          </Button>                                                                     
                        </Stack>
                    }>
                    Cotizaciones offline pendiente de sincronizar (<b>{numberQuotes}</b>), sincronice los datos para visualizarlas correctamente
                </Alert>

            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openWarning}>
                <Alert
                    severity="warning"
                    sx={{ width: '100%' }}
                    variant="filled">
                    <AlertTitle>Modo offline</AlertTitle>
                    Cotizaciones pendientes de sincronizar (<b>{numberQuotes}</b>), las cotizaciones serán almacenadas durante 48h
                </Alert>

            </Snackbar>            
        </Box>
    )
}