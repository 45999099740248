import React, { useState, useEffect } from "react";
import Datatable from "../../Controls/Datatable";
import { ENV, } from "../../../utils/constants";
import NewQuoteButton from "../NewQouteButton/NewQuoteButton";
import ActionButtons from "./Buttons/ActionButtons";
import QueueQuotes from "./QueueQuotes";
import api from "../../../../src/axios/axios";
import md5 from "md5";
import { getAllFertilizerWithPrice } from "../../../apiCore/ferilizers";
import { loadFullData } from "../../../apiCore/entity";

/**
 * Renderiza el componente Datatable permitiendo agregar acciones a la columna de acciones del componente.
 * La propiedad ActionsButtons recibee un botón o un grupo de botones y les pasa las propiedades del id de la fila,
 * la función onReload que recarga los datos del Datatable y la propiedad row con los datos de la fila.
 * @returns Componente Datatable para la sección de contizaciones.
 */
export default function DatatableQuote() {
    // Columnas que se requiere información pero no se deben de mostrar en la tabla.    
    const [update, setUpdate] = useState(0);    

    const reload = () => {
        setUpdate(update + 1);
    }

    const filterColumns = ["quoteStatusId", "numberApprovalRequest", "pdfName", "userId"];
    
    useEffect(()=>{
        localStorage.setItem("SYNC", true);

        async function fetchData() {
            //Sincronización inicial de datos
            try{

                //Formulario de registro de cotizaciones
                let res = await api.post("api/autocomplete/loadFullData", { model:"typeOfMix", column:"name", conditions:{} });
                localStorage.setItem(md5("autocompleteLoadFulltypeOfMixname{}"), JSON.stringify(res));
                res = await api.post("api/autocomplete/loadFullData", { model:"customers", column:"name", conditions:{} });
                localStorage.setItem(md5("autocompleteLoadFullcustomersname{}"), JSON.stringify(res));
                res = await api.post("api/autocomplete/loadFullData", { model:"validity", column:"value", conditions:{} });
                localStorage.setItem(md5("autocompleteLoadFullvalidityvalue{}"), JSON.stringify(res));
                res = await api.post("api/autocomplete/loadFullData", { model:"deadline", column:"value", conditions:{} });
                localStorage.setItem(md5("autocompleteLoadFulldeadlinevalue{}"), JSON.stringify(res));

                //Formulario de registro de mezclas
                await getAllFertilizerWithPrice();
                await loadFullData({model: ENV.CLIENT_ROUTES.FERTILIZERSBYELEMENTS.MODEL});
                await loadFullData({model: ENV.CLIENT_ROUTES.ELEMENTS.MODEL});
                await loadFullData({model: ENV.CLIENT_ROUTES.AUTOMATIC_CALCULATION_BY_GRADE.MODEL});
                await loadFullData({model: ENV.CLIENT_ROUTES.FERTILIZERDEFAULT.MODEL});

                //Formulario de costo de mezcla, precarga
                res = await api.get(ENV.API_ROUTES.MAQUILA.GET);
                localStorage.setItem(md5(ENV.API_ROUTES.MAQUILA.GET), JSON.stringify(res));
                res = await api.post("api/autocomplete/loadFullData", { model:"packagingType", column:"name", conditions:{} });
                localStorage.setItem(md5("autocompleteLoadFullpackagingTypename{}"), JSON.stringify(res));
            }catch(err){
                console.log("Error sincronización inicial")
            }
        }

        fetchData();
    },[])

    return (
        <>
            <QueueQuotes 
                reload={reload} />
            <Datatable
                model={ENV.CLIENT_ROUTES.VIEW_QUOTES.MODEL}
                NewRegisterButton={NewQuoteButton}
                ActionsButtons={ActionButtons}
                modelIsView={true}
                filterColumns={filterColumns}
                update={update}/>
        </>
    )
}