import React, { useRef, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import * as Yup from "yup";
import SelectAutocomplete from "../../Controls/SelectAutocomplete";
import MixesForm from "../MixesForm/MixesForm";
import BasicModal from "../../common/BasicModal/BasicModal";
import { useFormik } from "formik";

export default function SelectTypeMixes({ quote, onOpenCloseModal, mix, onReload, availableEdit }) {

    const [open, setOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            mixName: mix?.name || "", // Nombre de la mezcla
            mixType: mix?.type || null, // Objeto con el tipo de mezcla {id: number, name: string, created: date || null}
            typeOfMixId: mix?.typeOfMixId || "", // Id del tipo de mezcla.
        },
        validationSchema: Yup.object({
            mixName: Yup.string().required("Debe ingresar un nombre de mezcla"),
            typeOfMixId: Yup.string().required("Seleccione un tipo de mezcla"),
        }),
        onSubmit: (_) => {
            setOpen(true);
        }
    });

    return (
        <>
            <Box component="form" onSubmit={formik.handleSubmit}>
                <Box p={1}>
                    <TextField
                        value={formik.values.mixName}
                        onChange={formik.handleChange}
                        label="Nombre de la mezcla"
                        name="mixName"
                        fullWidth
                        disabled={!availableEdit}
                        error={formik.touched.mixName && Boolean(formik.errors.mixName)}
                        helperText={formik.touched.mixName && formik.errors.mixName}
                    />
                </Box>
                <Box p={1}>
                    <SelectAutocomplete
                        disabled={mix ? true : false}
                        value={formik.values.typeOfMixId || ""}
                        model="typeOfMix"
                        label="Tipo de mezcla"
                        column="name"
                        name="typeOfMixId"
                        onChange={async (id, _, __, option) => {
                            await formik.setFieldValue("typeOfMixId", id ?? "");
                            await formik.setFieldValue("mixType", option);
                        }}
                        textfieldError={formik?.touched?.typeOfMixId && Boolean(formik?.errors?.typeOfMixId)}
                        textfieldHelpertext={formik?.touched?.typeOfMixId && formik?.errors?.typeOfMixId}
                        getDefaultValue={(option) => {
                            if (option) {
                                formik.setFieldValue("typeOfMixId", option?.id);
                                formik.setFieldValue("mixType", option);
                            }
                        }}
                    />
                </Box>

                <Box
                    p={1}>
                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        type="submit">
                        {!availableEdit ? "Visualizar mezcla" : (mix ? "Editar mezcla" : "Crear mezcla")}
                    </Button>
                </Box>
            </Box>

            <BasicModal
                open={open}
                onClose={() => setOpen(false)}
                fullScreen
                title={`Mezcla ${formik.values.mixType?.name?.toLowerCase()}`}>
                <MixesForm
                    mix={mix}
                    mixType={formik.values.mixType}
                    mixName={formik.values.mixName}
                    quote={quote}
                    onOpenCloseModal={onOpenCloseModal}
                    onReload={onReload}
                    availableEdit={availableEdit}/>
            </BasicModal>
        </>
    )
}
