import React, { useState } from "react";
import { Button, } from "@mui/material";
import SelectTypeMixes from "../SelectTypeMixes/SelectTypeMixes";
import BasicModal from "../../common/BasicModal/BasicModal";

export default function NewMixesButton({ quote, onReload, availableEdit }) {
    const [open, setOpen] = useState(false);
    const [newMix, setMix] = useState(null);

    const onOpenCloseModal = () => {
        setOpen(prevState => !prevState);
    };

    return (
        <>
            <Button
                color="success"
                fullWidth
                className="newMixesBtn"
                variant="contained"
                onClick={onOpenCloseModal}
                disabled={!availableEdit}>
                Nueva mezcla
            </Button>

            <BasicModal
                open={open}
                onClose={onOpenCloseModal}
                title="Seleccionar tipo de mezcla"
                sxDialogContent={{
                    "&.MuiDialogContent-root": {
                        paddingTop: "5px",
                    },
                }}
                maxWidth="md">
                <SelectTypeMixes
                    onOpenCloseModal={onOpenCloseModal}
                    quote={quote}
                    onReload={onReload}
                    availableEdit={availableEdit}
                />
            </BasicModal>
        </>
    );
}
