import axios from "axios";
import { blobToJson } from "../utils/blobreader";

let api = axios.create({});

/**
 * Permite interceptar solictudes al servidor de manera global.
 * @param {Function} callback función que se ejecuta cuando se intercepta un estado 401.
 * 
 */
export const setupAxiosInterceptor = (callback) => {

    const controller = new AbortController();

    // Al request se le asigna un signal con el objetivo de poder cancelar las peticiones.
    api.interceptors.request.use(config => {
        return { ...config, signal: controller.signal }
    })

    api.interceptors.response.use((async response => {
        // Validar si en el response es de tipo blob.
        if ((response?.data instanceof Blob)
            && response?.config?.responseType === "blob"
            && response?.data?.type === "application/json") {
            try {
                // Convertir el archivo blob a tipo json.
                const result = await blobToJson(response.data);

                response.data = result;
            } catch (err) {
                console.error(err);
            }
        }
        return response
    }), async error => {

        // Validar status del response.
        if (error?.response?.status === 401) {
            // Setear usuario a null.
            callback();

            // Cancelar todas las solicitudes que se hayan realizado y que esten pendiente, depues de la primera respuesta de 401.
            controller.abort();
        }
        // Validar si en el response es de tipo blob.
        if ((error?.response?.data instanceof Blob)
            && error?.response?.config?.responseType === "blob"
            && error?.response?.data?.type === "application/json") {
            try {
                // Convertir el archivo blob a tipo json.
                const result = await blobToJson(error.response.data);

                error.response.data = result;
            } catch (err) {
                console.error(err);
            }
        }

        return Promise.reject(error);
    });
}

export default api;