import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Popover, Stack } from "@mui/material";
import { Close, DateRange } from "@mui/icons-material";
import { CssTextField } from "./CustomTextField";
import moment from "moment";

const ddmmyyyyhhmmFormatDate = "DD-MM-YYYY HH:mm";
const yyyymmddhhmmFormatDate = "YYYY-MM-DD HH:mm";

export default function DateTimePickerSelector({ onFilterChange, value, column, isFilter }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [finishDate, setFinishDate] = useState("");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    const id = open ? "simple-popover" : undefined;

    const inputValue = useMemo(() => {
        const formattedStartDate = moment(startDate).format(ddmmyyyyhhmmFormatDate);
        const formattedFinishDate = moment(finishDate).format(ddmmyyyyhhmmFormatDate);
        return (startDate && finishDate) ? `${formattedStartDate} - ${formattedFinishDate}` : (startDate) ? `${formattedStartDate} - dd/mm/aaaa` : (finishDate) ? `dd/mm/aaa - ${formattedFinishDate}` : ""
    }, [startDate, finishDate]);


    useEffect(() => {
        if (!value) {
            setFinishDate("");
            setStartDate("");
        }
    }, [value]);

    return (
        <React.Fragment>
            <CssTextField
                fullWidth
                size="small"
                className="forceHeightInput marginBottom"
                InputProps={{
                    endAdornment: < DateRange />,
                    readOnly: true,
                }}
                placeholder="dd/mm/aaaa - dd/mm/aaaa"
                label={isFilter ? "Filtro " + column?.headerName : column?.headerName}
                value={inputValue}
                InputLabelProps={{
                    shrink: (open || startDate || finishDate) ? true : false,
                }}
                onClick={handleClick}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Stack
                    paddingX={1}
                    paddingY={2}
                    direction="row"
                    overflow="auto"
                    alignItems="center"
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            height: "3rem",
                        }}
                    >
                        <Close />
                    </IconButton>
                    <Stack
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 1,
                        }}
                        direction={{
                            xs: "column",
                            sm: "row"
                        }}
                    >
                        <CssTextField
                            type="datetime-local"
                            size="small"
                            label="Fecha de inicio"
                            value={startDate}
                            onChange={(event) => {
                                if (finishDate && onFilterChange instanceof Function) {
                                    onFilterChange({
                                        startDate: moment(event.target.value).format(yyyymmddhhmmFormatDate),
                                        finishDate: moment(finishDate).format(yyyymmddhhmmFormatDate),
                                    });
                                }
                                setStartDate(event.target.value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <CssTextField
                            disabled={!startDate}
                            type="datetime-local"
                            size="small"
                            label="Fecha fin"
                            value={finishDate}
                            onChange={(event) => {
                                if (onFilterChange instanceof Function) {
                                    onFilterChange({
                                        startDate: moment(startDate).format(yyyymmddhhmmFormatDate),
                                        finishDate: moment(event.target.value).format(yyyymmddhhmmFormatDate),
                                    });
                                }
                                setFinishDate(event.target.value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Stack>
                </Stack>
            </Popover>
        </React.Fragment>
    );
}
