import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SectionContainer from "../common/SectionContainer";
import RenderColumns from "../common/RenderColumns";
import { CustomPaper } from "../common/CustomPaper";
import { UnlabeledTextField } from "../common/UnlabeledTextField";
import { typeOfMixes } from "../../../../utils/constants";

export default function ElementsSection({
    elements,
    show,
    updateElementValue,
    mixType,
    calculateGrade,
    availableEdit,
    calculateColumnWidths,
    automaticGradeCalculation,
}) {

    const elementTableRef = useRef(null);

    useLayoutEffect(() => {
        // Calcular el tamaño de las columnas.
        calculateColumnWidths(elementTableRef);
    }, []);

    return (
        <SectionContainer>
            <Grid
                item
                xs={show ? 3 : 8}
            >
                <TableContainer component={CustomPaper}>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell>
                                    <Typography>Elemento</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className="height-row">
                                <TableCell>
                                    <Typography>
                                        Grado {mixType?.id === typeOfMixes.byFertilizer ? "(%)" : ""}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
            </Grid>
            {show && (
                <Grid
                    item
                    xs={7}>
                    <TableContainer component={CustomPaper}>
                        <Table ref={elementTableRef} className="table-min-width">
                            <TableHead>
                                <TableRow>
                                    <RenderColumns
                                        elements={elements}
                                        Component={ElementItemName}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="height-row">
                                    <RenderColumns
                                        elements={elements}
                                        Component={ElementItemInput}
                                        onChange={updateElementValue}
                                        automaticGradeCalculation={automaticGradeCalculation}
                                        mixType={mixType}
                                        calculateGrade={calculateGrade}
                                        availableEdit={availableEdit}
                                    />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            <Grid
                item
                xs={show ? 2 : 4}
            >
                {/* Se deja vacía */}
            </Grid>

        </SectionContainer>
    );
}

const ElementItemInput = ({ element, onChange, mixType, calculateGrade, availableEdit, automaticGradeCalculation }) => {
    const [value, setValue] = useState(element?.value || 0);

    const handleChange = (e) => {
        setValue(e.target.value);
        if (!e.target.value) {
            onChange(element, 0);
        } else {
            onChange(element, e.target.value);
        }

        // Ejecutar función para calcular automáticamente el valor del fertilizante a partir del grado.
        if (automaticGradeCalculation instanceof Function) automaticGradeCalculation();

    };

    const handleFocus = (e) => {
        if (mixType?.id === typeOfMixes.byGrade && (e.target.value == 0))
            setValue("");
    }

    const handleBlur = (e) => {
        if (mixType?.id === typeOfMixes.byGrade && (e.target.value == 0))
            setValue(0);
    }

    return (
        <TableCell>
            <UnlabeledTextField
                type="number"
                disabled={!availableEdit}
                InputProps={{
                    readOnly: mixType?.id === typeOfMixes.byFertilizer,
                    className: (availableEdit && mixType?.id === typeOfMixes.byGrade) ? "mixesEditBtn" : "mixesNotEditBtn",
                }}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                // value={mixType?.id === typeOfMixes.byGrade ? value : getValue()}
                value={mixType?.id === typeOfMixes.byGrade ? value : calculateGrade(element)}
                size="small"
            />
        </TableCell>
    );
}

const ElementItemName = ({ element }) => {
    return (
        <TableCell
            className="textAlignCenter elementColumn" >
            <Typography>
                {element.name}
            </Typography>
        </TableCell>
    );
}
