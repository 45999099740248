import api from "../axios/axios";

export const getUserInfo = async (email) => {
    try {
        const response = await api.post(`/api/user/userInfo`, { email: email.trim() });
        if (response.status !== 200) throw response;
        return response.data;
    } catch (error) {
        throw error;
    }
}