import { createTheme } from "@mui/material/styles";

export const themeMuiV5 = createTheme({
    palette: {
        background: {
            default: "#fff",
            paper: "#fff",
        },
        primary: {
            main: "#aaaaaa",
            light: "#888888",
            dark: "#aaaaaa",
            contrastText: "#000",
        },
        secondary: {
            main: "#FFF",
            dark: "#fff",
            light: "#fff",
        },
        third: {
            main: "#bbb",
            light: "#bbb",
            dark: "#bbb",
            contrastText: "#bbb"
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === "contained" &&
                        ownerState.color === "primary" &&
                    {
                        backgroundColor: "#e0e0e0",
                        boxDecorationBreak: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                        color: "#000",
                        "&:hover": {
                            backgroundColor: "#e0e0e0",
                        }
                    }),
                }),
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#006530",
                    color: "#fff",
                    position: "relative",
                },
            },
        },
        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#006530",
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#006530",
                    color: "#fff",
                },
                root: {
                    "&.MuiButtonBase-root": {
                        color: "#fff",
                    }
                },
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#006530",
                    color: "#fff",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ":hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.16)",
                    },
                    "&.Mui-selected": {
                        backgroundColor: "rgba(255, 255, 255, 0.16)",
                        ":hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.16)",
                        }
                    },
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    ":hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.16)",
                    },
                    "&.Mui-selected": {
                        backgroundColor: "rgba(255, 255, 255, 0.16)",
                        ":hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.16)",
                        }
                    },
                },
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "#fff",
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#aaa",
                    color: "#303030",
                }
            }
        }
    },
});