import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../components/Login/Login";
import AuthRoutes from "../AuthenticatedRoute/AuthRoutes";
import MenuNavegacion from "../../components/MenuNavegacion/MenuNavegacion";
import NotFound404 from "../../components/HttpState/NotFound404/NotFound404";
import ApprovedQuote from "../../components/Quotes/ExternalControl/ApprovedQuote";
import DeniedQuote from "../../components/Quotes/ExternalControl/DeniedQuote";
import { ENV, roles } from "../../utils/constants";
import NewQuoteButton from "../../components/Quotes/NewQouteButton/NewQuoteButton";
import ProtectedRoutes from "../ProtectedRoutes/ProtecteRoutes";
import AcessControlRoute from "../AccessControlRoute/AcessControlRoute";

function AppRoutes() {
    return (
        <Routes>
            <Route path={ENV.CLIENT_ROUTES.LOGIN} element={<Login />} />
            <Route element={<AuthRoutes />}>

                {/* ----- Inicio Opciones del menú de usuarios ----- */}
                <Route element={<ProtectedRoutes allowedRoles={[roles.administrador]} />}>
                    <Route path={ENV.CLIENT_ROUTES.USERS.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.USERS.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.USERS.TITLE_PAGE}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.GROUPS.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.GROUPS.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.GROUPS.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.GROUPS.MODEL}
                        />
                    } />
                    <Route path={ENV.CLIENT_ROUTES.ACCESSCONTROL.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.ACCESSCONTROL.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.ACCESSCONTROL.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.ACCESSCONTROL.MODEL}
                        />
                    } />
                </Route>
                {/* ----- Fin Opciones del menú de usuarios ----- */}

                <Route element={<AcessControlRoute />}>
                    <Route path={ENV.CLIENT_ROUTES.DASHBOARD.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.DASHBOARD.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.DASHBOARD.TITLE_PAGE}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.CUSTOMERS.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.CUSTOMERS.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.CUSTOMERS.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.CUSTOMERS.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.VIEW_QUOTES.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.VIEW_QUOTES.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.VIEW_QUOTES.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.VIEW_QUOTES.MODEL}
                            NewRegisterButton={NewQuoteButton}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.FERTILIZERS.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.FERTILIZERS.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.FERTILIZERS.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.FERTILIZERS.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.ELEMENTS.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.ELEMENTS.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.ELEMENTS.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.ELEMENTS.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.FERTILIZERSBYELEMENTS.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.FERTILIZERSBYELEMENTS.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.FERTILIZERSBYELEMENTS.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.FERTILIZERSBYELEMENTS.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.MAQUILA_COST.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.MAQUILA_COST.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.MAQUILA_COST.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.MAQUILA_COST.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.ZONES.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.ZONES.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.ZONES.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.ZONES.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.PACKAGINGTYPE.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.PACKAGINGTYPE.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.PACKAGINGTYPE.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.PACKAGINGTYPE.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.FERTILIZERDEFAULT.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.FERTILIZERDEFAULT.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.FERTILIZERDEFAULT.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.FERTILIZERDEFAULT.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.DEADLINE.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.DEADLINE.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.DEADLINE.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.DEADLINE.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.ADDITIONAL_INFO.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.ADDITIONAL_INFO.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.ADDITIONAL_INFO.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.ADDITIONAL_INFO.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.ADDITIONAL_PAGES.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.ADDITIONAL_PAGES.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.ADDITIONAL_PAGES.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.ADDITIONAL_PAGES.MODEL}
                        />
                    } />                    

                    <Route path={ENV.CLIENT_ROUTES.USERS_INVENTORY.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.USERS_INVENTORY.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.USERS_INVENTORY.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.USERS_INVENTORY.MODEL}
                        />
                    } />                    

                    <Route path={ENV.CLIENT_ROUTES.VALIDITY.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.VALIDITY.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.VALIDITY.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.VALIDITY.MODEL}
                        />
                    } />

                    <Route path={ENV.CLIENT_ROUTES.PROFITABILITY.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.PROFITABILITY.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.PROFITABILITY.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.PROFITABILITY.MODEL}
                            orderId={ENV.CLIENT_ROUTES.PROFITABILITY.ORDER_ID}
                        />
                    } />   
                    <Route path={ENV.CLIENT_ROUTES.DISCOUNT_RANGES_BY_ROLS.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.DISCOUNT_RANGES_BY_ROLS.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.DISCOUNT_RANGES_BY_ROLS.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.DISCOUNT_RANGES_BY_ROLS.MODEL}
                            orderId={ENV.CLIENT_ROUTES.DISCOUNT_RANGES_BY_ROLS.ORDER_ID}
                        />
                    } />                                        

                    <Route path={ENV.CLIENT_ROUTES.REPORTS.PATH} element={
                        <MenuNavegacion
                            ContentComponent={ENV.CLIENT_ROUTES.REPORTS.COMPONENT}
                            titlePage={ENV.CLIENT_ROUTES.REPORTS.TITLE_PAGE}
                            model={ENV.CLIENT_ROUTES.REPORTS.MODEL}
                        />
                    } />
                </Route>

                <Route path="/approved/:idQuote/:mailUser/:requestId" element={
                    <MenuNavegacion
                        ContentComponent={ApprovedQuote}
                        titlePage="Aprobar Cotización"
                    />
                } />

                <Route path="/denied/:idQuote/:mailUser/:requestId" element={
                    <MenuNavegacion
                        ContentComponent={DeniedQuote}
                        titlePage="Rechazar Cotización"
                    />
                } />                

                <Route path="*" element={
                    <MenuNavegacion
                        ContentComponent={NotFound404}
                        titlePage="Not found"
                    />
                } />
            </Route>
        </Routes>
    )
}

export default AppRoutes