import api from "../axios/axios";
import md5 from "md5";

export const getAllFertilizerWithPrice = async function () {
    const hash = md5("getAllFertilizerWithPrice");
    try {
        const response = await api.get("api/Fertilizers");
        localStorage.setItem(hash, JSON.stringify(response));
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        let res = localStorage.getItem(hash);
        if(res){
            return JSON.parse(res);
        }else{
            throw error;
        }   
    }
}

// Consulta la lista de fertilizantes con precios actualizados.
export const getAllFertilizerWithPriceUpdate = async function () {
    const hash = md5("getAllFertilizerWithPriceUpdate");
    try {
        const response = await api.get("api/Fertilizers/fertilizersWithPriceUpdate");
        localStorage.setItem(hash, JSON.stringify(response));
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        let res = localStorage.getItem(hash);
        if(res){
            return JSON.parse(res);
        }else{
            throw error;
        }   
    }
}

// Consulta la lista de fertilizantes que no tienen asignada una composición.
export const getAllFertilizerWithoutComposition = async function () {
    const hash = md5("getAllFertilizerWithoutComposition");
    try {
        const response = await api.get("api/Fertilizers/fertilizersWithoutComposition");
        localStorage.setItem(hash, JSON.stringify(response));
        if (response.status !== 200) throw response;
        return response;
    } catch (error) {
        let res = localStorage.getItem(hash);
        if(res){
            return JSON.parse(res);
        }else{
            throw error;
        }   
    }
}