import React, { useState, useEffect, useRef, } from "react";
import { Box, Typography, } from "@mui/material";
import { useSnackbar } from "notistack";

import { structure, loadModelData } from "../../apiCore/dataTable";
import { showLoader, hideLoader } from "../Controls/Loader";
import jsonTraslate from "../../translate.json";
import DataOnePage from "./DataOnePage";
import Form from "./Form";

export default function Datatable({ NewRegisterButton, ActionsButtons, model = null, typeDatatable = null, modelIsView = false, filterColumns = [], update=null, orderId = null }) {
  // Se organiza de esta manera con el objetivo de que al cambiar de modelo de datos, la variables de estado
  // se reinicien a su estado inicial.  
  return (
    <DatatableWrapper
      key={model}
      NewRegisterButton={NewRegisterButton}
      ActionsButtons={ActionsButtons}
      model={model}
      typeDatatable={typeDatatable}
      modelIsView={modelIsView}
      filterColumns={filterColumns}
      update={update}
      orderId={orderId}
    />
  );
}

function DatatableWrapper({ NewRegisterButton, ActionsButtons, model, typeDatatable, modelIsView, filterColumns = [], update=null, orderId }) {

  console.log("ORDER VALUE")
  console.log(orderId)

  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [length, setLength] = useState(0);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [openAddItem, setOpenAddItem] = useState(false);

  const [filtersValues, setFiltersValues] = useState(null);
  const [orderValue, setOrderValue] = useState(orderId ? {field:"id", "order":orderId}:null);

  const [orderArray, setOrderArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isFilterApplied = useRef(false); // Controlar la aplicación de filtros.
  const firstRenderControlPage = useRef(true); // Controlar la ejecución del useEffect de page, perPage y orderValue en el primer renderizado.
  const firstRenderControlFilter = useRef(true); // Controlar la ejecución del useEffect de filterValues en el primer renderizado.

  const currentFilterValuesRef = useRef(null); // Referencia a los valores de filtros actualizados.
  const currentOrderValueRef = useRef(null); // Referencia a los valores de orden de columna actualizados.

  const generateColumns = async (errorCallback) => {

    if (model) {
      //Se consulta la estructura del modelo que se desea analizar
      const res = await structure(model);

      if (res?.data?.error) {
        enqueueSnackbar(res.data.error, { variant: "error", });
        errorCallback();
      } else {
        //enqueueSnackbar(res.data.message, {variant:"success"});
        //Consulta valida de la estructura
        var columnsTemporales = new Array();
        var tempValues = {};
        //Se le resta 100, del id, y 20 de los border de las tablas
        var widthAllElement = window.innerWidth - 116;
        var width = 0;
        var type = "text";
        var initValue = "";

        for (var i = 0; i < res.data.structure.length; i++) {
          // Filtrar columnas que no se deben de renderizar.
          const columnName = res.data.structure[i].COLUMN_NAME;
          // Saltar las columnas que coincidan con el filtro
          if (filterColumns.find(column => column === columnName)) { continue };

          //Longitud por defecto de las columnas 300
          //Columnas que deben rellenar el espacio, son todas menos 3, id, internal consecutive y enable (columnas por default en todas las tablas)
          //QUITAMOS LAS COLUMNAS DE 100 QUE SON 3
          width = widthAllElement / (res.data.structure.length - 1);

          if (width < 150) {
            width = 150;
          }

          var filter = {};
          if (
            res.data.structure[i].COLUMN_NAME == "id" ||
            res.data.structure[i].COLUMN_NAME == "internalConsecutive"
          ) {
            initValue = "";
            width = 100;
            filter = {
              available: false,
            };
          } else if (res.data.structure[i].OPTIONS) {
            initValue = [];
            type = "select";
            filter = {
              available: true,
              type: "select",
              options: res.data.structure[i].OPTIONS,
            };
          } else if (res.data.structure[i].DATA_TYPE == "float") {
            initValue = "";
            type = "float";
            filter = {
              available: true,
              type: "float",
            };
          } else if (
            res.data.structure[i].DATA_TYPE == "int" &&
            res.data.structure[i].COLUMN_NAME != "id"
          ) {
            initValue = "";
            type = "int";
            filter = {
              available: true,
              type: "int",
            };
          } else if (
            res.data.structure[i].DATA_TYPE == "varchar" ||
            res.data.structure[i].DATA_TYPE == "String"
          ) {
            initValue = "";
            type = "text";
            filter = {
              available: true,
              type: "text",
            };
          } else if (
            res.data.structure[i].DATA_TYPE == "datetime2" ||
            res.data.structure[i].DATA_TYPE == "Date"
          ) {
            type = "date";
            initValue = "";
            filter = {
              available: true,
              type: "date",
            };
          } else if (res.data.structure[i].DATA_TYPE === "Boolean" || res.data.structure[i].DATA_TYPE === "bit") {
            type = "bool";
            initValue = false;
            // width = 150;
            filter = {
              available: true,
              type: "bool",
            };
          }
          else if (res.data.structure[i].DATA_TYPE == "Array") {
            type = "array";
            initValue = [];
            width = 200;
            filter = {
              available: false,
              type: "text",
            };
          }

          var headerName = "";

          // Datos de traducción de la columna, tipo de validación y array de validaciones.
          const { columnNameTranslation, validationType, validations } = res?.data?.structure[i] || {};

          if (columnNameTranslation) {
            headerName = columnNameTranslation;
          }

          if (!headerName) {
            if (
              !jsonTraslate[model] ||
              !jsonTraslate[model][res.data.structure[i].COLUMN_NAME]
            ) {
              headerName = model + "." + res.data.structure[i].COLUMN_NAME;
            } else {
              headerName = jsonTraslate[model][res.data.structure[i].COLUMN_NAME];
            }
          }

          var jsonConfig = null;
          if (res.data.structure[i].CONFIG) {
            jsonConfig = JSON.parse(res.data.structure[i].CONFIG);
          }

          var objPush = {
            // required: res.data.structure[i].REQUIRED,
            required: res.data.structure[i].IS_NULLABLE === "NO" ? true : false,
            config: jsonConfig,
            component: res.data.structure[i].COMPONENT,
            object: res.data.structure[i],
            field: res.data.structure[i].COLUMN_NAME,
            headerName: headerName,
            width: width,
            type: type,
            filter: filter,
            options: res.data.structure[i].OPTIONS
              ? res.data.structure[i].OPTIONS
              : null,
            value: initValue,
            validationType,
            validations,
          };

          columnsTemporales.push(objPush);
          tempValues[res.data.structure[i].COLUMN_NAME] = objPush;
        }

        // Evitar ejecutar el useEffect al cargar las columnas al inicio.
        firstRenderControlFilter.current = true;

        // Acumular el valor de los filtros actuales cuando se haga solicitudes al servidor.
        // setFiltersValues({ ...tempValues, ...filtersValues });
        currentFilterValuesRef.current = tempValues;
        setFiltersValues(tempValues);

        // Permite setear el valor de las columnas al inicio de la carga del componente.
        // if (columns.length === 0) setColumns(columnsTemporales);
        setColumns(columnsTemporales);
      }
    }
  };

  const loadData = async (
    model,
    page,
    perPage,
    filtersValuesArray,
    orderValue,
    callback
  ) => {
    if (model) {
      //Se consulta la estructura del modelo que se desea analizar

      const res = await loadModelData(
        model,
        page,
        perPage,
        filtersValuesArray,
        typeDatatable,
        orderValue,
      );

      if (!res) return; // Validar si existe un respuesta del servidor.

      if (res?.data?.error) {
        enqueueSnackbar(res.data.error, { variant: "error", });
        callback();
      } else {
        setRows(res?.data?.data);
        setLength(res?.data?.length);
        callback();
      }
    }
  };

  const lodaDataCleanFilters = (callback) => {
    loadData(model, page, perPage, getFilterValues(), currentOrderValueRef.current, callback);
  }

  /**
   * Controla la actualización de los filtros.
   * @param {Function} callback función que se ejecuta para actualizar el valor de los filtros.
   */
  const onFilterValuesChange = (callback) => {
    isFilterApplied.current = true;
    setPage(1);
    callback();
  }

  /**
   * Actualiza el número de página que se desea consultar al servidor.
   * @param {Number} numberOfPage nuevo número de página.
   */
  const onPageNumberChange = (numberOfPage) => {
    isFilterApplied.current = false;
    setPage(numberOfPage);
  }

  /**
   * Actualiza el número de registros que se muestran por página.
   * @param {Number} numberOfRecordsPerPage nuevo número de registros por página. 
   */
  const onNumberOfRecordsPerPageChange = (numberOfRecordsPerPage) => {
    isFilterApplied.current = false;
    setPerPage(numberOfRecordsPerPage);
    if (page !== 1) {
      setPage(1);
    };
  }

  /**
   * Actualiza los valores del campo y dirección de orden.
   * @param {String} field - Nombre de la columna
   * @param {String} order - Dirección de orden, puede ser "ASC" o "DESC" 
   */
  const handleOrderValueChange = ({ field, order }) => {
    currentOrderValueRef.current = { field, order };
    setOrderValue({ field, order });
  }

  // Controla el cambio de del modelo.
  useEffect(() => {
    if (model) {
      currentOrderValueRef.current = orderValue;
      setIsLoading(true);
      setColumns([]);
      showLoader();
      (async () => {
        try {
          await generateColumns(hideLoader);
          await loadData(model, page, perPage, getFilterValues(), currentOrderValueRef.current, hideLoader)
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          hideLoader();
          if (!error || error?.message === "canceled") return; // La petición se canceló.

          if (error?.response?.data?.error) {
            enqueueSnackbar(error.response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar("Error al cargar los datos", { variant: "error" });
          }
        }
      })();
    }
  }, [model, update]);

  // Controla el cambio de página, registros por página y orden de las columnas.
  useEffect(() => {
    (async () => {
      try {
        if (isFilterApplied.current) {
          isFilterApplied.current = false;
          return;
        };

        if (firstRenderControlPage.current) {
          firstRenderControlPage.current = false;
        } else {
          showLoader();
          await loadData(model, page, perPage, getFilterValues(), currentOrderValueRef.current, hideLoader);
        }
      } catch (error) {
        hideLoader();
        if (!error || error?.message === "canceled") return; // La petición se canceló.

        if (error?.response?.data?.error) {
          enqueueSnackbar(error.response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar("Error al cargar los datos", { variant: "error" });
        }
      }
    })();
  }, [page, perPage, orderValue]);

  // Controla el cambio de los filtros.
  useEffect(() => {
    (async () => {
      try {
        if (firstRenderControlFilter.current) {
          firstRenderControlFilter.current = false;
        } else {
          showLoader();
          await loadData(model, page, perPage, getFilterValues(), currentOrderValueRef.current, hideLoader);
        }
      } catch (error) {
        hideLoader();
        if (!error || error?.message === "canceled") return; // La petición se canceló.

        if (error?.response?.data?.error) {
          enqueueSnackbar(error.response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar("Error al cargar los datos", { variant: "error" });
        }
      }
    })();
    isFilterApplied.current = false;
  }, [filtersValues]);

  const getFilterValues = () => {
    var filtersValuesClean = {};

    // Obtener el valor actual de los filtros.
    let currentFiltersValue = currentFilterValuesRef.current;


    if (!currentFiltersValue) return filtersValuesClean;

    Object.keys(currentFiltersValue).forEach(key => {
      const type = currentFiltersValue[key].type;
      // Validar la propiedad type: select, text y bool.
      // En default se validan los tipos int, float y array, según el valor de la propiedad value.
      switch (type) {
        case "select":
          if (currentFiltersValue[key]?.value?.length) {
            filtersValuesClean[key] = currentFiltersValue[key];
          }
          break;
        case "text":
          if (currentFiltersValue[key]?.value) {
            filtersValuesClean[key] = currentFiltersValue[key];
          }
          break;
        case "bool":
          // El campo filterReset se crea cuando el usuario de clic en los campos de filtro de tipo boolean.
          // Se Evalua si el campo filterReset se ha creado en el objeto de filtros.
          if (currentFiltersValue[key]?.filterReset === undefined) break;
          // En caso de que el campo filterReset si existe, valida si ha este se ha actualizado cuando el usuario
          // dio click en el boton de 'Borrar filtros'.
          if (currentFiltersValue[key]?.filterReset) break;
          // Asigna los valores al filtro en caso de que se haya cambiado el estado por parte del usuario.
          filtersValuesClean[key] = currentFiltersValue[key];
          break;
        default:
          if (currentFiltersValue[key]?.value) {
            filtersValuesClean[key] = currentFiltersValue[key];
          }
          break;
      }

    });

    return filtersValuesClean;
  };

  if (!model) return (
    <Box
      className="whiteLetter">
      <Typography
        className="textAlignCenter"
        variant="h2">
        Modelo no definido
      </Typography>
    </Box>
  );

  if (isLoading) return <Box className="backgroundPlain" />

  return (
    <Box
      className="backgroundPlain displayFlex flexDirectionColumn">
      {columns.length > 0 && (
        <>
          <DataOnePage
            model={model}
            rows={rows}
            columns={columns}
            filtersValues={filtersValues}
            setFiltersValues={setFiltersValues}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            orderArray={orderArray}
            length={length}
            setOrderArray={setOrderArray}
            addItem={() => {
              setOpenAddItem(true);
            }}
            loadData={() => {
              showLoader();
              lodaDataCleanFilters(hideLoader);
            }}
            typeDatatable={typeDatatable}
            handleOrderValueChange={handleOrderValueChange}
            onFilterValuesChange={onFilterValuesChange}
            onNumberOfRecordsPerPageChange={onNumberOfRecordsPerPageChange}
            onPageNumberChange={onPageNumberChange}
            NewRegisterButton={NewRegisterButton}
            ActionsButtons={ActionsButtons}
            modelIsView={modelIsView}
          />
          <Form
            setOpenAddItem={setOpenAddItem}
            open={openAddItem}
            model={model}
            columns={columns}
            close={(store) => {
              setOpenAddItem(false);
              if (store) {
                showLoader();
                lodaDataCleanFilters(hideLoader);
              }
            }}
          />
        </>
      )}
    </Box>
  );
}
