import React from "react";
import Datatable from "../../Controls/Datatable";

import NewGroupsButton from "./NewGroupsButton";
import GroupActionsButtons from "./GroupActionsButtons";
import { ENV } from "../../../utils/constants";

export default function DatatableGroups() {
    return (
        <Datatable
            model={ENV.CLIENT_ROUTES.GROUPS.MODEL}
            NewRegisterButton={NewGroupsButton}
            ActionsButtons={GroupActionsButtons}
            modelIsView={true} // Es modelo de users no es una vista, pero se coloca en true para desactivar la opción de editar.
        />
    )
}
