import React, { useState } from "react";
import { Button, Dialog } from "@mui/material";
import { Add } from "@mui/icons-material";

import QuoteStepper from "../QuoteStepper/QuoteStepper";
import { Transition } from "../../common/Transition/Transition";

export default function NewQuoteButton({ onReload }) {
    const [open, setOpen] = useState(false);

    const onOpenCloseModal = () => {
        let stateSync = localStorage.getItem("SYNC");
        if(open){
            localStorage.setItem("SYNC", true);
        }else{
            localStorage.setItem("SYNC", false);
        }        

        //El siguiente estado del formulario es abierto
        //Se verifica el estado de conexión, en este momento para en caso de no tener conexión se activa el modo offline
        if(window.navigator.onLine){
            localStorage.setItem("ONLINE", true);
        }else{
            localStorage.setItem("ONLINE", false);
        }

        setOpen(prevState => !prevState);
    };

    return (
        <>
            <Button
                color="success"
                fullWidth
                variant="contained"
                className="heightButton"
                endIcon={<Add />}
                onClick={onOpenCloseModal}>
                Nueva cotización
            </Button>

            <Dialog
                open={open}
                onClose={onOpenCloseModal}
                fullScreen
                TransitionComponent={Transition}>
                <QuoteStepper onClose={onOpenCloseModal} onReload={onReload} />
            </Dialog>
        </>
    )
}