import React from "react";
import MixesTableItem from "./MixesTableItem";

export default function MixesTableList({ elements,  onReload, availableEdit, mixes = [], profit }) {
    return mixes.map((mix, index) => (
        <MixesTableItem
            key={mix?.id ?? index}
            mix={mix}
            elements={elements}
            onReload={onReload}
            availableEdit={availableEdit}
            profit={profit}
        />
    ));
}
