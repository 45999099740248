import axios from "axios";
import { handleResponse } from "./handleResponse";

export const sendCode = async (email) => {
    try {
        const res = await axios.post(`api/session/sendCode`, { email });
        return handleResponse(res);
    } catch (error) {
        return handleResponse(error?.response);
    }

}

export const validateCode = async (email, code) => {
    try {
        const res = await axios.post(`api/session/validateCode`, { email, code });
        return handleResponse(res);
    } catch (error) {
        return handleResponse(error?.response);
    }

}

export const requestAccess = async (userData) => {
    try {
        const response = await axios.post(`/api/session/requestAccess`, { user: userData });
        if (response.status >= 400) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}

export const validateLoginWithMicrosoft = async (email) => {
    try {
        const response = await axios.post(`/api/session/microsoftLogin`, { email });
        if (response.status >= 400) throw response;
        return response;
    } catch (error) {
        throw error;
    }
}