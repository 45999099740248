import React, { useState, useEffect, useRef } from "react";
import {
  Switch,
  Popper,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import { CssTextField } from "../Controls/CustomTextField";
import { updateRegister } from "../../apiCore/dataTable";
import { useSnackbar } from "notistack";
import CurrencyFormat from "react-currency-format";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { getFile } from "../../apiCore/dwonload";

import "../../custom.css";
import { dataTypes } from "../../utils/constants";

/*
Tipo de datos validos en los componentes dinamicos
1. text
2. bool
3. select
4. date
*/

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export default function CellTable({ model, row, column, modelIsView, permissions }) {
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(null);
  const [booleanValue, setBooleanValue] = useState(false); // Controlar el estado de campos tipo boolean.

  const inputElement = useRef();

  const formatDate = (date) => {
    return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
  }

  const callUpdate = async (valueToUpdate, columnToUpdate) => {
    var valueUpdate = null;

    if (
      column.type === dataTypes.text ||
      column.type === dataTypes.boolean ||
      column.type === dataTypes.float ||
      column.type === dataTypes.int ||
      column.type === dataTypes.number ||
      column.type === dataTypes.date
    ) {
      valueUpdate = valueToUpdate;
    } else if (column.type === dataTypes.select) {
      if (valueToUpdate) {
        valueUpdate = valueToUpdate.id;
      } else {
        valueUpdate = null;
      }
    }

    // Validar si el campo el de tipo date y el valor ingresado es una fecha valida.
    if (column.type === dataTypes.date && !moment(valueToUpdate).isValid()) {
      return enqueueSnackbar("El valor de fecha seleccionado no es valido.", { variant: "error", });
    };

    if (columnToUpdate) {
      const res = await updateRegister(
        model,
        columnToUpdate,
        row.id,
        valueUpdate
      );
      if (res.data.error) {
        enqueueSnackbar(res.data.error, { variant: "error" });
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });
      }
    } else {
      enqueueSnackbar("Valor invalido", { variant: "error", });
    }
  };

  useEffect(() => {
    if (column.type === dataTypes.select && column.options) {
      //Es un objeto de la lista de seleccion de opciones
      // Se valida si el valor del campo no sea null, en caso se serlo se actualiza con un valor de null.
      if (!row?.[column?.field]) {
        setValue(null);
      } else {
        for (var i = 0; i < column.options.length; i++) {
          if (column.options[i].id === row[column.field]) {
            setValue(column.options[i]);
          }
        }
      }
    }
    else if (column.type === dataTypes.boolean) {
      // Actualizar el valor de los campos de tipo boolean.
      setBooleanValue(row[column.field])
    }
    else {
      setValue(row[column.field]);
    }
  }, [row, column]);

  return (
    <>
      {(!edit || column.field === "id" || column.field === "created") && (
        <Tooltip
          title={
            column.type === dataTypes.select
              ? value
                ? value.name
                : "--"
              : value
                ? value.toString()
                : "--"
          }
        >
          <div
            className="cellTable cellTableFontSize floatLeft fullWidth"
            onDoubleClick={() => {
              if (
                column.type !== dataTypes.boolean &&
                column.type !== dataTypes.array &&
                column.field !== "created"
              ) {
                // Si es un modelo basado en una vista, no se permite activar la edición de datos.
                if (!modelIsView && permissions?.editPermission) {
                  setEdit(true);
                };
                //setTimeout(function(){
                //    inputElement.current.focus();
                //},10)
              }
            }}>
            {column.type === dataTypes.date &&
              moment(new Date(value ? value : null)).format(
                "DD/MM/YYYY HH:mm:ss")
            }
            {column.type === dataTypes.int && value && (
              <>
                <CurrencyFormat
                  value={value}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </>
            )}
            {column.type === dataTypes.float && value && (
              <>
                <CurrencyFormat
                  value={value}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </>
            )}
            {column.type === dataTypes.text && (
              <>
                {(value && value.toString() != "" && value?.toString().indexOf("urlFile::") == -1) && (<>{value}{column.component}</>)}
                {(value && value.toString() != "" && value?.toString().indexOf("urlFile::") != -1) && (<>
                  <Button
                    size="small"
                    color="info"
                    className="littleSmallButton"
                    variant="contained"
                    onClick={async () => {
                      //Procedimiento de descarga de archivo
                      await getFile({ path: value.replace("urlFile::","") });
                    }}>
                      Ver archivo
                  </Button>
                </>)}
              </>
            )}
            {column.type === dataTypes.array && column.component === "upload" && (
              <>
                {value && value.length > 0 && (
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          size="small"
                          className="smallButton"
                          variant="contained"
                          {...bindTrigger(popupState)}>
                          Adjuntos
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          {value.map((val) => (
                            <MenuItem onClick={() => window.open(val)}>
                              ...{val.substring(val.length - 40, val.length)}
                            </MenuItem>
                          ))}
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                )}
                {!value && <p>Sin adjuntos</p>}
              </>
            )}
            {column.type === dataTypes.select && value?.name}
            {column.type === dataTypes.boolean && (
              <Switch
                disabled={modelIsView || !permissions?.editPermission}
                checked={booleanValue}
                value={booleanValue}
                size="small"
                onChange={(_, _checked) => {
                  setBooleanValue(_checked);
                  callUpdate(_checked, column.field);
                }}
              />
            )}
          </div>
        </Tooltip>
      )}
      {edit &&
        column.field !== "id" &&
        column.field !== "created" &&
        (column.type === dataTypes.float ||
          column.type === dataTypes.int ||
          column.type === dataTypes.text ||
          column.type === dataTypes.boolean) && (
          <div
            className="cellTable floatLeft fullWidth"
          >
            <CssTextField
              inputRef={inputElement}
              className="noPaddingField heighFieldSelect"
              autoFocus
              fullWidth
              size="small"
              value={value ? value.toString() : ""}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onBlur={() => {
                setEdit(false);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  setEdit(false);
                  callUpdate(value, column.field);
                }
              }}
              onKeyDown={(ev) => {
                if (ev.key === "Escape") {
                  ev.preventDefault();
                  setEdit(false);
                }
              }}
            ></CssTextField>
          </div>
        )}
      {edit &&
        column.field !== "id" &&
        column.field !== "created" &&
        column.type === dataTypes.date && (
          <div
            className="cellTable floatLeft fullWidth"
          >
            <input
              ref={inputElement}
              type="datetime-local"
              className="noPaddingField heighFieldSelect styleButtonSelect cellTableBtnEditDate"
              autoFocus
              onFocus={(e) => e.target.style.borderWidth = "2px"}
              value={formatDate(value)}
              onChange={(event) => {
                const newDate = formatDate(event.target.value);

                // Validar que el nuevo valor seleccionado sea una fecha valida.
                if (!moment(newDate).isValid()) return;

                setValue(formatDate(event.target.value));
              }}
              onBlur={() => setEdit(false)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  callUpdate(value, column.field);
                  setEdit(!edit);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Escape") {
                  event.preventDefault();
                  setEdit(!edit);
                }
              }}
            />
          </div>
        )}
      {edit &&
        column.field !== "id" &&
        column.field !== "created" &&
        column.type === dataTypes.select && (
          <div
            className="cellTable floatLeft fullWidth"
          >
            {column.options.length > 0 && (
              <Autocomplete
                isOptionEqualToValue={(option, value) => option?.id === value?.id || value === "" || value === null}
                PopperComponent={PopperMy}
                value={value ?? ""}
                onBlur={() => {
                  setTimeout(function () {
                    setEdit(false);
                  }, 10);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    setEdit(false);
                  }
                }}
                onChange={(_, newValue) => {
                  setEdit(false);
                  setValue(newValue);
                  callUpdate(newValue, column.field);
                }}
                disableCloseOnSelect
                options={column.options}
                getOptionLabel={(option) => {
                  var stringReturn = option.name ? option.name : "";
                  return stringReturn;
                }}
                variant="outlined"
                onKeyDown={(ev) => {
                  //console.log(ev);
                  //setEdit(false);
                }}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    inputRef={inputElement}
                    className="noPaddingField heighFieldSelect styleButtonSelect"
                    placeholder={column.headerName}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            )}
          </div>
        )}
    </>
  );
}