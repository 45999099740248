import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import { CssTextField } from "../../CustomTextField";
import { debounce } from "../../../../utils/debouce";


export default function Pagination(props) {
    const { page, perPage, onPageNumberChange, length } = props;

    const pagesCount = Math.ceil(length / perPage);

    return (
        <Box p={1}>
            <Grid
                container
                spacing={1}
                className="fullWidth"
                alignContent="center"
            >
                {(((page * perPage - perPage)) <= length && (length > perPage)) &&
                    <>
                        <Grid item xs md={5} sm>
                            <PaginationInput
                                page={page}
                                pagesCount={pagesCount}
                                onPageNumberChange={onPageNumberChange}
                                length={length}
                            />
                        </Grid>

                        <Grid
                            item
                            xs
                            md={7}
                            sm
                            className="fullWidth"
                        >
                            <ButtonGroup
                                size="small"
                                orientation="horizontal"
                                className="fullWidth"
                            >
                                <PaginationButton
                                    onClick={() => {
                                        if (page > 1) {
                                            onPageNumberChange(page - 1);
                                        }
                                    }}

                                    icon={<ArrowBackIosNewIcon />}
                                />

                                <PaginationButton
                                    onClick={() => {
                                        if ((page * perPage) < length) {
                                            onPageNumberChange(page + 1);
                                        }
                                    }}
                                    icon={< ArrowForwardIosIcon />}
                                />
                            </ButtonGroup>
                        </Grid>
                    </>
                }

                <Grid
                    item
                    xs="auto"
                    md={12}
                    className="paginationLabelProgressContainer"
                >
                    <PaginationLabelProgress
                        page={page}
                        perPage={perPage}
                        length={length}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export const PaginationButton = (props) => {
    const { icon, onClick } = props;

    return (
        <div
            className="floatRight fullWidth"
        >
            <Button
                variant="contained"
                className="heightButton paginationBtn"
                size="small"
                onClick={onClick}
            >
                {icon && (icon)}
            </Button>
        </div>
    )
}

export const PaginationLabelProgress = (props) => {
    const { page, perPage, length } = props;

    return (
        <div
            className="paginationLabelProgressWrapper"
        >
            <b>{((page - 1) * perPage) + 1}-{(page * perPage) <= length ? (page * perPage) : length}</b> de <b>{length}</b>
        </div>
    )
}

export const PaginationInput = (props) => {
    const { page, pagesCount, onPageNumberChange, length } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [inputValue, setInputValue] = useState(page);

    const handleChangeDebounce = useCallback(debounce((value) => {
        if (value && value > 0 && value <= pagesCount) {
            onPageNumberChange(Number(value));
        }
    }, 400), [pagesCount]);

    const handleChange = (e) => {
        setInputValue(e.target.value);
        if ((e.target.value > pagesCount || e.target.value <= 0) && e.target.value !== "") {
            enqueueSnackbar(`Por favor digite un valor entre 1 y ${pagesCount}`, { variant: "error" });
        };
        handleChangeDebounce(e.target.value);
    };

    const handleBlur = (e) => {
        if (!e.target.value || Number(e.target.value) <= 0 || e.target.value > pagesCount) {
            setInputValue(page);
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            setInputValue(page);
        }

        return () => isMounted = false;
    }, [page]);

    return (
        <CssTextField
            onBlur={handleBlur}
            type="number"
            label="Página"
            size="small"
            value={inputValue}
            onChange={handleChange}
            inputProps={{ maxLength: pagesCount }}
        />
    );
}